const data=[
    {

        
       img:'https://sslimages.shoppersstop.com/sys-master/root/hd2/hdd/32654675640350/prada-armani-web--2024-05-09-new-.jpg',
       link:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.shoppersstop.com%2Fsearch%2F%3Fsort%3DtopRated%26q%3Dfragrance%3AnewArrival-desc%3AinStockFlag%3Atrue%3AcategoryName%3APerfumes%3Al1category%3ABeauty%3Al3category%3APerfumes%26startRange%3D%26endRange%3D`,

    },

    {

        
        img:'https://sslimages.shoppersstop.com/sys-master/root/hd3/hb4/32803027222558/Armani_Desktop_o01.jpg',
        link:'https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.shoppersstop.com%2Fsearch%2F%3Fsort%3DtopRated%26q%3Dgiorgio%2Barmani%3Arelevance%3AinStockFlag%3Atrue%3Abrands%3AARMANI%3Al1category%3ABeauty%26startRange%3D%26endRange%3D%23',
 
     },

     {

        
        img:`https://sslimages.shoppersstop.com/sys-master/root/h15/hae/32803027419166/Prada_Desktop_o03.jpg`,
        link:'https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.shoppersstop.com%2Fbrand%2Fprada%3Fsort%3DtopRated%26q%3D%3AcategoryRelevance%3AinStockFlag%3Atrue%3Abrands%3APRADA%26startRange%3D%26endRange%3D%26show%3DPage%23',
 
     },
     {

        
      img:`https://sslimages.shoppersstop.com/sys-master/root/h86/ha3/32803027746846/Ralph%20lauren_Web_o07.jpg`,
      link:'https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.shoppersstop.com%2Fbrand%2FRalph%20Lauren%3Fsort%3DtopRated%26q%3D%3AcategoryRelevance%3AinStockFlag%3Atrue%3Abrands%3ARALPH%2BLAUREN%26startRange%3D%26endRange%3D%23',

   },
]

export default data;