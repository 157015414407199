const data = [



    {
        img:`https://static1.lenskart.com/media/desktop/img/23may/cannes/web.gif`,
        link:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Feyewear%2Fpromotion%2Fas-seen-on-mouni-roy.html`,
    },
    {
        img:`https://static1.lenskart.com/media/desktop/img/Dec22/Web_banner.gif`,
        link:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Feyewear%2Fcollections%2Fkaran-johar-favorites.html`,
    },

    {
        img:`https://static1.lenskart.com/media/desktop/img/Jan23/sunglasses/Sun-Banner-web.gif`,
        link:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Fsunglasses%2Fpromotions%2Fsunglasses-b1g1-offer.html`,
    },



] 
export default data