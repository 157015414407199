const data =[

    {
        img:'https://www.boat-lifestyle.com/cdn/shop/files/Nirvana_Zenith_HP_Banner_WEB_1_1600x.jpg?v=1716541503',
        link:'https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.boat-lifestyle.com%2Fproducts%2Fnirvana-zenith-anc-earbuds%3Fvariant%3D41146213433442',

    },

    {
        img:'https://hammeronline.in/cdn/shop/files/bt_earbuds_49d378d7-6ccd-4fb0-85bc-b93bdee28b0a.webp?v=1713440018&width=1920',
        link:'https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fhammeronline.in%2Fcollections%2Ftruly-wireless-earbuds%2Fproducts%2Fhammer-solitude-tws-bluetooth-earbuds-with-bluetooth-v5-3-enc-and-smart-touch-controls',

    },

    {
        img:'https://m.media-amazon.com/images/S/aplus-media-library-service-media/36706080-18ca-4ce0-8040-8999a7427a89.__CR0,0,1464,600_PT0_SX1464_V1___.jpg',
        link:'https://www.amazon.in/OnePlus-Adaptive-Cancellation-Charging-Playback/dp/B0CRH6CYS6/ref=sr_1_3?crid=3DOSXIJU8WZ7D&dib=eyJ2IjoiMSJ9._D3wUoZrKzL8_zHfbwd3jYJ5OSYOB81s0LmPMMMrGRzeY9OoCukBq9jKiKG_WmN6eudV5-a-Q4qmmQKf2x-TtwsxizvmGdu_HTbHFbM-pzLCPNlslJFRSyBiBvElk4xR01sVWemVo4G5ZFoxGYntkCp5dIhe44BWxCQVuuqz_uDBxkqRloAkAfvKiwuaKaBm8bz_LQ4xPtcVMBkakrPhL5NP1e2oV157WXTeiE6p3q4.qmO1uCwzQUmrmst8dwIB124M_LQmfZ-IN-NzU6cC4_M&dib_tag=se&keywords=one+plus+buds+3&qid=1716886740&sprefix=one+plus+buds+,aps,210&sr=8-3&th=1&tag=inrdeal123-21',

    },
    {
        img:'https://m.media-amazon.com/images/S/aplus-media-library-service-media/70f1a1e9-291f-4f1d-a00e-611c167a9641.__CR0,0,1464,600_PT0_SX1464_V1___.jpg',
        link:'https://www.amazon.in/OnePlus-Bluetooth-Wireless-co-Created-Cancellation/dp/B0BRSLXGCN/ref=sr_1_7?crid=3DOSXIJU8WZ7D&dib=eyJ2IjoiMSJ9._D3wUoZrKzL8_zHfbwd3jYJ5OSYOB81s0LmPMMMrGRzeY9OoCukBq9jKiKG_WmN6eudV5-a-Q4qmmQKf2x-TtwsxizvmGdu_HTbHFbM-pzLCPNlslJFRSyBiBvElk4xR01sVWemVo4G5ZFoxGYntkCp5dIhe44BWxCQVuuqz_uDBxkqRloAkAfvKiwuaKaBm8bz_LQ4xPtcVMBkakrPhL5NP1e2oV157WXTeiE6p3q4.qmO1uCwzQUmrmst8dwIB124M_LQmfZ-IN-NzU6cC4_M&dib_tag=se&keywords=one+plus+buds+3&qid=1716886740&sprefix=one+plus+buds+,aps,210&sr=8-7&tag=inrdeal123-21',

    },
    {
        img:`https://images-eu.ssl-images-amazon.com/images/G/31/img24hp/jbl/jbl/SMARTCASE_-_GIF.gif`,
            link:'https://www.amazon.in/l/99976637031/ref=QAHzEditorial_en_IN_5?pf_rd_r=AXHYJJ7W619BMGPQDYQE&pf_rd_p=50821721-b232-4f1b-bb0c-0f847c26144e&pf_rd_m=A1VBAL9TL5WCBF&pf_rd_s=merchandised-search-7&pf_rd_t=&pf_rd_i=1388921031&tag=inrdeal123-21',

    },
 
    {

        img:`https://www.boultaudio.com/cdn/shop/files/collection_mob_1600x.png?v=1719155851`,
        link:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.boultaudio.com%2Fcollections%2Fboult-x-mustang`,

    },

]

export default data ;