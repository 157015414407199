    import React from 'react'
    import { useRef } from 'react';
    import Autoscroll from 'embla-carousel-auto-scroll';
    import { Carousel } from '@mantine/carousel';
    import { MantineProvider } from '@mantine/core';
    import MicasFloralContent from '../content/MicasFloralContent'


    const MicasFloralDress = () => {
        const autoscroll = useRef(Autoscroll({ delay: 2000 }));

    return (
        <div>
            <MantineProvider withGlobalStyles withNormalizeCSS>
        

        <Carousel className=''
        mx="auto"
        sx={{ maxWidth: 320 }}
    withIndicators
    height={430}
    slideSize="20%"
    slideGap="xs"
    loop
    align="start"
    slidesToScroll={1}
    plugins={[autoscroll.current]}
                    onMouseEnter={autoscroll.current.stop}
                    onMouseLeave={autoscroll.current.reset}
                    >

        {MicasFloralContent.map((card, index)=>(
            <Carousel.Slide key={index} className='mx-3'> 
        <div className='flex  items-center  justify-center bg-white'>
        
        <div  className='grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
        
        
            <div  key={index} className='group relative item-center justify-center overflow-hidden cursor-pointer hover:shadow-xl hover:shadow-black/30 transition-shadow'>
            
                <div  className='2xl:h-96 h-84 w-48 2xl:w-full lg:w-60 lg:h-96 '><img src={card.img}
                alt='bnner' className='h-full w-full object-cover group-hover:rotate-1 group-hover:scale-125 transition-transform duration-500'/>
                </div>
                
            
                <div className='absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:via-black/60 to group-hover:to-black/70'>
                </div>
                <div className='absolute inset-0 flex flex-col items-center justify-center px-9 text-center translate-y-[60%] group-hover:translate-y-0 transition-all' >
                <h1 className='text-sm md:text-lg lg:text-xl font-dmserif font-bold text-black border bg-white p-2 rounded-xl  '>{card.title}</h1>
                <p className='text-lg italic text-white mb-3 opacity-0 group-hover:opacity-100 transition-opacity'>{card.name}</p>
                <a href={card.blink} className='rounded-full shadow shadow-orange-900/60 py-2 bg-orange-700 px-3.5 text-sm capitalize text-white'>{card.tradeRate}</a>
                
                </div>
                
            </div>
        
            
            
            </div>
        </div>
            
        
        </Carousel.Slide>

        
        ))}

    </Carousel>

    </MantineProvider>

        
        </div>
    )
    }

    export default MicasFloralDress