const data =[
    {
        
        name: `THIBAUT CREAM RELAXED FIT CARGO`,
        href: `https://www.snitch.co.in/products/thibaut-cream-relaxed-fit-cargo-pant?ref=3wSXfTh7wAmkpb&variant=44679168000162`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/1dcc4d7c92208d7d019fd5cfd219d38f_5a0a7928-afd3-4472-9363-8beaadc80368.webp?v=1720173375&width=1800`,
        imageAlt: "Front of men's  Cargo pant in Beige.",
        price: 'INR 1,899',
        color: 'Pant',
    },

    {
        
        name: `DENITY SLIM FIT DENIM BLUE`,
        href: `https://www.snitch.co.in/products/denity-slim-fit-denim-blue-jeans?ref=3wSXfTh7wAmkpb&variant=44604855976098`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/18cc217929eb2253d908fbf0ce90aebe.webp?v=1717583016&width=1800`,
        imageAlt: "Front of men's damage jaen in Blue.",
        price: 'INR 1,699',
        color: 'Jeans',
    },

    {
        
        name: `THIBAUT BLACK RELAXED FIT CARGO`,
        href: `https://www.snitch.co.in/products/thibaut-black-relaxed-fit-cargo-pant?ref=3wSXfTh7wAmkpb&variant=44679167606946`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/33b29491d386a657b51cde3ec8aa52b3_07883a3b-9915-473b-b5ff-4bd3e6b30099.webp?v=1719259675&width=1800`,
        imageAlt: "Front of men's Cargo pant in Black.",
        price: 'INR 1,899',
        color: 'Pant',
    },

    {
        
        name: `DENIMIQUE SPRUCE BLUE DISTRESSED SLIM FIT`,
        href: `https://www.snitch.co.in/products/denimique-spruce-blue-distressed-slim-fit-jeans?ref=3wSXfTh7wAmkpb&variant=44487587201186`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/53eca94b1da0d5e88a4cbc6486b7018b.webp?v=1715597186&width=1800`,
        imageAlt: "Front of men's Jeans in Blue Faded.",
        price: 'INR 1,799',
        color: 'Jeans',
    },
    {
        
        name: `THIBAUT BROWN RELAXED FIT CARGO`,
        href: `https://www.snitch.co.in/products/thibaut-brown-relaxed-fit-cargo-pant?ref=3wSXfTh7wAmkpb&variant=44679167377570`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/c4e7686af963249b2ac27bc3937dda3b_b79f1c92-f26c-4afe-8227-20c8fc7c7fca.webp?v=1719259652&width=1800`,
        imageAlt: "Front of men's Cargo pant in Brown.",
        price: 'INR 1,899',
        color: 'Pant',
    },



    {
        
        name: `STEEZY ASH GREY CARGO`,
        href: `https://www.snitch.co.in/products/steezy-ash-grey-cargo-pant?ref=3wSXfTh7wAmkpb&variant=41048381653154`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/products/4MTR0123-04-Cargo_1.jpg?v=1660236954&width=1800`,
        imageAlt: "Front of men's Cargo pant in Ash Grey.",
        price: 'INR 1,499',
        color: 'Pant',
    },

    {
        
        name: `ULTRADENIM BLUE SKINNY`,
        href: `https://www.snitch.co.in/products/ultradenim-blue-skinny-jeans?ref=3wSXfTh7wAmkpb&variant=44465839374498`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4MSD3678-01-3212.jpg?v=1714740768&width=1800`,
        imageAlt: "Front of men's Fade Jeans in blue  .",
        price: 'INR 1,699',
        color: 'Jeans',
    },

    {
        
        name: `ROCCO BLACK DISTRESSED SKINNY FIT`,
        href: `https://www.snitch.co.in/products/rocco-black-distressed-skinny-fit-jeans?ref=3wSXfTh7wAmkpb&variant=44458038821026`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4MSD3543-04-3247.jpg?v=1715067361&width=1800`,
        imageAlt: "Front of men's Fade Distressed in Black.",
        price: 'INR 1,699',
        color: 'Jeans',
    },

    {
        
        name: `EIRIK STEEL BLUE SKINNY FIT`,
        href: `https://www.snitch.co.in/products/eirik-steel-blue-skinny-fit-jeans?variant=44604861481122`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/78960dc78991fd0208254c6f3694aba4.webp?v=1717675575&width=1800`,
        imageAlt: "Front of men's Jeans in Light Blue.",
        price: 'INR 1,699',
        color: 'Jeans',
    },

    {
        
        name: `TRAVERSE NAVY CARGO `,
        href: `https://www.snitch.co.in/products/traverse-navy-cargo-pant?ref=3wSXfTh7wAmkpb&variant=43943336837282`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4MSO4540-04-3225.jpg?v=1704166092&width=1800`,
        imageAlt: "Front of men's Polo Cargo pant in Dark Blue.",
        price: 'INR 1,699',
        color: 'Pant',
    },

    {
        
        name: `EUPHORIA BLUE DISTRESSED SKINNY FIT`,
        href: `https://www.snitch.co.in/products/euphoria-blue-distressed-skinny-fit-jeans?variant=44751732342946`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/6da8ca7cee10b644c734f126f4d920ae.jpg?v=1715078148&width=1800`,
        imageAlt: "Front of men's  Jeans in Light Blue.",
        price: 'INR 1,699',
        color: 'Jeans',
    },
    {
        
        name: `PROFLEX WHITE SKINNY FIT`,
        href: `https://www.snitch.co.in/products/proflex-white-skinny-fit-jeans?ref=3wSXfTh7wAmkpb&variant=44550115590306`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4c9cc650f4974e359c25f25fb8e0315f.webp?v=1716876222&width=1800`,
        imageAlt: "Front of men's Polo Cargo pant in White.",
        price: 'INR 1,699',
        color: 'jeans',
    },

    {
        
        name: `PANTZIQUE SKINNY FIT BLACK `,
        href: `https://www.snitch.co.in/products/pantzique-skinny-fit-black-jeans?ref=3wSXfTh7wAmkpb&variant=44555925356706`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/70a0d549343a47d03f8d30f1890602bd.webp?v=1716871884&width=1800`,
        imageAlt: "Front of men's Jean in Black.",
        price: 'INR 1,699',
        color: 'Jeans',
    },

    {
        
        name: `URBANFLARE AEGEAN BLUE BOOT CUT`,
        href: `https://www.snitch.co.in/products/urbanflare-aegean-blue-boot-cut-jeans?ref=3wSXfTh7wAmkpb&variant=44489388064930`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4MSD3689-03-327.jpg?v=1715243442&width=1800`,
        imageAlt: "Front of men's Jeans in fade blue.",
        price: 'INR 1,799',
        color: 'Jeans',
    },

    {
        
        name: `ULTRADENIM DARK BLUE SKINNY`,
        href: `https://www.snitch.co.in/products/ultradenim-dark-blue-skinny-jeans?ref=3wSXfTh7wAmkpb&variant=44751726411938`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/806bc0f8bc2424b23eaca3ac2c99ae4b.jpg?v=1713789286&width=1800`,
        imageAlt: "Front of men's Jeans in Dark blue.",
        price: 'INR 1,699',
        color: 'Jeans',
    },

    {
        
        name: `CLAUDE KOALA GREY SKINNY FIT`,
        href: `https://www.snitch.co.in/products/claude-koala-grey-skinny-fit-jeans?ref=3wSXfTh7wAmkpb&variant=44389515296930`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4MSD3680-02-3219.jpg?v=1713774132&width=1800`,
        imageAlt: "Front of men's Jeans in Grey",
        price: 'INR 1,699',
        color: 'Jeans',
    },
    {
        
        name: `TREKKER NAVY CARGO`,
        href: `https://www.snitch.co.in/products/trekker-navy-cargo-pant?ref=3wSXfTh7wAmkpb&variant=44099190915234`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4MSO4559-02-3228.jpg?v=1710503547&width=1800`,
        imageAlt: "Front of men's Cargo Pant in Navy",
        price: 'INR 1,799',
        color: 'Pant',
    },

    {
        
        name: `VOID GREY BAGGY FIT`,
        href: `https://www.snitch.co.in/products/void-grey-baggy-fit-jeans?ref=3wSXfTh7wAmkpb&variant=43869367959714`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4MSD2639-02-3222.jpg?v=1701169178&width=1800`,
        imageAlt: "Front of men's Cargo Pant Grey",
        price: 'INR 1,799',
        color: 'Pant',
    },

    {
        
        name: `BUZZER BLUE CARGO BAGGY`,
        href: `https://www.snitch.co.in/products/buzzer-blue-cargo-baggy-jeans?ref=3wSXfTh7wAmkpb&variant=44751703605410`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4MSD3590-01-324395.jpg?v=1685973160&width=1800`,
        imageAlt: "Front of men's Baggy jean in Blue",
        price: 'INR 1,799',
        color: `Jeans`,
    },

    {
        
        name: `STREETCARGO OLIVE CARGO`,
        href: `https://www.snitch.co.in/products/streetcargo-olive-cargo-pant?ref=3wSXfTh7wAmkpb&variant=43797409661090`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4MSO4533-04-3211.webp?v=1698687907&width=1800`,
        imageAlt: "Front of men's Cargo pant street style in Olive",
        price: 'INR 1,699',
        color: `Pant`,
    },

    {
        
        name: `VOID OLIVE BAGGY FIT`,
        href: `https://www.snitch.co.in/products/void-olive-baggy-fit-jeans?ref=3wSXfTh7wAmkpb&variant=43853001818274`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4MSD3639-01-3242.jpg?v=1700658310&width=1800`,
        imageAlt: "Front of men's Cargo baggy fit style in Olive",
        price: 'INR 1,799',
        color: `Pant`,
    },

    {
        
        name: `OSPREY BLACK SKINNY FIT`,
        href: `https://www.snitch.co.in/products/osprey-black-skinny-fit-jeans?ref=3wSXfTh7wAmkpb&variant=44386477703330`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4MSD3677-01-3212.jpg?v=1715072990&width=1800`,
        imageAlt: "Front of men's Skinny  fit style in fade Black",
        price: 'INR 1,699',
        color: `Jeans`,
    },

    {
        
        name: `COSMIC BLUE BAGGY FIT`,
        href: `https://www.snitch.co.in/products/cosmic-blue-baggy-fit-jeans?ref=3wSXfTh7wAmkpb&variant=44751712551074`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4MSD3654-01-3257.jpg?v=1710398442&width=1800`,
        imageAlt: "Front of men's Baggy Fit in Cosmic Blue",
        price: 'INR 1,799',
        color: `Jeans`,
    },
    {
        
        name: `OSPREY JET BLACK SKINNY FIT`,
        href: `https://www.snitch.co.in/products/osprey-jet-black-skinny-fit-jeans?ref=3wSXfTh7wAmkpb&variant=44386501034146`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4MSD3677-02-3225.jpg?v=1715074011&width=1800`,
        imageAlt: "Front of men's Skinny fit in Black",
        price: 'INR 1,699',
        color: `Jeans   `,
    },

    {
        
        name: `PIETRO WHITE WATER REPELLENT`,
        href: `https://www.snitch.co.in/products/pietro-white-water-repellant-jeans?ref=3wSXfTh7wAmkpb&variant=44661746761890`,
        imageSrc: ``,
        imageAlt: "Front of men's WATER REPELLENT in White",
        price: 'INR 1,799',
        color: `Jeans`,
    },


]

export default data