const data =[

    {    
        title:`ColorFit Pro 5 Max AMOLED`,
        tradeRate:`₹4,499`,
        feature:`ColorFit Pro 5 Max features a full-touch 1.96” AMOLED display.`,
        thumbnail:`https://www.gonoise.com/cdn/shop/files/Frame1_f1db79ba-1033-4de2-bce5-06abbd806cb6.png?v=1701150448`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.gonoise.com%2Fproducts%2Fnoise-colorfit-pro-5-max-smartwatch%3Fvariant%3D40343548493911`,
        
    },
    {  
        title:`Luna Ring`,
        tradeRate:`₹19,999`,
        feature:`After placing your order, a complimentary sizing kit will be sent to you, helping you determine a perfect size for you.`,
        thumbnail:`https://www.gonoise.com/cdn/shop/files/StardustSilver_fb0f0017-1bc7-41b4-b371-c4b81daf3063.webp?v=1702963588`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.gonoise.com%2Fproducts%2Fluna-smart-ring`,
    },
    {
        title:`Origin SmartWatch`,
        tradeRate:`₹6,499`,
        feature:`Elevate sensory experience, Discover the beauty of Gradients`,
        thumbnail:`https://cdn.shopify.com/s/files/1/0997/6284/files/1_d64fe1c0-5cdb-415d-aff8-dd10186c4c2f.png?v=1716280155`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.gonoise.com%2Fa%2Fh%2Forigin-smartwatch`,
    },
    {
        title:`NoiseFit Diva`,
        tradeRate:`₹2,999`,
        feature:``,
        thumbnail:`https://www.gonoise.com/cdn/shop/files/1.0-min_d91a41c6-5006-4b63-a467-b8955a12b987.png?v=1713446834`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.gonoise.com%2Fproducts%2Fnoisefit-diva-smartwatch`,
    },
    {
        title:`ColorFit Pro 5 Smartwatch`,
        tradeRate:`₹3,499`,
        feature:``,
        thumbnail:`https://www.gonoise.com/cdn/shop/files/Pro-5-Carousel-GN-500x500-Grey-Silicon-1.png?v=1704993597`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.gonoise.com%2Fproducts%2Fnoise-colorfit-pro-5-smart-watch`,
    },
    {
        title:`NoiseFit Posh`,
        tradeRate:`₹1,799`,
        feature:``,
        thumbnail:`https://www.gonoise.com/cdn/shop/files/Artboard1copy5-1_1_11zon.png?v=1706847610`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.gonoise.com%2Fproducts%2Fnoisefit-posh-smartwatch`,
    },
    {
        title:`Reflex Play+ Black`,
        tradeRate:`₹4,995`,
        feature:``,
        thumbnail:`https://www.fastrack.in/dw/image/v2/BKDD_PRD/on/demandware.static/-/Sites-titan-master-catalog/default/dwe3d103d7/images/Fastrack/Catalog/38077AP01_1.jpg?sw=800&sh=800`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.fastrack.in%2Fproduct%2Ffastrack-smart-unisex-watch-with-black-strap-38077ap01.html%3Flang%3Den_IN%26plp%3Dtrue%26catID%3Dreflex-smart-watches`,
    },

    {
        title:`Storm Call 2`,
        tradeRate:`₹1,699`,
        feature:``,
        thumbnail:`https://www.boat-lifestyle.com/cdn/shop/files/DarkBlue_jpg_700x.png?v=1689928715`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.boat-lifestyle.com%2Fproducts%2Fstorm-call-2-bluetooth-calling-smartwatch`,
    },
    {
        title:`Wave Flex Connect`,
        tradeRate:`₹1,699`,
        feature:``,
        thumbnail:`https://www.boat-lifestyle.com/cdn/shop/products/WaveFlexConnectPackaging1.2_700x.png?v=1677645513`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.boat-lifestyle.com%2Fproducts%2Fwave-flex-connect-bt-calling-smartwatch`,
    },
    {
        title:`Lunar Call Pro`,
        tradeRate:`₹2,299`,
        feature:``,
        thumbnail:`https://www.boat-lifestyle.com/cdn/shop/products/bla_700x.png?v=1679660395`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=boat-lifestyle.com%2Fproducts%2Flunar-call-pro-heart-rate-monitoring-smart-watch`,
    },
    {
        title:`Lnar Connect Ace`,
        tradeRate:`₹1,899`,
        feature:``,
        thumbnail:`https://www.boat-lifestyle.com/cdn/shop/files/LunarConnectAce-FI_Black01-removebg-preview_500x.png?v=1708948695`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.boat-lifestyle.com%2Fproducts%2Flunar-connect-ace-round-display-smartwatch`,
    },
    {
        title:`Ultima Max Call`,
        tradeRate:`₹1,749`,
        feature:``,
        thumbnail:`https://www.boat-lifestyle.com/cdn/shop/files/blackwavecallma_700x.png?v=1685025870`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.boat-lifestyle.com%2Fproducts%2Fultima-call-max-large-display-smartwatch`,
    },

    {
        title:`Primia Ace`,
        tradeRate:`₹1,999`,
        feature:``,
        thumbnail:`https://cdn.shopify.com/s/files/1/0057/8938/4802/files/Primia_Ace_Packaging_4.2331-removebg-preview_447x.png?v=1689752791`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.boat-lifestyle.com%2Fproducts%2Fwave-primia-ace-smartwatch%3Fvariant%3D40400892887138`,
    },
    {
        title:`Storm Plus`,
        tradeRate:`₹2,399`,
        feature:``,
        thumbnail:`https://www.boat-lifestyle.com/cdn/shop/files/Storm_Plus_Packaging_4.2352-removebg-preview_500x.png?v=1690188178`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.boat-lifestyle.com%2Fproducts%2Fstorm-plus-amoled-display-smartwatch`,
    },
    {
        title:`Ultima Chronos`,
        tradeRate:`₹2,499`,
        feature:``,
        thumbnail:`https://cdn.shopify.com/s/files/1/0057/8938/4802/files/ChronosM__2_-removebg-preview_500x.png?v=1690528562`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.boat-lifestyle.com%2Fproducts%2Fultima-chronos-amoled-display-smartwatch%3Fvariant%3D40408095195234`,
    },


    

]

export default data;