const data =[
    {
        image:'https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1722321283_1239897.jpg?format=webp&w=1080&dpr=1.5',
        title: `BagPack`,
        country: 'Should Store',
        description:`TSS Originals: Musafir`,
        mrprate:``,
        traderate:`₹1,499`,
        rating:`4.3`,
        keyword:`BagPack, Mini Bags,College Bag, NewLaunch, ShouldSotore`,
        discount:`GenZ Choice`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.thesouledstore.com%2Fproduct%2Ftss-originals-musafir-mini-bagpack%3Fgte%3D1`,

    
     },

     {
        image:`https://images.bewakoof.com/t1080/men-s-maroon-black-deadpool-color-block-high-top-sneakers-630669-1721909806-1.jpg`,
        title: `Marvel Edition Shoes`,
        country: 'Bewakoof.com',
        description:`Men's Maroon & Black Deadpool Color Block High Top Sneakers`,
        mrprate:`₹5,499`,
        traderate:`₹2,799`,
        rating:`4.6`,
        keyword:`Marvel Limited Edition, Shoes, Deadpool Edition, New Arrival`,
        discount:`57 people buy in 7 days`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.bewakoof.com%2Fp%2Fmens-maroon-black-color-block-high-top-sneakers`,

    
     },

     {
        image:`https://www.boat-lifestyle.com/cdn/shop/files/Artboard4_290fb063-cedb-4542-a36b-fce820be6fd6_1000x.png?v=1721457633`,
        title: `Boat: Airpodes Deadpool Edition`,
        country: 'Flipkart.com',
        description:`Wireless Earbuds with boAt Signature Sound, 35 Hours Playback, ASAP™ Charge, ENx™ Technology`,
        mrprate:`₹3490`,
        traderate:`₹999`,
        rating:`4.3`,
        keyword:`Marvel Limited Edition, Shoes, Deadpool Edition, New Arrival`,
        discount:`71% OFF`,
        blink:`https://fktr.in/kpBxo1i`,

    
     },
     {
      image:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1722481821_5261951.jpg?format=webp&w=480&dpr=1.5`,
      title: `Harry Potter: Slytherin`,
      country: 'The Should Store',
      description:`Men Low Top Sneakers`,
      mrprate:``,
      traderate:`₹2,499`,
      rating:`4.3`,
      keyword:`Harry Potter, New launch Shoe, The Should Store new edition `,
      discount:`Limited Edition`,
      blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.thesouledstore.com%2Fproduct%2Fmen-low-top-sneaker-harry-potter-slytherin%3Fgte%3D1`,

  
   },

   {
      image:`https://images.bewakoof.com/t1080/men-s-black-deadpool-duo-graphic-printed-oversized-t-shirt-644871-1721309976-1.jpg`,
      title: `OFFICIAL MARVEL MERCHANDISE`,
      country: 'Bewakoof.com',
      description:`Men's Black Deadpool Duo Graphic Printed Oversized T-shirt`,
      mrprate:`₹1,949`,
      traderate:`₹799`,
      rating:`4.7`,
      keyword:`Design Of the Week, Oversize Tshirt, Deadpool Wolverine, Marvel Edition T-shird`,
      discount:`59% OFF`,
      blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.bewakoof.com%2Fp%2Fmens-black-deadpool-duo-graphic-printed-oversized-t-shirt-men`,

  
   },

   {
      image:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1721458947_6270300.jpg?format=webp&w=480&dpr=1.5`,
      title: `Wolverine: Weapon X`,
      country: 'theshouldstore.com',
      description:`Men Low Top Sneakers`,
      mrprate:``,
      traderate:`₹1,999`,
      rating:`4.9`,
      keyword:`DeadPool X Wolverine, Fashion Accessories, Deadpool Wolverine Sneaker, Marvel Edition Product`,
      discount:`trending`,
      blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.thesouledstore.com%2Fproduct%2Fwolverine-logans-kicks-men-low-top-sneakers%3Fgte%3D1`,

  
   },
]

export default data; 