const data = [
    {
        name:`MagSafe Case Cover For iPhone 15`,
        tradeRate:`₹899.00`,
        category:`Best Seller`,
        img:`https://images.dailyobjects.com/marche/product-images/1101/dailyobjects-bandit-stride-2-0-case-cover-for-iphone-15-images/DailyObjects-Bandit-Stride-2-0-Case-Cover-For-iPhone-15-vw.png?tr=cm-pad_resize,v-2,w-640,h-591,dpr-1`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Fdailyobjects-bandit-stride-2-0-case-cover-for-iphone-15%2Fdp%3Ff%3Dpid~STRD-2-0-BANDI-AP-IPH15%26s%3Dreferer~lp`,
        style:`absolute inset-0 bg-gradient-to-b from-transparent via-transparent  group-hover:via-black/60 to group-hover:to-black/70 to-cyan-800`,
    
    },
    {
        name:`MagSafe Wallet Stand Combo iPhone 14`,
        tradeRate:`₹1699.00`,
        category:`Best Seller`,
        img:`https://images.dailyobjects.com/marche/product-images/1101/stride-2-0-clear-magsafe-phone-case-cover-angle-magsafe-wallet-stand-combo-for-iphone-14-images/Stride-2-0-Clear-MagSafe-Phone-Case-Cover-Angle-MagSafe-Wallet-Stand-Combo-For-iPhone-14-2.png?tr=cm-pad_resize,v-2,w-640,h-591,dpr-1`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Fstride-2-0-clear-magsafe-phone-case-cover-angle-magsafe-wallet-stand-combo-for-iphone-14%2Fdp%3Ff%3Dpid~STRD-2-0-CLR-ANGL-MEGSAF-WLT-AP-IPH14%26s%3Dreferer~lp`,
        style:`absolute inset-0 bg-gradient-to-b from-transparent via-transparent  group-hover:via-black/60 to group-hover:to-black/70 to-neutral-600`,
    },
    {
        name:`MagSafe Case Cover For iPhone 15`,
        tradeRate:`₹899.00`,
        category:`Best Seller`,
        img:`https://images.dailyobjects.com/marche/product-images/1101/dailyobjects-bao-stride-2-0-case-cover-for-iphone-15-images/DailyObjects-Bao-Stride-2-0-Case-Cover-For-iPhone-15-vw.png?tr=cm-pad_resize,v-2,w-640,h-591,dpr-1`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Fdailyobjects-bao-stride-2-0-case-cover-for-iphone-15%2Fdp%3Ff%3Dpid~STRD-2-0-BAO-AP-IPH15%26s%3Dreferer~lp`,
        style:`absolute inset-0 bg-gradient-to-b from-transparent via-transparent  group-hover:via-black/60 to group-hover:to-black/70 to-red-600`,
    },
    {
        name:`MagSafe Case Cover For iPhone 14`,
        tradeRate:`₹899.00`,
        category:`Best Seller`,
        img:`https://images.dailyobjects.com/marche/product-images/1101/dailyobjects-main-character-energy-stride-2-0-case-cover-for-iphone-14-images/DailyObjects-Main-Character-Energy-Stride-2-0-Case-Cover-For-iPhone-14.png?tr=cm-pad_resize,v-2,w-640,h-591,dpr-1`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Fdailyobjects-main-character-energy-stride-2-0-case-cover-for-iphone-14%2Fdp%3Ff%3Dpid~STRD-2-0-MAIN-CHAR-ENE-AP-IPH14%26s%3Dreferer~lp`,
        style:`absolute inset-0 bg-gradient-to-b from-transparent via-transparent  group-hover:via-black/60 to group-hover:to-black/70 to-green-600`,
    },
    {
        name:`MagSafe Case Cover For iPhone 14`,
        tradeRate:`₹899.00`,
        category:`Best Seller`,
        img:`https://images.dailyobjects.com/marche/product-images/1101/dailyobjects-no-nazar-stride-2-0-case-cover-for-iphone-14-images/DailyObjects-No-Nazar-Stride-2-0-Case-Cover-For-iPhone-14.png?tr=cm-pad_resize,v-2,w-640,h-591,dpr-1`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Fdailyobjects-no-nazar-stride-2-0-case-cover-for-iphone-14%2Fdp%3Ff%3Dpid~STRD-2-0-NO-NAZA-AP-IPH14%26s%3Dreferer~lp`,
        style:`absolute inset-0 bg-gradient-to-b from-transparent via-transparent  group-hover:via-black/60 to group-hover:to-black/70 to-red-600`,
    },
    {
        name:`MagSafe Case Cover For iPhone 14`,
        tradeRate:`₹899.00`,
        category:`Best Seller`,
        img:`https://images.dailyobjects.com/marche/product-images/1101/green-nimbus-magsafe-phone-case-cover-for-iphone-14-images/Green-Nimbus-MagSafe-Phone-Case-Cover-For-iPhone-14-2.png?tr=cm-pad_resize,v-2,w-640,h-591,dpr-1`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Fgreen-nimbus-magsafe-phone-case-cover-for-iphone-14%2Fdp%3Ff%3Dpid~GRN-NIMBS-MAGSAF-PHN-AP-IPH14%26s%3Dreferer~lp`,
        style:`absolute inset-0 bg-gradient-to-b from-transparent via-transparent  group-hover:via-black/60 to group-hover:to-black/70 to-green-600`,
    },
    {
        name:`MagSafe Case Cover For iPhone 14`,
        tradeRate:`₹899.00`,
        category:`Best Seller`,
        img:`https://images.dailyobjects.com/marche/product-images/1101/blue-nimbus-magsafe-phone-case-cover-for-iphone-14-images/Blue-Nimbus-MagSafe-Phone-Case-Cover-For-iPhone-14-2.png?tr=cm-pad_resize,v-2,w-640,h-591,dpr-1`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Fblue-nimbus-magsafe-phone-case-cover-for-iphone-14%2Fdp%3Ff%3Dpid~BLUE-NIMBS-MAGSAF-PHN-AP-IPH14%26s%3Dselected~Color`,
        style:`absolute inset-0 bg-gradient-to-b from-transparent via-transparent  group-hover:via-black/60 to group-hover:to-black/70 to-blue-600`,
    },
    {
        name:`MagSafe Case Cover For iPhone 14`,
        tradeRate:`₹899.00`,
        category:`Best Seller`,
        img:`https://images.dailyobjects.com/marche/product-images/1101/dailyobjects-ares-stride-2-0-clear-magsafe-phone-case-cover-for-iphone-14-images/DailyObjects-Ares-Stride-2-0-Clear-MagSafe-Phone-Case-Cover-For-iPhone-14.png?tr=cm-pad_resize,v-2,w-640,h-591,dpr-1`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Fdailyobjects-ares-stride-2-0-clear-magsafe-phone-case-cover-for-iphone-14%2Fdp%3Ff%3Dpid~STRD-2-0-CLR-MEGSAF-ARES-AP-IPH14%26s%3Dreferer~lp`,
        style:`absolute inset-0 bg-gradient-to-b from-transparent via-transparent  group-hover:via-black/60 to group-hover:to-black/70 to-red-600`,
    },
    {
        name:`MagSafe Case Cover For iPhone 14 Pro Max`,
        tradeRate:`₹899.00`,
        category:`Best Seller`,
        img:`https://images.dailyobjects.com/marche/product-images/1101/dailyobjects-black-special-delivery-stride-2-0-case-cover-for-iphone-14-pro-max-images/DailyObjects-Black-Special-Delivery-Stride-2-0-Case-Cover-For-iPhone-14-Pro-Max.png?tr=cm-pad_resize,v-2,w-640,h-591,dpr-1`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Fdailyobjects-black-special-delivery-stride-2-0-case-cover-for-iphone-14-pro-max%2Fdp%3Ff%3Dpid~STRD-2-0-BLAC-SPEC-DEL-AP-IPH14PROMAX%26s%3Dreferer~lp`,
        style:`absolute inset-0 bg-gradient-to-b from-transparent via-transparent  group-hover:via-black/60 to group-hover:to-black/70 to-neutral-600`,
    },
    {

        name:`MagSafe Case Cover For iPhone 15`,
        tradeRate:`₹899.00`,
        category:`Best Seller`,
        img:`https://images.dailyobjects.com/marche/product-images/1101/dailyobjects-mismatch-stride-2-0-case-cover-for-iphone-15-images/DailyObjects-Mismatch-Stride-2-0-Case-Cover-For-iPhone-15-vw.png?tr=cm-pad_resize,v-2,w-640,h-591,dpr-1`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Fdailyobjects-mismatch-stride-2-0-case-cover-for-iphone-15%2Fdp%3Ff%3Dpid~STRD-2-0-MISMA-AP-IPH15%26s%3Dreferer~lp`,
        style:`absolute inset-0 bg-gradient-to-b from-transparent via-transparent  group-hover:via-black/60 to group-hover:to-black/70 to-amber-600`,
    },
    {
        name:`MagSafe Case Cover For iPhone 15`,
        tradeRate:`₹899.00`,
        category:`Best Seller`,
        img:`https://images.dailyobjects.com/marche/product-images/1101/dailyobjects-paw-code-stride-2-0-case-cover-for-iphone-15-images/DailyObjects-Paw-Code-Stride-2-0-Case-Cover-For-iPhone-15-vw.png?tr=cm-pad_resize,v-2,w-640,h-591,dpr-1`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Fdailyobjects-paw-code-stride-2-0-case-cover-for-iphone-15%2Fdp%3Ff%3Dpid~STRD-2-0-PAW-CODE-AP-IPH15%26s%3Dreferer~lp`,
        style:`absolute inset-0 bg-gradient-to-b from-transparent via-transparent  group-hover:via-black/60 to group-hover:to-black/70 to-purple-600`,
    },

    {
        name:`Clear Case Cover S24 ultra`,
        tradeRate:`₹499.00`,
        category:`Best Seller`,
        img:`https://images.dailyobjects.com/marche/product-images/1101/dailyobjects-paris-skyline-black-hybrid-clear-case-cover-for-samsung-galaxy-s24-ultra-images/DailyObjects-Paris-Skyline-Black-Hybrid-Clear-Case-Cover-For-Samsung-Galaxy-S24-Ultra.png?tr=cm-pad_resize,v-2,w-640,h-591,dpr-1`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Fdailyobjects-paris-skyline-black-hybrid-clear-case-cover-for-samsung-galaxy-s24-ultra%2Fdp%3Ff%3Dpid~BLK-HYBRD-CLR-PARI-SKYL-SS-GLS24ULTRA%26s%3Dreferer~lp`,
        style:`absolute inset-0 bg-gradient-to-b from-transparent via-transparent  group-hover:via-black/60 to group-hover:to-black/70 to-gray-600`,
    },
]  
export default data ;