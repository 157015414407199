import React from 'react'

const collection = () => {
  return (
    <div>

      <h1>Collection</h1>

    </div>
  )
}

export default collection