const biraCarouselContent = [
    {
        img:'https://cdn.anscommerce.com/catalog/brandstore/Bira91/623-1716402600-1920x667website.jpg',
        link:'https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fshop.bira91.com%2Fmakeplay-collection',
    },
    {
        img:'https://cdn.anscommerce.com/live/image/bira91/banner/848_202405291240_1920x667_1_(1).jpg',
        link:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fshop.bira91.com%2Fbsl-collection`,
    },
  
]

export default biraCarouselContent