import React from 'react'
import Slider from "react-slick";
import { Link } from 'react-router-dom';


const banner = () => {
  const  settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
      };
  return (
    <div>
        <div className='w-full'> 

            <Slider { ...settings }>
             
            <div>
        <h3>
          <Link to='/Electronic/Airpods' ><img className='items-center pl-[200px]' src='https://cdn.zoutons.com/images/originals/blog/1678790168548.jpg_1678790172.png' alt=''/></Link>
        </h3>
      </div>
      <div>
        <h3>2</h3>
      </div>
      <div>
        <h3>3</h3>
      </div>
      <div>
        <h3>4</h3>
      </div>
      <div>
        <h3>5</h3>
      </div>
      <div>
        <h3>6</h3>
      </div>

            </Slider>


        </div>
    </div>
  )
}

export default banner