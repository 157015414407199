import React from 'react'
import Navbar from '../components/navbarf';
import Footer from '../components/Footer';
import Micasbanner from './layout/banner';
import MicasBannerContent from './content/Micasbannercontent'
import Micsaslinen  from './layout/aldo';
import MicasLinenContent from './content/micasLinenContent';
import MicasFloralDress from './layout/MicasFloralDress';

import { Helmet } from "react-helmet";
const micas = () => {
  return (
    <div>
        <Navbar />
        <Helmet>
        <title>ShopMicas: Trendy Women's Fashion, Clothing, and Accessories Online</title>
        <meta name="description" contents="Discover the latest fashion trends at Micas, Shop For Best Retail Clothing, Shop stylish clothing, accessories, and more at affordable prices. Explore our wide range of chic outfits and stay ahead in fashion. Visit https://shopmicas.com for your ultimate fashion destination. Fast shipping and excellent customer service guaranteed. Elevate your style with Micas today!" />
        <meta name="keywords" keywords="Micas Shopping, online fashion store, women's clothing, trendy apparel, dresses, tops, accessories, stylish outfits, shop Micas, Micas fashion, affordable fashion, online boutique, shopmicas.com" />
      </Helmet>

        <div>
            <Micasbanner data={MicasBannerContent} />
        </div>

        <div>
          <Micsaslinen data={MicasLinenContent} />
        </div>

        <div className='flex flex-col lg:m-10 m-5  '>
          
          <a href='https://www.dpbolvw.net/click-101131564-15486831?url=https%3A%2F%2Fshopmicas.com%2Fcollections%2Ftropic-floral-collection' ><img className=' rounded-xl' src='https://shopmicas.com/cdn/shop/collections/25bb90c3-901f-4c1a-b250-4ce336933a3d_1800x.jpg?v=1718815665' alt='' /></a>
            
          <div className='border rounded-xl bg-gradient-to-r from-orange-600 to-yellow-500 m-5 p-5'>

            <MicasFloralDress />


          </div>

          
        
        
        </div>
        <div className=''>

        

        </div>

        <Footer />
    </div>
  )
}

export default micas