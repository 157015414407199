import React from "react";
import groceryContent from "./grocerycontent";
import Groceries from "../components/groceries";
import { Helmet } from "react-helmet";

const grocerylist  = () =>{
    return(
        <>
        <Helmet>
        <title>Explore Products</title>
        <meta name="description" content="choose your specific Product" />
        <meta name="keywords" keywords="explore Product, find your suitable product, Increase your fashion efficiency" />
      </Helmet>
        <div>
            
            <div className="container py-4 mx-auto">
                <div className="flex flex-wrap m-4">
                    <Groceries groceries={ groceryContent } /> 
                </div>
            </div>
        </div>
       
       </>
    )
}

export default grocerylist;