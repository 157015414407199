const data = [


    {

        name:``,
        electronics: `airpodes`,
        title:`Cross Halter Linen Mini Dress`,
        saleCategory:`airpodes`,
        mrpRate:`₹4999`,
        tradeRate:`$35.00`,
        thumbnail:`https://shopmicas.com/cdn/shop/files/3b75e428-6fd1-4f9b-905c-b7fc7104ec34_1800x1800.jpg?v=1718094281`,
        brand:'',
        blink:`https://www.tkqlhce.com/click-101131564-15486831?url=https%3A%2F%2Fshopmicas.com%2Fcollections%2Fthe-linen-edit%2Fproducts%2Fmcwdc2405090131`
        
    },
    {

        name:``,
        electronics: `airpodes`,
        title:`Strapless Maxi Dress`,
        saleCategory:`airpodes`,
        mrpRate:`₹4999`,
        tradeRate:`$28.00`,
        thumbnail:`https://shopmicas.com/cdn/shop/files/38936bd0-0cd5-4fea-b78a-f9f309277097_1800x1800.jpg?v=1718192822`,
        brand:'',
        blink:`https://www.anrdoezrs.net/click-101131564-15486831?url=https%3A%2F%2Fshopmicas.com%2Fcollections%2Fthe-linen-edit%2Fproducts%2Fmcwbp2404010005`
        
    },
    {

        name:``,
        electronics: `airpodes`,
        title:`Asymmetric Ruched Mini Dress`,
        saleCategory:`airpodes`,
        mrpRate:`₹4999`,
        tradeRate:`$32.00`,
        thumbnail:`https://shopmicas.com/cdn/shop/files/0c410e03-4a9a-43ee-ac16-48e737864cec_1800x1800.jpg?v=1718189819`,
        brand:'',
        blink:`https://www.tkqlhce.com/click-101131564-15486831?url=https%3A%2F%2Fshopmicas.com%2Fcollections%2Fthe-linen-edit%2Fproducts%2Fmcwdc2405130014`
        
    },
    {

        name:``,
        electronics: `airpodes`,
        title:`Tie Waist V-Neck Pants Set`,
        saleCategory:`airpodes`,
        mrpRate:`₹4999`,
        tradeRate:`$48.00`,
        thumbnail:`https://shopmicas.com/cdn/shop/files/4b266b13-e6fc-4e10-859e-073f919fc50e_1800x1800.jpg?v=1718094483`,
        brand:'',
        blink:`https://www.anrdoezrs.net/click-101131564-15486831?url=https%3A%2F%2Fshopmicas.com%2Fcollections%2Fthe-linen-edit%2Fproducts%2Fmcwst2404010001`
        
    },
    {

        name:``,
        electronics: `airpodes`,
        title:`Linen Halter Neck Ruched Mini Dress`,
        saleCategory:`airpodes`,
        mrpRate:`₹4999`,
        tradeRate:`$38.00`,
        thumbnail:`https://shopmicas.com/cdn/shop/files/ad19340f-ff9f-403d-be2d-f3dddb99369e_1800x1800.jpg?v=1718094298`,
        brand:'',
        blink:`https://www.dpbolvw.net/click-101131564-15486831?url=https%3A%2F%2Fshopmicas.com%2Fcollections%2Fthe-linen-edit%2Fproducts%2Fmcwdc2405140002`
        
    },
    {

        name:``,
        electronics: `airpodes`,
        title:`Abstract Print Knot V-Neck Mini Dress`,
        saleCategory:`airpodes`,
        mrpRate:`₹4999`,
        tradeRate:`$32.00`,
        thumbnail:`https://shopmicas.com/cdn/shop/files/f5fd8c9c-985e-473c-bca4-b967e2c6da74_1800x1800.jpg?v=1718094247`,
        brand:'',
        blink:`https://www.tkqlhce.com/click-101131564-15486831?url=https%3A%2F%2Fshopmicas.com%2Fcollections%2Fthe-linen-edit%2Fproducts%2Fmcwdc2404260074`
        
    },
    {

        name:``,
        electronics: `airpodes`,
        title:`Linen Halter Neck V Neck Slit Midi Dress`,
        saleCategory:`airpodes`,
        mrpRate:`₹4999`,
        tradeRate:`$32.00`,
        thumbnail:`https://shopmicas.com/cdn/shop/files/82b4aa49-c48a-4cd2-9d5c-1e8fdbe27c9e_1800x1800.jpg?v=1718094243`,
        brand:'',
        blink:`https://www.dpbolvw.net/click-101131564-15486831?url=https%3A%2F%2Fshopmicas.com%2Fcollections%2Fthe-linen-edit%2Fproducts%2Fmcwdb2404230155`
        
    },
    {

        name:``,
        electronics: `airpodes`,
        title:`Ruched Sleeveless Asymmetric Hem Midi Dress`,
        saleCategory:`airpodes`,
        mrpRate:`₹4999`,
        tradeRate:`$32.00`,
        thumbnail:`https://shopmicas.com/cdn/shop/files/216a4531-e213-47f2-b055-85af402b2f4b_1800x1800.jpg?v=1718189807`,
        brand:'',
        blink:`https://www.kqzyfj.com/click-101131564-15486831?url=https%3A%2F%2Fshopmicas.com%2Fcollections%2Fthe-linen-edit%2Fproducts%2Fmcwdb2402210077`
        
    },
    {

        name:``,
        electronics: `airpodes`,
        title:`Backless Halter Neck Tank Romper`,
        saleCategory:`airpodes`,
        mrpRate:`₹4999`,
        tradeRate:`$35.00`,
        thumbnail:`https://shopmicas.com/cdn/shop/files/638f387d-4ecb-484e-b16d-32e4540e857d_1800x1800.jpg?v=1718094523`,
        brand:'',
        blink:`https://www.dpbolvw.net/click-101131564-15486831?url=https%3A%2F%2Fshopmicas.com%2Fcollections%2Fthe-linen-edit%2Fproducts%2Fmcwul2404290198`
        
    },





]
export default data ;