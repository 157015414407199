const data  =[
    {
        title:`Magnetic MagSafe Wireless `,
        tradeRate:`₹4,499`,
        feature:``,
        thumbnail:`https://images.dailyobjects.com/marche/product-images/1809/dailyobjects-surge-magnetic-wireless-battery-pack-images/DailyObjects-SURGE-Magnetic-Wireless-Battery-Pack-2nd.png?tr=cm-pad_resize,v-2,w-952,h-829,dpr-1`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Fdailyobjects-surge-magnetic-wireless-battery-pack%2Fdp%3Ff%3Dpid~SRG-MGNTC-WIRLES-BTRY-PACK%26gad_source%3D1%26gclid%3DCj0KCQjwvb-zBhCmARIsAAfUI2tB-QSTGaw19Hb4ebA9jSE71DHIHRKzRHTAnfGZiGuHhsl1EphscP8aAmErEALw_wcB`,
    },
    {
        title:`Earphones & Cable Pouch`,
        tradeRate:`₹399`,
        feature:``,
        thumbnail:`https://images.dailyobjects.com/marche/product-images/1703/oblong-earphones-and-cable-pouch-grey-images/Oblong-Earphones-And-Cable-Pouch-Gray.png?tr=cm-pad_resize,v-2,w-952,h-829,dpr-1`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Foblong-earphones-and-cable-pouch-grey%2Fdp%3Ff%3Dpid~GRAY-OBLOG-EARPHN-POUCH%26gad_source%3D1%26gclid%3DCj0KCQjwvb-zBhCmARIsAAfUI2uto_rfOFvkOqT14oWntTEjGzasWD-ceTrzs2MCmkkSgeaOOQnJ_fwaAst-EALw_wcB`,
    },
    {
        title:`Marshal Tech Kit Organiser`,
        tradeRate:`₹1,699`,
        feature:``,
        thumbnail:`https://images.dailyobjects.com/marche/product-images/1703/marshal-tech-case-blue-images/marshal-tech-kit-organiser-blue.png?tr=cm-pad_resize,v-2,w-960,h-829,dpr-1`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Fmarshal-tech-case-blue%2Fdp%3Ff%3Dpid~MARSAL-TECH-CASE-BLUE%26s%3Dreferer~best-sellers`,
    },
    {
        title:`Max 3-In-1 Magnetic Wireless Charger`,
        tradeRate:`₹4,999`,
        feature:``,
        thumbnail:`https://images.dailyobjects.com/marche/product-images/1809/dailyobjects-surge-max-3-in-1-magnetic-charging-station-30w-images/DailyObjects-SURGE-Max-3-In-1-Magnetic-Charging-Station.png?tr=cm-pad_resize,v-2,w-960,h-829,dpr-1`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Fdailyobjects-surge-max-3-in-1-magnetic-charging-station-30w%2Fdp%3Ff%3Dpid~SURG-MAX-3IN1-MGNT-CHRGN-STATION%26s%3Dreferer~best-sellers`,
    },
    {
        title:`Phone Lanyard - Cord`,
        tradeRate:`₹1,199`,
        feature:``,
        thumbnail:`https://images.dailyobjects.com/marche/product-images/1813/orange-crossbody-phone-lanyard-cord-images/Orange-Crossbody-Phone-Lanyard-Cord.png?tr=cm-pad_resize,v-2,w-960,h-829,dpr-1`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Forange-crossbody-phone-lanyard-cord%2Fdp%3Ff%3Dpid~ORNG-CROSBODY-PHN-LNYARD-CORD%26s%3Dreferer~new-arrivals`,
    },
    {
        title:`Apple WatchBand `,
        tradeRate:`₹699`,
        feature:``,
        thumbnail:`https://images.dailyobjects.com/marche/product-images/1801/blue-ridge-loop-apple-watchband-38-40-41mm-images/Blue-Ridge-Loop-Apple-WatchBand.png?tr=cm-pad_resize,v-2,w-952,h-829,dpr-1`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Fblue-ridge-loop-apple-watchband-38-40-41mm%2Fdp%3Ff%3Dpid~BLUE-RDGE-SOLO-APL-WATCH-STRP-38%26s%3Dreferer~new-arrivals`,
    },
    {
        title:`Max Conoid Magnetic Wireless Charger`,
        tradeRate:`₹2,999`,
        feature:``,
        thumbnail:`https://images.dailyobjects.com/marche/product-images/1809/conoid-universal-magnetic-charging-stand-images/SURGE-Max-Conoid-Magnetic-Wireless-Charging-Stand-15W.png?tr=cm-pad_resize,v-2,w-960,h-829,dpr-1`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Fconoid-universal-magnetic-charging-stand%2Fdp%3Ff%3Dpid~CNID-UNVRSL-MGNTC-CHRGN-STATION%26s%3Dreferer~best-sellers`,
    },
    {
        title:`4-in-1 Universal Braided Charging Cable`,
        tradeRate:`₹599`,
        feature:``,
        thumbnail:`https://images.dailyobjects.com/marche/product-images/1809/dailyobjects-surge-4-in-1-universal-braided-charging-cable-images/DailyObjects-SURGE-4-in-1-Universal-Braided-Charging-Cable.png?tr=cm-pad_resize,v-2,w-960,h-829,dpr-1`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Fdailyobjects-surge-4-in-1-universal-braided-charging-cable%2Fdp%3Ff%3Dpid~4IN1-SRG-BRDED-CHRGNG-CABLE%26s%3Dreferer~similar-products`,
    },
    {
        title:`Apple WatchBand `,
        tradeRate:`₹699`,
        feature:``,
        thumbnail:`https://images.dailyobjects.com/marche/product-images/1801/black-ridge-loop-apple-watchband-42-44-45mm-images/Black-Ridge-Loop-Apple-WatchBand-3-view.png?tr=cm-pad_resize,v-2,w-960,h-829,dpr-1`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Fblack-ridge-loop-apple-watchband-42-44-45mm%2Fdp%3Ff%3Dpid~BLK-RDGE-SOLO-APL-WATCH-STRP-42%26s%3Dreferer~best-sellers`,
    },
    
    {
        title:`RoadEye Neo`,
        tradeRate:`₹2999`,
        feature:``,
        thumbnail:`https://crossbeats.com/cdn/shop/files/NeoA.jpg?v=1716439939&width=800`,
        blink:`https://amzn.urlvia.com/gwG7Zs`,
    },
    {
        title:`GoSure 3000 series`,
        tradeRate:`₹5849`,
        feature:``,
        thumbnail:`https://images.philips.com/is/image/philipsconsumer/695aeecba1e54685b3fbb0d500c7d7f9?$jpglarge$&wid=960`,
        blink:`https://www.amazon.in/PHILIPS-GoSure-G-Sensor-Emergency-Recording/dp/B0CVQFHZ1G/ref=sr_1_8_sspa?crid=3QTY0LB2HSDPV&dib=eyJ2IjoiMSJ9.4fLdLgUqtBCI62m17DcTs-73PYvFObjMrUYIJipONDISuKZD8OA4OBsb6XlJ7_HI.wxuL_xT0GPxR9Om2-vMwX3RLVnixacbdt13ED7_Dq5U&dib_tag=se&keywords=RoadEye+neo&qid=1718957731&sprefix=roadeye+neo,aps,213&sr=8-8-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9tdGY&psc=1&tag=inrdeal123-21`,
    },
    {
        title:`WD 1.5 TB Wired External Hard Disk `,
        tradeRate:`₹4,999`,
        feature:``,
        thumbnail:`https://5.imthumbnail.com/data5/SELLER/Default/2022/5/YS/YK/HL/11565071/1tb-wd-elements-portable-500x500.jpg`,
        blink:`https://fktr.in/iKQp8mD`,
    },

    {
        title:`PIXAPLAY 55, Smart Projector`,
        tradeRate:`₹14,999`,
        feature:``,
        thumbnail:`https://m.media-amazon.com/images/I/61SLX-KtTFL._SX522_.jpg`,
        blink:`https://www.amazon.in/dp/B0D63FL4CR?tag=inrdeal123-21`,
    },
    
]

export default data