import { Image, Container, Title, Text, Button, SimpleGrid } from '@mantine/core';
import image from './notFound.png';
import classes from './NotFoundImage.module.css';
import { MantineProvider } from '@mantine/core';
import { Link } from 'react-router-dom';


const Notfound = () => {
    return(
        <div className='min-h-screen mt-48'>
        <MantineProvider withGlobalStyles withNormalizeCSS>
        <Container className={classes.root}>
        <SimpleGrid spacing={{ base: 40, sm: 80 }} cols={{ base: 1, sm: 2 }}>
          <Image src={image.src} className={classes.mobileImage} />
          <div >
            <Title className={classes.title}>Something is not right...</Title>
            <Text c="dimmed" size="lg">
              Page you are trying to open does not exist. You may have mistyped the address, or the
              page has been moved to another URL. If you think this is an error contact support.
            </Text>
           <Link to='/'> <Button variant="outline" size="md" mt="xl" className={classes.control}>
              Get back to home page
            </Button>
            </Link>
          </div>
          <Image src={image.src} className={classes.desktopImage} />
        </SimpleGrid>
      </Container>
      </MantineProvider>
      </div>
    )
}

export default Notfound;