import React from "react";
import { Link } from "react-router-dom";
import { GoArrowRight } from "react-icons/go";







 const Articles = ({articles}) =>{
return(
    <>
          {articles.map((article, index)=>(
                    <div key={index} className=" p-3 w-1/2 xl:w-1/3 lg:px-10  lg:w-1/3">
                        <div className="w-full md:w-[16rem] xl:w-[19rem] border-2 border-gray-400 hover:bg-orange-200 border-opacity-60 rounded-lg overflow-hidden shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]">
                             <Link to={`/article/${article.name}`}>
                                <img className="w-full h-auto  center  object-cover " src={article.thumbnail}
                                alt="blog"/>
                             </Link>
                             <div className="p-6">
                                <Link key={index} to={`/article/${article.name}`}>
                                    <h3 className={article.tcolor}>{article.title.substring(0, 20)}...</h3>
                                </Link>
                                <p className="leading-relaxed mb-3 text-xs  md:text-sm">
                                    {article.content[0].substring(0, 90)}...
                                </p>
                                <h1 className="text-lg font-semibold">
                                    {article.rate}
                                </h1>
                              
                              
                                </div>
                                <div className=" p-2 flex lg:mt-5 gap-1 lg:gap-3 justify-center items-center border bg-gray-600 rounde-xl">
                                <img className='w-1/4 h-auto  border-2 rounded-lg border-white  ' src={article.buy} alt='brandlogo' />
                                    <a href={article.blink} className='flex-inline inline-flex  font-semibold hover:text-orange-500 text-white  text-sm'>Buy Now</a>
                                    <Link className=" hover:border-2px hover:bg-orange-500 ml-4 rounded-xl  text-white  items-center" placeholder='read more' to={`/article/${article.name}`}>
                                    <GoArrowRight className='text-3xl ' /></Link>
                                </div>
                        </div>
                        
                        
                    </div>))}


    </>

);

 };

 export default Articles