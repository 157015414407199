import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import HeroBullets from '../layout/heroBullet'
import JblAirpodsContent from '../content/JblAirpodsContent'
import HammerDisplayEarbudsContent from '../content/HammerDisplayEarbudsContent'

import { green } from '@mui/material/colors';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const  Tabs2 =() => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%',  }}>
      <Box sx={{ borderBottom: 0 , }}>
        <div className='lg:mx-[20rem] mx-3 font-semibold mt-10'>
        <Tabs value={value}  onChange={handleChange} aria-label="basic tabs example">
          <Tab label="New Launch"  sx={{color:green[700]}}  {...a11yProps(0)} />
          <Tab label="True Enc + ANC" sx={{color:green[700]}} {...a11yProps(1)} />
          
        </Tabs>
        </div>
      </Box>
      <CustomTabPanel  value={value} index={0}>
        
        <HeroBullets data={JblAirpodsContent}/>  
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <HeroBullets data={HammerDisplayEarbudsContent}/>
      
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      
      </CustomTabPanel>
    </Box>
  );
}

export default Tabs2;