import React from 'react'



import Navbar from '../components/NavBar';
import Footer from '../components/Footer'


import { Helmet } from "react-helmet";
import DailyObjectCarousel from './layout/dailyObjectCarousel';

const dailyObject = () => {
  return (
    <>
        <Navbar />
       
        <Helmet>
        <title>Shop Stylish & Functional Accessories | Premium Phone Cases, Bags & More | Daily Object</title>
        <meta name="description" content="Discover Daily Object (dailyobject.com), your ultimate destination for stylish and functional everyday accessories. Shop premium phone cases, laptop sleeves, bags, and more, designed to elevate your daily routine. Experience exceptional quality, innovative design, and unbeatable prices. Upgrade your essentials with Daily Object and stand out with style!" />
        <meta name="keywords" keywords="phone cases, laptop sleeves, bags, everyday accessories, premium accessories, stylish accessories, functional accessories, Daily Object, tech accessories, fashion accessories, innovative design, quality accessories, online accessories store, daily essentials, travel accessories, lifestyle products" />
      </Helmet>

      
  


        <div className='flex w-3/4 px-8 lg:w-1/4 lg:ml-40 my-14 gap-2 items-center justify-center '>

      
        <DailyObjectCarousel  />

        
        
        
        
        </div>
      
         
    <Footer />









    

    </>
  )
}

export default dailyObject