const data= [

    {

        name:``,
        skincare: `face serum`,
        title:`Vitamin C Serum`,
        saleCategory:`Skincare Items`,
        mrpRate:`₹595`,
        tradeRate:`₹595`,
        thumbnail:`https://foxtale.in/cdn/shop/files/PDP-FirstImage-14.jpg?v=1714546665&width=600`,
        brand:'https://inrdeals.sgp1.cdn.digitaloceanspaces.com/production/394173/foxtable-logo.png',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Ffoxtale.in%2Fproducts%2Fc-for-yourself-vitamin-c-serum`
        
    },
    {

        name:``,
        skincare: `acne reduction`,
        title:`Overnight Acne Reduction`,
        saleCategory:`Skincare Items`,
        mrpRate:`₹798`,
        tradeRate:`₹773`,
        thumbnail:`https://foxtale.in/cdn/shop/files/Overnightacnereduction_2.jpg?v=1714547445&width=600`,
        brand:`https://inrdeals.sgp1.cdn.digitaloceanspaces.com/production/394173/foxtable-logo.png`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Ffoxtale.in%2Fproducts%2Fovernight-acne-reduction`
        
    },
    {

        name:``,
        skincare: `spot reduction`,
        title:`Rapid Spot Reduction Drops`,
        saleCategory:`Skincare Items`,
        mrpRate:`₹595`,
        tradeRate:`₹595`,
        thumbnail:`https://foxtale.in/cdn/shop/files/Product1stImage-10.jpg?v=1714546068&width=600`,
        brand:`https://inrdeals.sgp1.cdn.digitaloceanspaces.com/production/394173/foxtable-logo.png`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Ffoxtale.in%2Fproducts%2Fhyperpigmentation-serum-with-tranexamic-acid`
        
    },
    {

        name:``,
        skincare: `face serum`,
        title:`Hydrating Serum with Hyaluronic Acid`,
        saleCategory:`Skincare Items`,
        mrpRate:`₹549`,
        tradeRate:`₹549`,
        thumbnail:`https://foxtale.in/cdn/shop/files/PDP-FirstImage-13_0274a034-7982-4085-b16f-4259c119e70f.jpg?v=1714546217&width=600`,
        brand:`https://inrdeals.sgp1.cdn.digitaloceanspaces.com/production/394173/foxtable-logo.png`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Ffoxtale.in%2Fcollections%2Fsummer-essentials%2Fproducts%2Fdaily-hydrating-hyaluronic-acid-serum`
        
    },
    {

        name:``,
        skincare: `moisturizer`,
        title:`Hydrating Moisturizer with Ceramide`,
        saleCategory:`Skincare Items`,
        mrpRate:`₹445`,
        tradeRate:`₹445`,
        thumbnail:`https://foxtale.in/cdn/shop/files/Product1stImage-15.jpg?v=1714545804&width=600`,
        brand:`https://inrdeals.sgp1.cdn.digitaloceanspaces.com/production/394173/foxtable-logo.png`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Ffoxtale.in%2Fcollections%2Fsummer-essentials%2Fproducts%2Fceramide-supercream`
        
    },
    {

        name:``,
        skincare: `facewash`,
        title:`Hydrating Face Wash`,
        saleCategory:`Skincare Items`,
        mrpRate:`₹349`,
        tradeRate:`₹349`,
        thumbnail:`https://foxtale.in/cdn/shop/files/PDP_-_First_Image-04_d76eae1a-6b99-49fd-bed1-a8033d71156f.jpg?v=1714544285&width=600`,
        brand:`https://inrdeals.sgp1.cdn.digitaloceanspaces.com/production/394173/foxtable-logo.png`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Ffoxtale.in%2Fcollections%2Fsummer-essentials%2Fproducts%2Fthe-daily-duet-cleanser`
        
    },
    {

        name:``,
        skincare: `glow mask`,
        title:`Overnight Glow Mask`,
        saleCategory:`Skincare Items`,
        mrpRate:`₹595`,
        tradeRate:`₹595`,
        thumbnail:`https://foxtale.in/cdn/shop/files/PDP-FirstImage-16_1.jpg?v=1714544354&width=600`,
        brand:`https://inrdeals.sgp1.cdn.digitaloceanspaces.com/production/394173/foxtable-logo.png`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Ffoxtale.in%2Fproducts%2Fovernight-glow-mask-with-glycolic-and-lactic-acid`
        
    },
    {

        name:``,
        skincare: `sunscreen`,
        title:`SPF 70 Matte Finish Sunscreen`,
        saleCategory:`Skincare Items`,
        mrpRate:`₹595`,
        tradeRate:`₹595`,
        thumbnail:`https://foxtale.in/cdn/shop/files/PDP-FirstImage-11_e4d10687-d6ce-478f-a52b-54afaf920c04.jpg?v=1714544611&width=600`,
        brand:`https://inrdeals.sgp1.cdn.digitaloceanspaces.com/production/394173/foxtable-logo.png`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Ffoxtale.in%2Fcollections%2Fsummer-essentials%2Fproducts%2Fmatte-finish-sunscreen`
        
    },

    {

        name:``,
        skincare: `sunscreen`,
        title:`SPF 50 Tinted Sunscreen`,
        saleCategory:`Skincare Items`,
        mrpRate:`₹745`,
        tradeRate:`₹745`,
        thumbnail:`https://foxtale.in/cdn/shop/files/PDP-FirstImage-11_e4d10687-d6ce-478f-a52b-54afaf920c04.jpg?v=1714544611&width=600`,
        brand:`https://inrdeals.sgp1.cdn.digitaloceanspaces.com/production/394173/foxtable-logo.png`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Ffoxtale.in%2Fcollections%2Fsummer-essentials%2Fproducts%2Fultramatte-spf-50-primer`
        
    },

    


]

export default data;