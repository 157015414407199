import React from "react";
import { useParams } from "react-router-dom";
import  { useState } from "react";
import { Helmet } from "react-helmet";
// pages 
import groceryContent from "./grocerycontent";


//component




//apges
import Notfound from "./Notfound";




const Grocery = () =>{
    const { name } = useParams();

        const blog = groceryContent.find((blog) => blog.name === name );
        const [activeImg, setActiveImage] =useState(blog.thumbnail)

       
       

    if(!blog) return <Notfound />;
    
    

    //code for other articles seen below other article heading lin webpage

    
    return (
        <>

<Helmet>
        <title>{blog.title}</title>
        <meta name="description" content="detailing of selected product" />
        <meta name="keywords" keywords="price effect on different sites, purchase, benefit and useage" />
      </Helmet>
<div className="xl:m-14 sm:m-6 lg:m-10 m-3">

                
<div className="flex flex-col jutify-between lg:flex-row gap-16 lg:items-center" >
   <div className="flex flex-col gap-6 lg:w-2/4 2xl:w-1/3">
<img  className="w-full h-full mt-5 object-cover rounded-xl" src={activeImg} alt="blog"/>

<div className="flex flex-row sm:justify-between ml-14 items-center gap-2 lg:gap-4 md:gap-1 h-24">
<img  className="w-20 h-24 rounded-md cursor-pointer" src={blog.thumbnail} alt="blog"  onClick={()=>setActiveImage(blog.thumbnail)}/>

<img  className="w-20 h-24 rounded-md cursor-pointer" src={blog.image} alt="blog"  onClick={()=>setActiveImage(blog.image)}/>
<img className="w-20 h-24 rounded-md cursor-pointer" src={blog.image1} alt="blog" onClick={()=>setActiveImage(blog.image1)} />
</div>
</div>
{/*title and other detail */ }

<div className="flex flex-col gap-3 lg:w-2/4 2xl:w-3/4 ">
   <div>
   <span className={blog.Scolor}>{blog.wear}</span>
   <h1 className={blog.tcolor}>{blog.title}</h1>
   </div>
   <p className="text-grey-700">{blog.content}</p>
   <div>
       <div className=" flex flex-row ">
           <span className="bg-orange-600 py-2 px-5 border rounded-l-lg">
               <h1 className="lg:text-2xl text-lg">Available on</h1>
           </span>
          

       </div>
       <div className="flex gap-6 m-8">
               <a className="" href={blog.blink}><img className="h-10 w-10 " src={blog.buy} alt="plink"/></a>
               <a className="" href={blog.blink1}><img className="h-10 w-10" src={blog.buy1} alt="plink" /></a>
               <a className="" href={blog.blink2}><img className="h-10 w-10" src={blog.buy2} alt="plink" /></a>
           </div>
          
           
   </div>
   <div className="flex flex-row rounded">
           <span className="bg-black py-2 px-3 xl:px-3 border rounded-lg">
               <h1 className="text-sm lg:text-xl text-white">Follow for latest Update</h1>
           </span>
       </div>
       <div className="flex gap-6 m-4 mb-5 ">
               <a className="" href={blog.instagram}><img className="h-10 w-10 box-content" src={blog.icon} alt="plink"/></a>
               <a className="" href={blog.pinterest}><img className="h-10 w-10 box-content" src={blog.icon1} alt="plink" /></a>
               <a className="" href={blog.whatsapp}><img className="h-10 w-10 box-content" src={blog.icon2} alt="plink" /></a>
           </div>
           <div className="mb-5">
               <a className="" href={blog.jlink}><img className={blog.bclass} src={blog.summary} alt="jlink"/></a>
               
           </div>

</div>



</div>

<div className="m-8 border rounded-3xl bg-gray-400 2xl:mx-[23rem]">
   <h3 className="text-xl font-bold text-center m-4 ">
   Find its usage and features below
   </h3>
</div>







                 <div className="flex flex-col justify-center m-6  sm:py-12">
                    <div className="w-full py-2 px-2 sm:max-w-xl sm:mx-auto sm:px-0">
                        {/*verticle line in middle */}
                        <div className="hidden absolute w-1 sm:block bg-black h-full left-1/2 transform -translate-x-1/2">                        
                        </div>
                         {/*============left card start ==============*/}
                        <div className="mt-6 sm:mt-0 sm:mb-12">
                            <div className="flex items-center flex-col sm:flex-row">
                                   
                                <div className="flex justify-start w-full mx-auto items-center">
                                    <div className="w-full sm:w-1/2 sm:pr-8">
                                        <div data-aos="fade-right" data-aos-duration="1200" 
                                        className="bg-white p-4 rounded shadow group hover:bg-orange-600
                                        cursor-pointer ease-in duration-150">
                                            <h3 className="text-orange-400 font-[700] mb-3 group-hover:text-white 
                                            group-hover:font-[600] text-2xl">{blog.heading}</h3>
                                            <p className="text-[15px] text-black group-hover:text-white
                                            group-hover:font-[500] leading-7">{blog.paragraph}
                                             </p>
                                        </div>
                                    </div>
                                </div>
                               

                               
                                
                            </div>
                        </div>
                                            {/*============left card end ==============*/} 
                                            {/*============right card start ==============*/} 
                                            <div className="mt-6 sm:mt-0 sm:mb-12">
                            <div className="flex items-center flex-col sm:flex-row">
                                   
                                <div className="flex justify-end w-full mx-auto items-center">
                                    <div className="w-full sm:w-1/2 sm:pl-8">
                                        <div data-aos="fade-left" data-aos-duration="1200" 
                                        className="bg-white p-4 rounded shadow group hover:bg-gray-600
                                        cursor-pointer ease-in duration-150">
                                            <h3 className="text-black font-[700] mb-3 group-hover:text-white 
                                            group-hover:font-[600] text-2xl">{blog.heading1}</h3>
                                            <p className="text-[15px] text-black group-hover:text-white
                                            group-hover:font-[500] leading-7">{blog.paragraph1}
                                             </p>
                                        </div>
                                    </div>
                                </div>
                               

                                
                                
                            </div>
                        </div>
                         {/*============right card end ==============*/} 
                         <div className="hidden absolute w-1 mt-0 sm:block bg-black left-1/2 transform -translate-x-1/2">                        
                        </div>
                          {/*============left card start ==============*/}
                          
                          <div className="mt-6 sm:mt-0 sm:mb-12">
                            <div className="flex items-center flex-col sm:flex-row">
                                   
                                <div className="flex justify-start w-full mx-auto items-center">
                                    <div className="w-full sm:w-1/2 sm:pr-8">
                                        <div data-aos="fade-right" data-aos-duration="1200" 
                                        className="bg-white p-4 rounded shadow group hover:bg-black
                                        cursor-pointer ease-in duration-150">
                                            <h3 className="text-green-600 font-[700] mb-3 group-hover:text-white 
                                            group-hover:font-[600] text-2xl">{blog.heading2}</h3>
                                            <p className="text-[15px] text-black group-hover:text-white
                                            group-hover:font-[500] leading-7">{blog.paragraph2}
                                             </p>
                                        </div>
                                    </div>
                                </div>
                
                                
                                   
                                
                            </div>
                        </div>

                          {/*============right card start ==============*/} 
                          <div className="mt-6 sm:mt-0 sm:mb-12">
                            <div className="flex items-center flex-col sm:flex-row">
                                   
                                <div className="flex justify-end w-full mx-auto items-center">
                                    <div className="w-full sm:w-1/2 sm:pl-8">
                                        <div data-aos="fade-left" data-aos-duration="1200" 
                                        className="bg-white p-4 rounded shadow group hover:bg-red-300
                                        cursor-pointer ease-in duration-150">
                                            <h3 className="text-red-600  font-[700] mb-3 group-hover:text-white 
                                            group-hover:font-[600] text-2xl">{blog.heading3}</h3>
                                            <p className="text-[15px] text-black group-hover:text-white
                                            group-hover:font-[500] leading-7">{blog.paragraph3}
                                             </p>
                                        </div>
                                    </div>
                                </div>
                               

                                
                                
                            </div>
                        </div>
                          {/*============right card end ==============*/} 
                             {/*============left card start ==============*/}
                        <div className="mt-6 sm:mt-0 sm:mb-12">
                            <div className="flex items-center flex-col sm:flex-row">
                                   
                                <div className="flex justify-start w-full mx-auto items-center">
                                    <div className="w-full sm:w-1/2 sm:pr-8">
                                        <div data-aos="fade-right" data-aos-duration="1200" 
                                        className="bg-white p-4 rounded shadow group hover:bg-purple-300
                                        cursor-pointer ease-in duration-150">
                                            <h3 className="text-purple-600 font-[700] mb-3 group-hover:text-white 
                                            group-hover:font-[600] text-2xl">{blog.heading4}</h3>
                                            <p className="text-[15px] text-black group-hover:text-white
                                            group-hover:font-[500] leading-7">{blog.paragraph4}
                                             </p>
                                        </div>
                                    </div>
                                </div>
                               

                               
                                
                            </div>
                        </div>
                          {/*============left card end ==============*/} 









                    </div>
                </div>
                
                           
     

                 <br/>

                 </div>
                 

        </>
    );
};

export default Grocery;