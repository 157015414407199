import React from 'react';
import Navbar from '../components4/Navbar/navbar';
import Banner from '../components4/banner';


const Electronic = () => {
  return (
    <div>
        <Navbar />
        <Banner  />

      

        
      
    </div>
  )
}

export default Electronic;

