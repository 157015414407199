import React from 'react';
import { Carousel } from '@mantine/carousel';
import { IconStar } from '@tabler/icons-react';
import { MantineProvider, Card, Text, Group, Badge, Button, ActionIcon } from '@mantine/core';
import classes from './mantineCss/BadgeCard.module.css';



const Dailydeal = ({data}) => {
    

  


  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>

        
    <Carousel className='w-72  p-3'
     
 
 
                > 
        {data.map((article, index)=>(
            <Carousel.Slide>
    <Card withBorder radius="md" p="md" key={index} className={classes.card}>
      <Card.Section>
        <img src={article.image} alt={article.title} height={180} />
      </Card.Section>

      <Card.Section className={classes.section} mt="md">
        <Group justify="apart">
          <Text fz="lg" fw={500}>
            {article.title}
          </Text>
          <Badge size="sm" variant="light">
            {article.country}
          </Badge>
          <del className='' fz="lg" fw={600}>
            {article.mrprate}
          </del>
          <Text fz="lg" fw={500}>
            {article.traderate}
          </Text>
          <Badge size="lg" variant="light">
            {article.discount}
          </Badge>
          
        </Group>
        <Text fz="sm" mt="lg" fw={500}>
          {article.description}
        </Text>
      </Card.Section>

      <Card.Section className={classes.section}>
        <Text mt="md" className={classes.label} c="dimmed">
          {article.keyword}
        </Text>
        <Group gap={7} mt={5}>
          {article.features}
        </Group>
      </Card.Section>

      <Group mt="xs">
        <Button radius="lg" className={classes.button} style={{ flex: 1 }}>
          <a className='font-bold' href={article.blink}>Buy Now</a>
        </Button>
        <ActionIcon className='flex flex-row' variant="default" radius="md" size={45}>
        <h1 mt="md" className={classes.label} c="dimmed">
          {article.rating}
        </h1>
          <IconStar className={classes.like} stroke={1.5} />
          

        </ActionIcon>
      </Group>
    </Card>
    </Carousel.Slide>
        ))}

</Carousel>
    </MantineProvider>
  );
}

export default Dailydeal;