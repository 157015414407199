
import { MantineProvider } from '@mantine/core';
import Autoplay from 'embla-carousel-autoplay';
import { useRef } from 'react';

import { Carousel } from '@mantine/carousel';
import Biracarouselcontent from '../content/biraCarouselContent';


const BiraCarousel = () => {
    const autoplay = useRef(Autoplay({ delay: 2000 }));
 
  return (
    <div>
         <MantineProvider withGlobalStyles withNormalizeCSS>
        <div >
            <Carousel  slideSize="90%" height={400} slideGap="md"
            sx={{ maxWidth: 420 }}
            mx="auto"
            withIndicators
            plugins={[autoplay.current]}
            onMouseEnter={autoplay.current.stop}
            onMouseLeave={autoplay.current.reset}

      >

{Biracarouselcontent.map((article, index)=>(
      <Carousel.Slide key={index} >
        <div className="pt-10  flex flex-col justify-between items-center lg:px-32 px-5  bg-cover bg-no-re">
            <div className=' space-y-5'>
              <a href={article.link}>
               <img className='border rounded-xl ' src={article.img} alt='' />
               </a>
            </div>
        </div>
      </Carousel.Slide>
      ))}
      
    </Carousel>

    </div>
    </MantineProvider>

    </div>
  )
}

export default BiraCarousel