import React from 'react'
import { FaShoppingBag } from 'react-icons/fa'
import Navbar from '../components/NavBar';
import Footer from '../components/Footer';
import watchContent from './content/watchContent';
import WatchList from './layout/productList1';
import  Banner  from './layout/banner';
import SmartWatchbannerContent from './content/smartWatchBannerContent'
import ProductListCarousel from './layout/ProductListCarousel';
import SmartWatchCarouselContent from './content/smartWatchContent'
import { Helmet } from "react-helmet";
const watch = () => {
  return (
    <>
    <div>
        <Navbar />

      

<Helmet>
<title>Shop Trendy Watches | Trend - Stylish & Modern Timepieces | Gshock, Timex, GUESS</title>
<meta name="description" content="Explore trend (trendz.blog/Watches) for an exclusive range of watches from top brands like Casio, Titan, Sonata, Fastrack, and G-Shock. Discover stylish, durable, and high-quality timepieces to suit every occasion. Shop now for the best deals on multi-brand watches and elevate your style with TrendZ, watches, Casio watches, Titan watches, Sonata watches, Fastrack watches, G-Shock watches, multi-brand watches, stylish watches, TrendZ watches, premium watches, buy watches online, trendy watches, men's watches, women's watches, quality timepieces, fashion watches, luxury watches watches, Casio watches, Titan watches, Sonata watches, Fastrack watches, G-Shock watches, multi-brand watches, stylish watches, TrendZ watches, premium watches, buy watches online, trendy watches, men's watches, women's watches, quality timepieces, fashion watches, luxury watches" />
<meta name="keywords" keywords="TrendZ watches, Casio watches, Titan watches, Sonata watches, Fastrack watches, G-Shock watches, multi-brand watches, stylish watches, TrendZ watches, premium watches, buy watches online, trendy watches, men's watches, women's watches, quality timepieces, fashion watches, luxury watches" />
</Helmet>
       <div className='bg-primaryBG py-12 sm:flex-row xl:px-28 px-4'>
        <div className='md:flex'>
            <div className='md:w-1/2 ml-10'>
                <h1 className='text-xl md:text-2xl  mb-3  font-semibold '>Jacqueline Three-Hand Date Rose Gold-Tone Stainless Steel Watch and Jewellery Set</h1>
                <p className='text-sm mb-7 md:text-lg '>This set features a 36mm Jacqueline watch featuring a rose gold-tone textured dial, three-hand date movement, rose gold-tone stainless steel bracelet and rose gold-tone stainless steel pendant necklace and stud earrings.</p>
                  <div className='my-5'>
                <h3 className='flex gap-2 text-sm md:text-lg font-semibold my-5'>Necklace: Closure: Lobster Clasp, Colour: Rose Gold-Tone<span><h2 className='font-bold uppercase text-lime-500'>Limited Edition</h2></span></h3>
                <h3 className='md:text-lg text-sm font-semibold'>Earrings: Closure: Post, Colour: Rose Gold-Tone,  </h3>
                </div>
                <h1 className=' text-3xl font-semibold mb-8'>Rs.9,897</h1>
                <a href='https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.fossil.com%2Fen-in%2Fproducts%2Fjacqueline-three-hand-date-rose-gold-tone-stainless-steel-watch-and-jewellery-set%2FES5252SET.html' className=' item-center gap-2 bg-black hover:bg-orange-600 px-6 py-2 text-white font-semibold rounded-sm'>
                    <FaShoppingBag className='inline-flex mr-3' />Shop Now</a>
                    

            </div>
            <div className='md:w-1/2 mr-10 my-10 '>
                <img className='w-3/4 lg:w-2/4 border rounded-lg border-black   items-center justify-center' src='https://fossil.scene7.com/is/image/FossilPartners/ES5252SET_onwrist?$sfcc_onmodel_xlarge$' alt='' />
            </div>
            
        </div>

    </div>
    <div className='flex bg-primaryBG py-12 xl:px-28 px-10'>
        <div className='md:flex    '>
            
    
            <div className='md:w-1/2 ml-16'>
            <img className='lg:w-2/4 w-3/4  ml-4 mb-10 item-center justify-center' src='https://fossil.scene7.com/is/image/FossilPartners/FS6045_onwrist?$sfcc_onmodel_xlarge$' alt='' />

               
            </div>
            
            <div className='md:w-3/4 '>
            <h1 className='text-xl md:text-2xl  mb-3  font-semibold '>Sport Tourer Chronograph Stainless Steel Watch</h1>
                <p className='text-sm mb-7 md:text-lg'>We set out to create a timepiece that embodies both the function and thrilling energy of racing. With a Japanese center second chronograph movement, high-performance tachymeter bezel and lume hour markers, the Sport Tourer is made for whatever road you take next.</p>
                  <div className='my-5'>
                <h3 className='flex gap-2 text-sm md:text-lg font-semibold my-5'>HIGH-PERFORMANCE TACHYMETER<span><h2 className='font-bold uppercase text-lime-500'>special edition</h2></span></h3>
                <h3 className='text-sm md:text-lg font-semibold'>HIGH-VISIBILITY DIALS</h3>
                </div>
                <h1 className=' text-3xl font-semibold mb-8'>Rs.17,495</h1>
                <a href='https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.fossil.com%2Fen-in%2Fproducts%2Fsport-tourer-chronograph-stainless-steel-watch%2FFS6045.html' className=' item-center gap-2 bg-black hover:bg-orange-600 px-6 py-2 text-white font-semibold rounded-sm'>
                    <FaShoppingBag className='inline-flex mr-3' />Shop Now</a>

                          </div>
            
        
        </div>
        </div>
        <div className="container py-4  mx-auto">
          <div className='grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-5 px-8 lg:mx-24'>
        <WatchList data={ watchContent } /> 
        </div>
        </div>

        <div>
          <Banner data = {SmartWatchbannerContent} />
        </div>


           <div className=' '>
         <div className=' lg:my-14 my-10 border-2 bg-black rounded-xl lg:mx-24'>
          <h1 className='text-center text-xl text-white lg:text-3xl font-bold my-14'>Smart, Connected, Stylish, Essential.</h1>
      
      
        <div className='my-10 mx-3 lg:mx-[5rem]'>

          <ProductListCarousel data={SmartWatchCarouselContent} />

        </div>
        </div>
        </div>
             
        </div>

<Footer />
</>



    
  )
}

export default watch