import React from 'react'
import { useRef } from 'react';
import Autoscroll from 'embla-carousel-auto-scroll';
import { Carousel } from '@mantine/carousel';
import { MantineProvider } from '@mantine/core';
import MobileCaseContent from '../content/MobileCaseContent'

const MobileCase = () => {
    const autoscroll = useRef(Autoscroll({ delay: 2000 }));

  return (
    <div>

<MantineProvider withGlobalStyles withNormalizeCSS>

<Carousel className=''
mx="auto"

withIndicators

slideSize="30%"

loop
align="start"
slidesToScroll={1}
plugins={[autoscroll.current]}
          onMouseEnter={autoscroll.current.stop}
          onMouseLeave={autoscroll.current.reset}
          >


{MobileCaseContent.map((card,index) => (
<Carousel.Slide  className='mx-3'>

 <div className='flex items-center justify-center'>
 
 <div  className=''>
 
   <div key={index}  className='group relative item-center justify-center overflow-hidden cursor-pointer hover:shadow-xl hover:shadow-black/30 transition-shadow rounded-3xl'  >
   
      <div  className='lg:h-72 w-48 lg:w-72 h-60    place-self-center'><img src={card.img}
       alt='bnner' className='lg:h-72 lg:w-72 w-48 h-48  group-hover:rotate-1  group-hover:scale-125 transition-transform duration-500'/>
      </div>
   
      <div className={card.style}>
      </div>
      <div className='absolute inset-0 flex flex-col items-center justify-center px-9 text-center translate-y-[60%] group-hover:translate-y-0 transition-all' >
      <a href={card.blink} className=''><h1 className='text-xl lg:text-2xl font-dmserif font-bold text-white '>{card.name}</h1></a>
        <p className='text-lg italic text-white mb-3 opacity-0 group-hover:opacity-100 transition-opacity'>{card.category}</p>
        <a href={card.blink} className='rounded-full shadow shadow-black/60 py-2 bg-yellow-500 px-3.5 text-sm capitalize text-white'>{card.tradeRate}</a>
      
      </div>
    </div>
    
  
  </div>
</div>

</Carousel.Slide>
) )}
</Carousel>


</MantineProvider>

    </div>
  )
}

export default MobileCase