const data=[
    {

        
        name:``,
        wear:`4/5 rating`,
        title:`Y1 Gaming Wireless Gaming  Earbuds with 50H Playtime`,
        saleCategory:`Dual device Connectivity`,
        mrpRate:'₹3,499',
        tradeRate:'₹1,099',
        thumbnail:`https://www.boultaudio.com/cdn/shop/files/Group12670_2.png?v=1716468229&width=800`,
        brand:`https://inrdeals.sgp1.cdn.digitaloceanspaces.com/production/393881/BOULT-Logo.png`,
        blink:`https://www.boultaudio.com/products/y1-gaming?variant=41619230326853`
        

    },
    {

        
        name:``,
        wear:`4.2/5 rating`,
        title:`Mustang Dash Wireless Earbuds with 100 Hrs Playtime, BOULT Amp App Support`,
        saleCategory:`Dual device Connectivity`,
        mrpRate:'₹5,999',
        tradeRate:'₹1,799',
        thumbnail:`https://www.boultaudio.com/cdn/shop/files/Group13018_1.png?v=1718977668&width=800`,
        brand:`https://inrdeals.sgp1.cdn.digitaloceanspaces.com/production/393881/BOULT-Logo.png`,
        blink:`https://www.boultaudio.com/products/mustang-dash?variant=41767477510213`
        

    },

    {

        
        name:``,
        wear:`5/5(5,496) Reviews`,
        title:`Astra Wireless Gaming Earbuds with 48 Hrs Playtime, 13mm Drivers - BoomX`,
        saleCategory:`Dual device Connectivity`,
        mrpRate:'₹3,499',
        tradeRate:'₹1,499',
        thumbnail:`https://www.boultaudio.com/cdn/shop/files/Group12538_2.png?v=1711010345&width=800`,
        brand:`/images/flipkart.png`,
        blink:`https://fktr.in/0d9wjmo`
        

    },

    {

        
        name:``,
        wear:`4.1/5(1068) reviews`,
        title:`Astra Neo Wireless Gaming Earbuds with 70 Hrs Playtime`,
        saleCategory:`Dual device Connectivity`,
        mrpRate:'₹3,499',
        tradeRate:'₹1,499',
        thumbnail:`https://www.boultaudio.com/cdn/shop/files/Group12443_4.png?v=1717065584&width=800`,
        brand:`/images/flipkart.png`,
        blink:`https://fktr.in/QMw0PxV`
        

    },

    {

        
        name:``,
        wear:`3.9/5(1476) Ratings`,
        title:`Z40 Gaming Wireless Gaming Earbuds with 60H Playtime`,
        saleCategory:`Dual device Connectivity`,
        mrpRate:'₹4,999',
        tradeRate:'₹1,299',
        thumbnail:`https://www.boultaudio.com/cdn/shop/files/Group12993.png?v=1715318862&width=800`,
        brand:`/images/amazon.jpg`,
        blink:`https://www.amazon.in/Boult-Earbuds-Playtime-Pairing-Bluetooth/dp/B0D1VJYK9P?tag=inrdeal123-21`
        

    },

    {

        
        name:``,
        wear:`3.9/5(1476) Ratings`,
        title:`Z40 Ultra True Wireless Earbuds with 100Hrs Playtime, 32dB ANC`,
        saleCategory:`Dual device Connectivity`,
        mrpRate:'₹6,999',
        tradeRate:'₹1,999',
        thumbnail:`https://www.boultaudio.com/cdn/shop/files/gifblack.gif?v=1709729876&width=800`,
        brand:`/images/amazon.jpg`,
        blink:`https://www.amazon.in/Boult-Earbuds-Playtime-Cancellation-Bluetooth/dp/B0CV5728VS?th=1&tag=inrdeal123-21`
        

    },

    {

        
        name:``,
        wear:`3.7/5(5288) Ratings`,
        title:`Curv Buds Pro Premium Wireless Earbuds with 100 Hrs Playtime`,
        saleCategory:`Dual device Connectivity`,
        mrpRate:'₹3,999',
        tradeRate:'₹1,299',
        thumbnail:`https://www.boultaudio.com/cdn/shop/files/Group12552_1.png?v=1710745165&width=800`,
        brand:`/images/amazon.jpg`,
        blink:`https://www.amazon.in/Boult-Curve-Playtime-Equalizer-Bluetooth/dp/B0CHYFZFM5?th=1&tag=inrdeal123-21`
        

    },
]

export default data;