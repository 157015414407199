const data = [

    {
        title:'Nike Pegasus 41',
        traderate:`₹11,895.00`,
        category:`New launch`,
        thumbnail:'https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/4825a4b3-74a0-44bf-9ef0-f968bb42aebb/pegasus-41-road-running-shoes-SKDkNs.png',
        blink:'https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Fpegasus-41-road-running-shoes-SKDkNs%2FFD2723-100',
    },
    {
        title:'Nike Pegasus 41',
        traderate:`₹11,895.00`,
        category:`new Launch`,
        thumbnail:`https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/0558cdb5-4d42-4f4d-9b37-5038f1f97f9f/pegasus-41-road-running-shoes-RZm89S.png`,
        blink:'https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Fpegasus-41-road-running-shoes-RZm89S%2FFD2722-103',
    },
    {
        title:`ACG Rufus Black`,
        traderate:`₹9,695.00`,
        category:`new Launch`,
        thumbnail:`https://static.nike.com/a/images/t_prod_ss/w_640,c_limit,f_auto/5448a1ca-9b1d-4c5e-ba40-d4223569a140/acg-rufus-black-fv2923-001-release-date.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Flaunch%2Ft%2Facg-rufus-shoes`,
    },
    {
        title:`Air Jordan XXXVIII Low PF`,
        traderate:`₹17,295.00`,
        category:`new Launch`,
        thumbnail:`https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco,u_126ab356-44d8-4a06-89b4-fcdcc8df0245,c_scale,fl_relative,w_1.0,h_1.0,fl_layer_apply/b6aa977d-3ad0-4f95-970f-2cae9a69dea5/air-jordan-xxxviii-low-pf-basketball-shoes-2lBnKn.png`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Fair-jordan-xxxviii-low-pf-basketball-shoes-2lBnKn%2FFD2325-107`,
    },
    {
        title:'Air Jordan I High G',
        traderate:`₹16,995.00`,
        category:`new Launch`,
        thumbnail:`https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco,u_126ab356-44d8-4a06-89b4-fcdcc8df0245,c_scale,fl_relative,w_1.0,h_1.0,fl_layer_apply/d35c686b-f9e6-49cb-9381-9bcc79388e72/air-jordan-i-high-g-golf-shoes-qKzTBg.png`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Fair-jordan-i-high-g-golf-shoes-qKzTBg%2FDQ0660-300`,
    },
    {
        title:'Nike Calm',
        traderate:`₹7,095.00`,
        category:`new Launch`,
        thumbnail:`https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/9f6870b3-ae99-4e2c-8cb5-1b5c941a91a4/calm-sandals-t1BKK6.png`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Fcalm-sandals-t1BKK6%2FFJ6044-001`,
    },
    {
        title:'Nike Renew Ride 3',
        traderate:`₹5,977.00`,
        category:`Best Seller`,
        thumbnail:`https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/20e053fa-0f9d-4fe9-9cdd-a45130d4a203/renew-ride-3-road-running-shoes-fzS091.png`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Frenew-ride-3-road-running-shoes-fzS091%2FDC8185-009`,
    },
    {
        title:`Nike Dunk Low 'Lunar New Year'`,
        traderate:`₹7,117.00`,
        category:`Best Seller`,
        thumbnail:`https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/5b61c1c5-2ea6-4edb-9382-d719ef98a5e1/dunk-low-lunar-new-year-older-shoes-z61lmC.png`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Fdunk-low-lunar-new-year-older-shoes-z61lmC%2FFZ5528-101`,
    },
    {
        title:`Nike Air Force 1 '07`,
        traderate:`₹7,787.00`,
        category:`Best Seller`,
        thumbnail:`https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/22a7de43-5ccc-4409-a6e9-b235f3f4b711/air-force-1-07-shoes-NMmm1B.png`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Fair-force-1-07-shoes-NMmm1B%2FDD8959-115`,
    },
    {
        title:`Nike Dunk Low Twist`,
        traderate:`₹9,207.00`,
        category:`Best Seller`,
        thumbnail:`https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/d8f712f7-a0fe-4e8e-9ca1-c53b4352de48/dunk-low-twist-shoes-V6NqFG.png`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Fdunk-low-twist-shoes-V6NqFG%2FDZ2794-002`,
    },
    {
        title:`Luka 2 PF`,
        traderate:`₹11,297.00`,
        category:`Best Seller`,
        thumbnail:`https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco,u_126ab356-44d8-4a06-89b4-fcdcc8df0245,c_scale,fl_relative,w_1.0,h_1.0,fl_layer_apply/e1c4ca3c-8b78-4ada-b132-173349363373/luka-2-pf-basketball-shoes-LM9ScX.png`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Fluka-2-pf-basketball-shoes-LM9ScX%2FDX9012-017`,
    },
    {
        title:`Nike Air Max 1`,
        traderate:`₹5,977.00`,
        category:`Best Seller`,
        thumbnail:`https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/29e6596f-3d1d-4b35-8e4f-8f4740ea00a6/air-max-1-slides-TSMTS4.png`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Fair-max-1-slides-TSMTS4%2FDH0295-102`,
    },


]

export default data ;