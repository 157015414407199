const data =[

    {
        name:`Blue-Rectangle-Unisex-Eyeglasses`,
        thumbnail:'https://d3995ea24pmi7m.cloudfront.net/ft-media/catalog/product/p/3/p357bk1_1_lar.jpg',
        title:'FASTRACK Black Wayfarer Men Sunglasses',
        category:'Trending',
        traderate:'₹899',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Fcheer-for-your-team`,


    },
    {
        name:`Blue-Rectangle-Unisex-Eyeglasses`,
        thumbnail:'https://d3995ea24pmi7m.cloudfront.net/media/catalog/product/F/T/FT1275UFP4MBUV_1_lar_1.jpg',
        title:`TITAN Blue Rectangle Unisex Eyeglasses`,
        category:'Best Seller',
        traderate:`₹750`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titaneyeplus.com%2Fproduct%2Fblue-rectangle-rimmed-eyeglasses-from-fastrack-ft1275ufp4mbuv`,


    },
    {
        name:`Gold-Rectangle-Semi-Rimmed-Eyeglasses`,
        thumbnail:'https://d3995ea24pmi7m.cloudfront.net/media/catalog/product/t/w/tw1122whm1_3_lar.jpg',
        title:`TITAN Gold Rectangle Semi-Rimmed Eyeglasses`,
        category:'Best Seller',
        traderate:`₹750`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titaneyeplus.com%2Fproduct%2Fgold-rectangle-semi-rimmed-eyeglasses-from-titan-tw1122whm1`,


    },
    {
        name:`Blue-CatEye-Eyeglasses-For-Women`,
        thumbnail:'https://d3995ea24pmi7m.cloudfront.net/media/catalog/product/f/v/fvo5398276151_1_lar.jpg',
        title:`VOGUE EYEWEAR Blue CatEye Eyeglasses For Women`,
        category:'Best Seller',
        traderate:`₹4,390`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titaneyeplus.com%2Fproduct%2Fblue-cateye-rimmed-eyeglasses-for-women-from-vogue-eyewear-fvo5398276151`,


    },
    {
        name:`Gold-CatEye-Rimmed-Eyeglasses-for-Women`,
        thumbnail:`https://d3995ea24pmi7m.cloudfront.net/media/catalog/product/F/V/FVO428584853_1_lar.jpg`,
        title:`VOGUE EYEWEAR Gold CatEye Rimmed Eyeglasses for Women`,
        category:'Best Seller',
        traderate:`₹5,790`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titaneyeplus.com%2Fproduct%2Fgold-cateye-rimmed-eyeglasses-for-women-from-vogue-fvo428584853`,


    },
    {
        name:`Green Square Rimmed Eyeglasses for Women`,
        thumbnail:`https://d3995ea24pmi7m.cloudfront.net/media/catalog/product/F/V/FVO427454851_1_lar.jpg`,
        title:`VOGUE EYEWEAR Green Square Rimmed Eyeglasses for Women`,
        category:'Best Seller',
        traderate:`₹4,890`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titaneyeplus.com%2Fproduct%2Fgreen-square-rimmed-eyeglasses-for-women-from-vogue-eyewear-fvo427454851`,


    },
    {
        name:`Black-Aviator-Sunglasses-for-Men-and-Women`,
        thumbnail:`https://d3995ea24pmi7m.cloudfront.net/ft-media/catalog/product/M/2/M258GR5V_1_lar.jpg`,
        title:`FASTRACK Black Aviator Sunglasses for Men and Women`,
        category:'Best Seller',
        traderate:`₹1,399`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.fastrackeyewear.com%2Fproduct%2Fblack-aviator-rimmed-sunglasses-from-fastrack-m258gr5v`,
    },
    {
        name:`Brown-Pilot-Rimmed-Sunglasses`,
        thumbnail:`https://d3995ea24pmi7m.cloudfront.net/ft-media/catalog/product/M/2/M258BR5V_3_lar.jpg`,
        title:`FASTRACK Brown Pilot Rimmed Sunglasses`,
        category:'Best Seller',
        traderate:`₹1,399`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.fastrackeyewear.com%2Fproduct%2Fbrown-pilot-rimmed-sunglasses-m258br5v`,
    },
    {
        name:`Grey-Full-Rim-Square`,
        thumbnail:`https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//v/i/vincent-chase-ls-e15666-c6-eyeglasses_img_9630_29_dec23.jpg`,
        title:`Lenskart STUDIO Grey Full Rim Square @KJo special Collection`,
        category:'Best Seller',
        traderate:`₹2,000`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Fvincent-chase-ls-e15666-c6-eyeglasses.html`,
    },
    {
        name:`Grey-Full-Rim-Square`,
        thumbnail:`https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//v/i/vincent-chase-vc-e15347-c1-eyeglasses_csvfile-1684493324462-g_0006_19_05_2023.jpg`,
        title:`LENSKART Vincent Chase Blue Gunmetal Full Rim Aviator `,
        category:'Best Seller',
        traderate:`₹2,000`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Fvincent-chase-vc-e15347-c1-eyeglasses.html`,
    },
    

]

export default data ;