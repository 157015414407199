const data =[

    {
        title:`Sunny Floral Cascade Strapless`,
        tradeRate:`$20.00`,
        feature:``,
        img:`https://shopmicas.com/cdn/shop/files/fdc42603-6d31-4550-ba6a-cfea7e6ecd15_1800x1800.jpg?v=1717096456`,
        blink:`https://www.anrdoezrs.net/click-101131564-15486831?url=https%3A%2F%2Fshopmicas.com%2Fcollections%2Ftropic-floral-collection%2Fproducts%2Fmcwda2403050001`,
    },
    {
        title:`Ruched Tank Denim Dress`,
        tradeRate:`$32.00`,
        feature:``,
        img:`https://shopmicas.com/cdn/shop/files/d55fedd6-b6b3-40bd-a6b0-43be91ab2019_1800x1800.jpg?v=1717654488`,
        blink:`https://www.dpbolvw.net/click-101131564-15486831?url=https%3A%2F%2Fshopmicas.com%2Fcollections%2Ftropic-floral-collection%2Fproducts%2Fmcwdc2403220248`,
    },
    {
        title:`Floral Print Lace Trim Slit `,
        tradeRate:`$38.00`,
        feature:``,
        img:`https://shopmicas.com/cdn/shop/files/8d972c84-fbc7-4eae-8610-a166e1cb2f36_1800x1800.jpg?v=1717095218`,
        blink:`https://www.jdoqocy.com/click-101131564-15486831?url=https%3A%2F%2Fshopmicas.com%2Fcollections%2Ftropic-floral-collection%2Fproducts%2Fmcwda2302130092`,
    },
    {
        title:`Floral Print Shirt And Shorts Set`,
        tradeRate:`$55.00`,
        feature:``,
        img:`https://shopmicas.com/cdn/shop/files/a45beb37-cca6-4ad6-9489-5456145fb261_1800x1800.jpg?v=1717075231`,
        blink:`https://www.anrdoezrs.net/click-101131564-15486831?url=https%3A%2F%2Fshopmicas.com%2Fcollections%2Ftropic-floral-collection%2Fproducts%2Fmcwsd2404280105`,
    },
    {
        title:`Floral Print Slip Maxi Dress`,
        tradeRate:`$28.00`,
        feature:``,
        img:`https://shopmicas.com/cdn/shop/files/065a9583-ecc3-4f07-8be7-eb0366877a20_1800x1800.jpg?v=1717074803`,
        blink:`https://www.tkqlhce.com/click-101131564-15486831?url=https%3A%2F%2Fshopmicas.com%2Fcollections%2Ftropic-floral-collection%2Fproducts%2Fmcwda2404030096`,
    },
    {
        title:`Phalaenopsis Orchid Flower Earrings`,
        tradeRate:`$10.00`,
        feature:``,
        img:`https://shopmicas.com/cdn/shop/files/946ac719-802b-4007-a5af-77eeceb33679_1800x1800.jpg?v=1718176988`,
        blink:`https://www.anrdoezrs.net/click-101131564-15486831?url=https%3A%2F%2Fshopmicas.com%2Fcollections%2Ftropic-floral-collection%2Fproducts%2Fmcaje2405150169`,
    },
    {
        title:`Floral Print Crisscross Back Mesh Slip`,
        tradeRate:`$35.00`,
        feature:``,
        img:`https://shopmicas.com/cdn/shop/files/3997d13e-79a0-4f10-bfbb-4ea3e3a5accc_1800x1800.jpg?v=1717076450`,
        blink:`https://www.anrdoezrs.net/click-101131564-15486831?url=https%3A%2F%2Fshopmicas.com%2Fcollections%2Ftropic-floral-collection%2Fproducts%2Fmcwdb2404110027`,
    },
    {
        title:`Flower Pendant Earrings`,
        tradeRate:`$12.00`,
        feature:``,
        img:`https://shopmicas.com/cdn/shop/files/c1c1435a-dd9d-4ded-b296-eb2c870f504f_1800x1800.jpg?v=1717098497`,
        blink:`https://www.dpbolvw.net/click-101131564-15486831?url=https%3A%2F%2Fshopmicas.com%2Fcollections%2Ftropic-floral-collection%2Fproducts%2Fmcaje2310130014`,
    },
    {
        title:`Floral Print Mesh Asymmetric Ruffle Hem`,
        tradeRate:`$38.00`,
        feature:``,
        img:`https://shopmicas.com/cdn/shop/files/58aede8b-ae5c-4d53-aa89-1b69a3d7333c_1800x1800.jpg?v=1717092691`,
        blink:`https://www.anrdoezrs.net/click-101131564-15486831?url=https%3A%2F%2Fshopmicas.com%2Fcollections%2Ftropic-floral-collection%2Fproducts%2Fmcwdc2307150332`,
    },


]

export default data