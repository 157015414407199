const groceries = [

  {
    name:`11.Dove-Cream-Beauty-Bathing-Bar-8x125g`,
    title:`11. Dove Cream Beauty Bathing Bar 8x125g (Pack of 8)`,
    tcolor:`lg:text-3xl text-xl mt-5 font-bold text-blue-700`,
    wear:`Beauty Product`,
    Scolor:`text-orange-500 font-bold`,
    buy:`/images/amazon.jpg`,
    blink:`https://www.amazon.in/Dove-Bathing-Beauty-Moisturising-Glowing/dp/B08S58S29R/ref=sr_1_1_sspa?crid=2SPFAM94AYKR8&dib=eyJ2IjoiMSJ9.L8RX6CLOfl019Pf_ty1TY_QZ-9FKUTmogmFxxr5uV6l5bf0k_sWgrqPDBpiUSfhQbl4P_zUPaG5uoQhRMz7RNV2pQZKbRafAldnfByduSEbUM9vEUFguY2kQjfvCUKbC0I3-3jqRgEsylIqR4keVOafR1WNQ_ygiDAUhwyCBAysZIsKQoIcRDdjQoRGlK_RbY2k0BRule-TR7veRYw4VyruTHJEpinC1EcK7fNP6t8rCw69Da7CZjYuT7gC0XKPZFKt_59KuiT72AzbMv6Iq-FLu3i3VmUccLXGrTj3bctk.stopoS6kL8XshAen_lSA96jYrQE3j0RysaT3Q7FJLz4&dib_tag=se&keywords=dove+beauty+bar&qid=1705767335&sprefix=,aps,430&sr=8-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1&tag=inrdeal123-21`,
    buy1:`/images/flipkart.png`,
    blink1:`https://fktr.in/i9lNREX`,
    buy2:`/images/nosite.jpg`,
    blink2:``,
    thumbnail:`/images/Grocery/11/g11.1.jpg`,
    image:`/images/Grocery/11/g11.2.jpg`,
    image1:`/images/Grocery/11/g11.3.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Deep Moisturization for Silky Smooth Skin`,
    paragraph:`Dove Cream Beauty Bathing Bar is specially crafted to provide deep moisturization to your skin. Infused with 1/4th moisturizing cream, it nourishes and hydrates your skin, leaving it irresistibly soft, smooth, and supple.`,
    heading1:`Gentle Cleansing for Delicate Skin`,
    paragraph1:`The gentle formula ensures effective cleansing without compromising the natural moisture balance of your skin. Dove's Cream Beauty Bathing Bar is suitable for daily use, making your skin feel clean, fresh, and pampered.`,
    heading2:`Classic Dove Fragrance`,
    paragraph2:`Immerse yourself in the classic and comforting fragrance of Dove. The Cream Beauty Bathing Bar leaves a subtle and long-lasting scent on your skin, providing an added touch of freshness to your post-bath experience.`,
    heading3:`Pack of 8 for Extended Use`,
    paragraph3:`With a pack of 8 bars, each weighing 125g, Dove ensures that you have an extended supply of luxurious skincare. This bulk pack is a convenient solution, allowing you to enjoy the nourishing benefits of Dove Cream Beauty over an extended period.`,
    heading4:`Dermatologically Tested`,
    paragraph4:`Dove is known for its commitment to skincare, and the Cream Beauty Bathing Bar is no exception. Dermatologically tested, this bathing bar is suitable for daily use and caters to the needs of all skin types, ensuring a gentle and safe cleansing experience.`,
    summary:`/images/ad/amazon1.jpg`,
    jlink:`https://www.amazon.in/l/78695869031?ref=ac_in_carousel-SLOT8`,
    bclass:`rounded-xl  ml-14 w-3/4`,
    content: [
      `Experience the epitome of gentle care and nourishment for your skin with the Dove Cream Beauty Bathing Bar. This pack of 8, each weighing 125g, is a testament to Dove's commitment to providing a moisturizing and soothing bathing experience. Elevate your daily routine with the creamy lather and rich formula of Dove.`      
    ],
  },


  
  {
    name:`10.Dove-Cream-Beauty-Bathing-Bar-5x125g`,
    title:`10.Dove Cream Beauty Bathing Bar 5x125g (Pack of 5)`,
    tcolor:`lg:text-3xl text-xl mt-5 font-bold text-blue-700`,
    wear:`Beauty Product`,
    Scolor:`text-orange-500 font-bold`,
    buy:`/images/amazon.jpg`,
    blink:`https://amzn.urlvia.com/xZMYUw`,
    buy1:`/images/myntra.png`,
    blink1:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.myntra.com%2Fsoap%2Fdove%2Fdove-set-of-5-cream-beauty-bathing-bar-for-soft-smooth--glowing-skin---125-g-each%2F13388312%2Fbuy`,
    buy2:`/images/flipkart.png`,
    blink2:`https://fktr.in/39o5iLH` ,
    thumbnail:`/images/Grocery/10/g10.1.jpg`,
    image:`/images/Grocery/10/g10.2.jpg`,
    image1:`/images/Grocery/10/g10.3.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Deep Moisturization for Soft Skin`,
    paragraph:`Dove Cream Beauty Bathing Bar is specially formulated to deliver deep moisturization to your skin. Enriched with 1/4th moisturizing cream, it nourishes your skin from within, leaving it soft, supple, and well-hydrated. Say goodbye to dryness and welcome the indulgent touch of Dove.`,
    heading1:`Gentle Cleansing for Delicate Skin`,
    paragraph1:`Experience gentle cleansing that cares for your delicate skin. Dove's Cream Beauty Bathing Bar is crafted to cleanse effectively while maintaining the natural moisture balance of your skin. Enjoy a refreshing and pampering bath that leaves your skin feeling clean and cared for.`,
    heading2:`Classic Dove Fragrance`,
    paragraph2:`Immerse yourself in the classic and comforting fragrance of Dove. The Cream Beauty Bathing Bar leaves a subtle and long-lasting scent on your skin, providing an added touch of freshness to your post-bath experience.`,
    heading3:`Pack of 5 for Extended Use`,
    paragraph3:`With a pack of 5 bars, each weighing 125g, Dove ensures that you have an extended supply of luxurious skincare. This bulk pack is a convenient solution, allowing you to enjoy the nourishing benefits of Dove Cream Beauty over an extended period.`,
    heading4:`Dermatologically Tested`,
    paragraph4:`Dove is known for its commitment to skincare, and the Cream Beauty Bathing Bar is no exception. Dermatologically tested, this bathing bar is suitable for daily use and caters to the needs of all skin types, ensuring a gentle and safe cleansing experience.c`,
    summary:`/images/ad/dovead.jpg`,
    jlink:`https://www.amazon.in/s?k=dove&crid=3AD2U3LJDOF1M&sprefix=dov,aps,252&ref=nb_sb_noss_2&tag=inrdeal123-21`,
    bclass:`rounded-xl  ml-14 w-2/4`,
    content: [
      `Indulge in Luxurious Skincare with Dove Cream Beauty Bathing Bar - Pack of 5 (5x125g). Pamper your skin with the rich and creamy goodness of Dove Cream Beauty Bathing Bar. This pack of 5, each weighing 125g, is a testament to Dove's commitment to providing nourishing care for your skin. Elevate your bathing experience with the moisturizing benefits of Dove.`      
    ],
  },

  {
    name:`9.Good-knight-Gold-Flash-Liquid-Vapourizer`,
    title:`9.Good knight Gold Flash Liquid Vapourizer | Mosquito Repellent Refill | Lavender Fragrance | Pack of 4 (45ml each)`,
    tcolor:`lg:text-3xl text-xl mt-5 font-bold text-yellow-600`,
    wear:`Home Product`,
    Scolor:"text-orange-600 font-bold",
    buy:`/images/amazon.jpg`,
    blink:`https://amzn.urlvia.com/6EpU4R`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`/images/Grocery/9/g9.1.jpg`,
    image:`/images/Grocery/9/g9.2.jpg`,
    image1:`/images/Grocery/9/g9.3.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Instant Protection with Gold Flash Technology`,
    paragraph:`Good knight Gold Flash Liquid Vapourizer is powered by Gold Flash Technology, providing instant and effective protection against mosquitoes. This advanced technology acts swiftly, creating a shield that keeps mosquitoes at bay, ensuring a mosquito-free living space for you and your loved ones.`,
    heading1:`Soothing Lavender Fragrance`,
    paragraph1:`Infuse your home with the calming and pleasant aroma of lavender. The Good knight Gold Flash Liquid Vapourizer Refill in lavender fragrance not only repels mosquitoes but also adds a touch of tranquility to your surroundings. Enjoy the dual benefits of protection and fragrance in one solution.`,
    heading2:`Pack of 4 for Extended Use`,
    paragraph2:`With a pack of 4 refills, each containing 45ml of potent liquid, Good knight Gold Flash ensures that you have an extended and continuous supply of mosquito protection. This bulk pack is a cost-effective solution for keeping your home safe from mosquitoes over an extended period.`,
    heading3:`Odorless and Smoke-Free`,
    paragraph3:`Bid farewell to strong odors and smoke associated with traditional mosquito repellents. The Good knight Gold Flash Liquid Vapourizer Refill is designed to be odorless, providing a comfortable and pleasant environment for you and your family without any discomfort.`,
    heading4:`Easy-to-Use and Compatible`,
    paragraph4:`Refilling your Good knight Gold Flash Liquid Vapourizer is a breeze. The user-friendly design ensures a hassle-free experience, allowing you to enjoy mosquito protection without any complications. The refills are compatible with the Good knight Gold Flash Liquid Vapourizer device, ensuring seamless usage.`,
    summary:`/images/ad/applwatch.jpg`,
    jlink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.apple.com%2Fam%2Fapple-watch-series-8%2F`,
    bclass:`rounded-xl  ml-10 w-3/4`,
    content: [
      `Guard your home against mosquitoes while indulging in a delightful lavender fragrance with the Good knight Gold Flash Liquid Vapourizer Refill Pack. This pack of 4 (45ml each) offers a powerful mosquito repellent solution with the added touch of soothing lavender, creating a pleasant environment for your family.
      `      
    ],
  },

  {
    name:`8.All-Out-Ultra-Liquid-Vaporizer-Refills-45ml-each`,
    title: `8. All Out Ultra Liquid Vaporizer, 6 Refills (45ml each) | Kills Dengue, Malaria & Chikungunya Spreading Mosquitoes| India's Only Mosquito Killer Brand Recommended by Indian Medical Association`,
    tcolor:`lg:text-3xl text-xl mt-5 font-bold text-blue-800`,
    wear:`Home Product`,
    Scolor:"text-orange-600 font-bold",
    buy:"/images/amazon.jpg",
    blink:`https://amzn.urlvia.com/YC2cEP`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`/images/Grocery/8/g8.1.jpg`,
    image:`/images/Grocery/8/g8.2.jpg`,
    image1:`/images/Grocery/8/g8.3.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Triple Defense Against Mosquito-Borne Diseases`,
    paragraph:`All Out Ultra Liquid Vaporizer is not just a mosquito repellent; it's a powerful defender against diseases. The formulation is crafted to specifically target mosquitoes that are known carriers of Dengue, Malaria, and Chikungunya, providing you and your family with a triple layer of protection.`,
    heading1:`India's Only IMA Recommended Mosquito Killer Brand`,
    paragraph1:`Trust in the expertise endorsed by the Indian Medical Association (IMA). All Out is the only mosquito killer brand in India recommended by the IMA, attesting to its effectiveness in safeguarding homes against disease-spreading mosquitoes.`,
    heading2:`Pack of 6 Refills for Extended Use`,
    paragraph2:`With a pack of 6 refills, each containing 45ml of potent liquid, All Out Ultra ensures extended protection for your household. This bulk pack is a cost-effective solution, providing you with a continuous supply to combat mosquitoes over an extended period.`,
    heading3:`24/7 Shield for Round-the-Clock Safety`,
    paragraph3:`All Out Ultra Liquid Vaporizer offers a 24/7 shield against mosquitoes. Simply plug in the vaporizer and let it work its magic. The continuous release of the active formula ensures that your living spaces remain mosquito-free, allowing you to enjoy your home without the threat of mosquito-borne diseases.`,
    heading4:`User-Friendly and Hassle-Free`,
    paragraph4:`The All Out Ultra Liquid Vaporizer is designed with user convenience in mind. It is easy to use, making it a hassle-free solution for mosquito protection. The refills are compatible with the All Out Ultra Vaporizer device, ensuring a seamless and efficient experience.`,
    summary:"/images/amazon/primemembership/primemember1.jpg",
    jlink:`https://www.amazon.in/tryprime?tag=trendz12345-21`,
    bclass:`rounded-xl lg:w-2/4 ml-10 w-3/4`,
    content: [
      `Defend your home against disease-spreading mosquitoes with the All Out Ultra Liquid Vaporizer, now available in a pack of 6 refills (45ml each). Designed to effectively combat mosquitoes that transmit diseases such as Dengue, Malaria, and Chikungunya, All Out stands out as India's only mosquito killer brand recommended by the Indian Medical Association.`

      
    ],
  },

  {
    name:`7.Good-knight-Gold-Flash-Liquid-Vapourizer-Mosquito-Repellent-Refill-Pack`,
    title:`7. Good knight Gold Flash Liquid Vapourizer  Mosquito Repellent Refill  Pack of 4 (45ml each)`,
    tcolor:`lg:text-3xl text-xl mt-5 font-bold text-yellow-500`,
    wear:"Home Product",
    Scolor:"text-orange-600 font-bold",
    buy:"/images/amazon.jpg",
    blink:`https://amzn.urlvia.com/ba2OGV`,
    buy1:`/images/nosite.png`,
    blink1:``,
    buy2:`/images/nosite.png`,
    blink2:`` ,
    thumbnail:`/images/Grocery/7/g7.1.jpg`,
    image:`/images/Grocery/7/g7.2.jpg`,
    image1:`/images/Grocery/7/g7.3.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Instant and Lasting Protection`,
    paragraph:`Good knight Gold Flash Liquid Vapourizer Refill is engineered to deliver instant and lasting protection against mosquitoes. The advanced formula acts quickly, creating a shield that deters mosquitoes from entering your living spaces. This ensures that you and your family can enjoy uninterrupted peace of mind.`,
    heading1:`Fast-Acting Flash Mode`,
    paragraph1:`The Gold Flash technology in this mosquito repellent refill pack introduces a fast-acting Flash Mode. This mode is designed to provide an additional burst of protection, especially during peak mosquito hours, ensuring that your living spaces remain mosquito-free.`,
    heading2:`Pack of 4 for Prolonged Use`,
    paragraph2:`The convenience of a pack of 4 ensures that you have a prolonged and continuous supply of mosquito protection. Each 45ml refill is crafted to last, making it a cost-effective solution for keeping your home safe from mosquitoes for an extended period.`,
    heading3:`Odorless and Smoke-Free`,
    paragraph3:`Say goodbye to strong odors and smoke associated with traditional mosquito repellents. Good knight Gold Flash Liquid Vapourizer Refill is designed to be odorless, providing a comfortable and pleasant environment for you and your family.`,
    heading4:`Effortless and Compatible`,
    paragraph4:`Refilling your Good knight Gold Flash Liquid Vapourizer is a breeze. The easy-to-use design ensures a hassle-free experience, allowing you to enjoy mosquito protection without any complications. The refills are compatible with the Good knight Gold Flash Liquid Vapourizer device, making it a seamless addition to your household essentials.`,
    summary:"/images/amazon/primemembership/primemember1.jpg",
    jlink:`https://www.amazon.in/tryprime?tag=trendz12345-21
    `,
    bclass:`rounded-xl lg:w-2/4 ml-10 w-3/4`,
    content: [
      `Safeguard your home against mosquito nuisances with the Good knight Gold Flash Liquid Vapourizer Mosquito Repellent Refill Pack of 4 (45ml each). This powerful and effective solution is designed to provide long-lasting protection, allowing you to enjoy mosquito-free moments indoors.`

      
    ],
  },


  {
    name: `6.Dove-Intense-Repair-Shampoo-Repairs-Dry-and-Damaged-Hair`,
    title: `6.Dove Intense Repair Shampoo 1L Repairs Dry and Damaged Hair, Strengthening Shampoo for Smooth & Strong Hair - Mild Daily Shampoo for Men & Women `,
    tcolor:`lg:text-3xl text-xl mt-5 font-bold text-blue-700`,
    wear:"Beauty Product",
    Scolor:"text-orange-600 font-semibold",
    buy:`/images/amazon.jpg`,
    blink:`https://amzn.urlvia.com/Dy3vdu`,
    buy1:`/images/myntra.png`,
    blink1:`https://clnk.in/tN9Y`,
    buy2:`/images/nosite.png`,
    blink2:`` ,
    thumbnail:`/images/Grocery/6/g6.1.jpg`,
    image: `/images/Grocery/6/g6.2.jpg`,
    image1: `/images/Grocery/6/g6.3.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Repair and Restore`,
    paragraph:`Dove Intense Repair Shampoo is your go-to solution for dry and damaged hair. The advanced formula works to repair and restore your hair's health, providing a deeply nourishing treatment that goes beyond the surface. Experience the joy of revitalized hair that exudes strength and resilience.`,
    heading1:`Strengthening for Smooth Hair`,
    paragraph1:`This shampoo is not just about repair; it's about building strength. Dove Intense Repair is a strengthening shampoo that fortifies your hair, making it smooth and more resistant to damage. Enjoy the touch of strong, resilient hair that maintains its smoothness even in challenging conditions.`,
    heading2:`Intensive Hydration for Dry Hair`,
    paragraph2:`Bid farewell to dryness with the intense hydration provided by Dove Intense Repair Shampoo. The formulation is infused with moisturizing agents that penetrate deep into each strand, ensuring that your hair is nourished from within. Reclaim the natural moisture balance for hair that is silky and supple.`,
    heading3:`Mild Daily Cleansing`,
    paragraph3:`Gentle care is the cornerstone of Dove's philosophy, and this mild daily shampoo exemplifies it perfectly. Cleanse your hair without stripping away its natural oils, making it suitable for regular use. Experience a refreshing and pampering ritual with each application, leaving your hair feeling cleansed and rejuvenated.`,
    heading4:`For Strong and Smooth Hair`,
    paragraph4:`Dove Intense Repair Shampoo caters to those seeking a perfect balance between strength and smoothness. Its unique formulation is designed to strengthen your hair while maintaining a silky-smooth texture, offering a comprehensive solution for those with dry and damaged locks.`,
    summary:"/images/amazon/audible/audible3.jpg",
    jlink:`https://www.amazon.in/dp/B077S5CVBQ/?ref=assoc_tag_sept19?actioncode=AINOTH066082819002X&tag=trendz12345-21`,
    bclass:`rounded-xl lg:w-2/4 ml-10 w-3/4`,
    content: [
      `Elevate your daily hair care routine with Dove Daily Shine Shampoo, a 1L bottle designed to cater to the specific needs of damaged or frizzy hair. Crafted for both men and women, this mild daily shampoo goes beyond cleansing to provide a nourishing and indulgent experience for your locks.
      `

      
    ],
  },


  {
    name: `5.Dove-Daily-Shine-Shampoo-1L`,
    title: `5. Dove Daily Shine, Shampoo, 1L, for Damaged or Frizzy Hair, Makes Hair Soft, Shiny And Smooth, Mild Daily Shampoo, for Men & Women `,
    tcolor:`lg:text-3xl text-xl mt-5 font-bold text-violet-600`,
    wear:"Beauty Product",
    Scolor:"text-orange-600 font-semibold",
    buy:`/images/amazon.jpg`,
    blink:`https://amzn.urlvia.com/BkGDE4`,
    buy1:`/images/myntra.png`,
    blink1:`https://clnk.in/tNPM`,
    buy2:`/images/nosite.png`,
    blink2:`` ,
    thumbnail:`/images/Grocery/5/g5.1.jpg`,
    image: `/images/Grocery/5/g5.2.jpg`,
    image1: `/images/Grocery/5/g5.3.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Repair and Revitalize`,
    paragraph:`Dove Daily Shine Shampoo is a targeted solution for damaged or frizzy hair. Its specialized formula works to repair and revitalize your strands, leaving you with hair that not only looks healthier but feels softer and more manageable. Embrace the transformation as your hair gains a renewed vitality.`,
    heading1:`Intense Hydration for Frizz Control`,
    paragraph1:`Experience the power of intense hydration with Dove Daily Shine Shampoo. Formulated to combat frizz, this shampoo infuses moisture into your hair, taming unruly strands and providing you with the control you desire. Revel in the joy of having hair that is not only silky but also effortlessly styled.`,
    heading2:`All-Day Softness`,
    paragraph2:`.Indulge your hair in a daily dose of softness that lasts throughout the day. Dove Daily Shine Shampoo is designed to leave your hair irresistibly soft after every wash. Enjoy the touch of smoothness as you run your fingers through your revitalized tresses, promoting a delightful tactile experience.`,
    heading3:`Enhanced Shine Formula`,
    paragraph3:`Reveal the natural shine of your hair with Dove's enhanced shine formula. This shampoo doesn't just clean; it adds a radiant luster to your locks, making them gleam with health and vitality. Say goodbye to dullness and hello to a glossy, head-turning mane that reflects a vibrant, healthy glow.`,
    heading4:`Mild and Gentle Daily Cleansing`,
    paragraph4:`Dove understands the importance of gentle care for your hair. This mild daily shampoo is crafted to cleanse without stripping away essential oils, making it suitable for regular use. Enjoy a luxurious and nurturing experience with each application, leaving your hair feeling refreshed and pampered.`,
    summary:"/images/amazon/music/set1/set1.2.jpg",
    jlink:`http://amazon.in/music/prime?tag=trendz12345-21`,
    bclass:`rounded-xl lg:w-2/4 ml-10 w-3/4`,
    content: [
      `Elevate your daily hair care routine with Dove Daily Shine Shampoo, a 1L bottle designed to cater to the specific needs of damaged or frizzy hair. Crafted for both men and women, this mild daily shampoo goes beyond cleansing to provide a nourishing and indulgent experience for your locks.
      `

      
    ],
  },






  {
    name: `4.Dove-Hair-Fall-Rescue-Shampoo-1L `,
    title: `4. Dove Hair Fall Rescue, Shampoo, 1L, for Damaged Hair, with Nutrilock Actives, to Reduce Hairfall & Repair, Deep Nourishment to Damaged Hair `,
    tcolor:`lg:text-3xl text-xl mt-5 font-bold text-lime-600`,
    wear:"Beauty Product",
    Scolor:"text-orange-600 font-semibold",
    buy:"/images/amazon.jpg",
    blink:`https://amzn.urlvia.com/xQ2W3n`,
    buy1:`/images/flipkart.png`,
    blink1:`https://fktr.in/RmVn6DL`,
    buy2:`/images/nosite.png`,
    blink2:`` ,
    thumbnail: "/images/Grocery/4/g4.1.jpg",
    image: "/images/Grocery/4/g4.2.jpg",
    image1: "/images/Grocery/4/g4.3.jpg",
    icon:"/images/instagram.png",
    icon1:"/images/pinterest.png",
    icon2:"/images/whatsapp.png",
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Nutrilock Actives for Intensive Nourishment`,
    paragraph:`Dove Hair Fall Rescue Shampoo is enriched with Nutrilock Actives, a unique blend of ingredients that provide intensive nourishment to your hair. This advanced formula penetrates deep into the hair strands, helping to strengthen them from within and reducing the risk of hair fall caused by breakage.`,
    heading1:`Reduces Hair Fall and Promotes Healthy Growth`,
    paragraph1:`Say goodbye to excessive hair fall and hello to stronger, more resilient locks. Dove's Hair Fall Rescue Shampoo is specifically designed to address the common issue of hair fall, making it an ideal choice for those dealing with damaged or weakened hair. Regular use can contribute to healthier hair growth and a noticeable reduction in hair fall.`,
    heading2:`Repairs and Restores Damaged Hair`,
    paragraph2:`For hair that has been subjected to heat styling, chemical treatments, or environmental stressors, Dove Hair Fall Rescue Shampoo offers a reparative solution. The nourishing ingredients work to repair damaged strands, leaving your hair looking revitalized and feeling softer and smoother.`,
    heading3:`Gentle and Suitable for Daily Use`,
    paragraph3:`Dove understands the importance of gentle care for your hair. Despite its powerful formulation, the Hair Fall Rescue Shampoo remains gentle enough for daily use. Treat your hair to a luxurious and rejuvenating experience every time you step into the shower.`,
    heading4:`Long-Lasting 1L Bottle for Value and Convenience`,
    paragraph4:`With Dove Hair Fall Rescue Shampoo in a generous 1L bottle, you get value for your money and the convenience of having a long-lasting supply at your fingertips. Enjoy the benefits of a reliable and effective hair care solution without the need for frequent restocking.`,
    summary:"/images/amazon/music/set1/set2.2.jpg",
    jlink:`http://amazon.in/music/prime?tag=trendz12345-21`,
    bclass:`rounded-xl lg:w-2/4 ml-10 w-3/4`,
    content: [
      `Transform your hair care routine with Dove Hair Fall Rescue Shampoo, a powerful solution designed to address the needs of damaged hair. Formulated with Nutrilock Actives, this 1L bottle is your go-to companion for reducing hair fall and repairing the health of your locks.`

      
    ],
  },


  {
    name: `2.Himalaya-Herbals-Purifying-Neem-Face-Wash `,
    title: `2. Himalaya Herbals Purifying Neem Face Wash, 200ml `,
    tcolor:`lg:text-3xl text-xl mt-5 font-bold text-green-400-700`,
    wear:`Beauty Product`,
    Scolor:"text-orange-600 font-semibold",
    buy:`/images/amazon.jpg`,
    blink:`https://www.amazon.in/gp/product/B006G83ZXO/ref=ppx_od_dt_b_asin_title_s00?ie=UTF8&psc=1&tag=inrdeal123-21`,
    buy1:`/images/bigbasket.png`,
    blink1:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.bigbasket.com%2Fpd%2F40000439%2Fhimalaya-purifying-neem-face-wash-200-ml%2F`,
    buy2:`/images/myntra.png`,
    blink2:`https://inr.deals/MGBTqA` ,
    thumbnail: `/images/Grocery/2/G2.1.jpg`,
    image:`/images/Grocery/2/G2.2.jpg`,
    image1: `/images/Grocery/2/G2.3.jpg`,
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:`https://www.instagram.com/treind_z?igsh=MzRlODBiNWFlZA==`,
    pinterest:`https://in.pinterest.com/treind_z/`,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Potent Neem Power`,
    paragraph:`At the heart of this face wash lies the power of Neem, a revered herb in Ayurveda known for its purifying and antibacterial properties. Himalaya Herbals harnesses the potency of Neem to create a gentle yet effective face wash that cleanses your skin, helping to prevent acne and blemishes.`,
    heading1:`Deep Cleansing Action`,
    paragraph1:`Bid farewell to impurities and pollutants that accumulate on your skin throughout the day. The Purifying Neem Face Wash boasts a deep cleansing action that unclogs pores, removing excess oil and impurities. Regular use helps prevent the buildup of acne-causing bacteria, leaving your skin refreshed and revitalized.`,
    heading2:`Nourishing Turmeric and Vetiver`,
    paragraph2:`In addition to Neem, this face wash features the goodness of Turmeric and Vetiver. Turmeric is renowned for its anti-inflammatory properties, promoting a healthy complexion and preventing skin issues. Vetiver, with its cooling effect, soothes the skin, leaving it feeling invigorated after each use.`,
    heading3:`Suitable for All Skin Types`,
    paragraph3:`Whether you have oily, normal, or combination skin, Himalaya Herbals Purifying Neem Face Wash is designed to suit all skin types. Its gentle formulation ensures a balanced cleansing experience without stripping the skin of its natural moisture.`,
    heading4:`Refreshing Aroma`,
    paragraph4:`Indulge your senses with the refreshing aroma of Neem and Turmeric. The natural fragrance invigorates your senses, making your daily skincare routine a rejuvenating and uplifting experience.`,
    summary:`/images/amazon/music/set1/set1.1.jpg`,
    jlink:`http://amazon.in/music/prime?tag=trendz12345-21`,
    bclass:`rounded-xl w-2/4`,
    content: [
      `The secret to clear and radiant skin with Himalaya Herbals Purifying Neem Face Wash. Crafted from the goodness of natural ingredients, this 200ml formulation is your gateway to a purifying and refreshing skincare experience.`

      
    ],
  },




  {
    name:`3.Bajaj-New-Shakti-Neo-15LVertical-Storage-Water-Heater`,
    title:`3. Bajaj New Shakti Neo 15L Vertical Storage Water Heater`,
    tcolor:`lg:text-3xl text-xl py-4 font-bold text-zinc-700`,
    wear:`Electronic's product`,
    Scolor:"text-orange-600 font-semibold",
    buy:"/images/amazon.jpg",
    blink:`https://www.amazon.in/dp/B097R45BH8?ref_=cm_sw_r_apann_dp_AK7BMT2HSCQ266GJG6WK&language=en-IN&th=1&tag=inrdeal123-21`,
    buy1:`/images/nosite.jpg`,
    blink1:``,
    buy2:`/images/nosite.jpg`,
    blink2:`` ,
    thumbnail:`/images/Grocery/3/g3.1.jpg`,
    image: `/images/Grocery/3/g3.2.jpg`,
    image1: "/images/Grocery/3/g3.3.jpg",
    icon:`/images/instagram.png`,
    icon1:`/images/pinterest.png`,
    icon2:`/images/whatsapp.png`,
    instagram:``,
    pinterest:``,
    whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
    heading:`Efficient Heating with Star Rated Technology`,
    paragraph:`Bajaj New Shakti Neo is not just a water heater; it's a star-rated performer. The energy-efficient design ensures that you get hot water quickly while optimizing power consumption. This not only saves energy but also contributes to lower electricity bills, making it a smart and economical choice for your home..`,
    heading1:`Titanium Armour for Durability`,
    paragraph1:`The water heating element is reinforced with Titanium Armour, ensuring durability and longevity. This robust construction not only enhances the lifespan of the water heater but also makes it resistant to corrosion, adding to the appliance's overall reliability.`,
    heading2:`Swirl Flow Technology for Uniform Heating`,
    paragraph2:`Experience consistent and uniform heating every time you turn on the tap. The Swirl Flow Technology incorporated in the Bajaj New Shakti Neo ensures that hot water is distributed evenly, eliminating cold spots and providing you with a thoroughly satisfying bathing experience.`,
    heading3:`Glasslined Tank for Hygienic Water Storage`,
    paragraph3:`Maintaining hygiene in your water storage is paramount, and the Bajaj New Shakti Neo addresses this concern with its glasslined tank. The tank is designed to resist corrosion and bacterial growth, ensuring that the water you use is clean and safe for your family.`,
    heading4:`Space-Saving Wall Mounting Design`,
    paragraph4:`Optimize your bathroom space with the wall-mounting design of the Bajaj New Shakti Neo. This vertical storage water heater is compact and can be conveniently installed on the wall, freeing up valuable floor space. The sleek white finish adds a touch of modern elegance to your bathroom decor.`,
    summary:`/images/amazon/seller/sellerfull.jpg`,
    jlink:`https://sell.amazon.in/sell-online.html?ref_=asin_soa_rd&ld=INSOAASSOCIATES&tag=trendz12345-21`,
    bclass:`rounded xl`,
    content: [
      `Star Rated Heater| Water Heating with Titanium Armour & Swirl Flow Technology| Glasslined Tank| Wall Mounting| 1-Yr Warranty by Bajaj| White`

      
    ],
  },


    {
        name:`1.Navratna-Ayurvedic-Cool-Oil-Combination-of-Ayurvedic-Herbs-Relieves-Headache`,
        title: `1. Navratna Ayurvedic Cool Oil  Combination of 9 Ayurvedic Herbs  Relieves Headache, Body ache, Tiredness, Sleeplessness & Tension Improves Scalp Health`,
        tcolor:`lg:text-3xl text-xl py-4 font-bold text-red-500`,
        wear:"Grocery's Item",
        Scolor:"text-orange-600 font-semibold",
        buy:`/images/amazon.jpg`,
        blink:`https://www.amazon.in/dp/B01412X46Y?ref=nb_sb_ss_w_as-reorder_k2_1_9&amp=&crid=3V61BANFWQYF0&sprefix=dabur+nav&th=1&tag=inrdeal123-21`,
        buy1:`/images/nosite.jpg`,
        blink1:``,
        buy2:`/images/nosite.jpg`,
        blink2:`` ,
        thumbnail:`/images/Grocery/1/42.1.jpg`,
        image: `/images/Grocery/1/42.2.jpg`,
        image1:`/images/Grocery/1/42.3.jpg`,
        icon:`/images/instagram.png`,
        icon1:`/images/pinterest.png`,
        icon2:`/images/whatsapp.png`,
        instagram:``,
        pinterest:`https://in.pinterest.com/treind_z/`,
        whatsapp:`https://whatsapp.com/channel/0029VaGl6seIHph9OAIZWp12`,
        heading:`Relieves Headache and Body Ache`,
        paragraph:`Whether it's the stress of a long day or the strain of staring at screens, Navratna Ayurvedic Cool Oil is formulated to ease headaches and body aches. Gently massaging the oil onto your temples and affected areas helps soothe tension, providing quick and effective relief.`,
        heading1:`Alleviates Tiredness and Sleeplessness`,
        paragraph1:`Combat fatigue and sleeplessness with the revitalizing properties of Navratna Ayurvedic Cool Oil. The calming effect of the herbs helps relax the body and mind, promoting a sense of tranquility that aids in overcoming tiredness and achieving restful sleep.`,
        heading2:`Tension Buster`,
        paragraph2:`In today's fast-paced world, stress and tension are inevitable companions. Navratna Ayurvedic Cool Oil serves as a natural tension buster, helping you unwind and de-stress. A gentle massage with this cooling oil can alleviate tension and promote a sense of calm.`,
        heading3:`Improves Scalp Health`,
        paragraph3:`Beyond its therapeutic benefits, Navratna Ayurvedic Cool Oil nourishes and improves scalp health. Regular use can enhance blood circulation, reduce dandruff, and contribute to healthier, lustrous hair. The cooling sensation also provides a refreshing experience, leaving you revitalized.`,
        heading4:`Time-Tested Tradition`,
        paragraph4:`With a legacy rooted in Ayurveda, Navratna Ayurvedic Cool Oil embodies the time-tested wisdom of traditional Indian medicine. The formulation, passed down through generations, continues to be a trusted companion for those seeking natural and effective solutions for everyday ailments.`,
        summary:`/images/amazon/audible/audiblefull.jpg`,
        jlink:`https://www.amazon.in/dp/B077S5CVBQ/?ref=assoc_tag_sept19?actioncode=AINOTH066082819002X&tag=trendz12345-21`,
        bclass:`rounded-xl `,
        content: [
          `Navratna Ayurvedic Cool Oil is enriched with a blend of nine potent Ayurvedic herbs, each known for its ther`
        ],
    }
  ]

  export default groceries;