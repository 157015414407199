import React from 'react'
import Navbar from '../components/NavBar';
import Footer from '../components/Footer';
import Beautybanner from './layout/beautyBanner';
import Dotkey from './layout/dotkey';
import Foxtalecarousel from './layout/foxtaleCarousel'
import { Helmet } from "react-helmet";


const Beauty = () => {
  return (
    <div>

    

        <Navbar />

        <Helmet>
        <title>Discover Beauty Trends & Tips | Skincare, Makeup, and Haircare | TrendZ</title>
        <meta name="description" content="Discover the latest beauty products at TrendZ. From skincare to makeup, haircare, and more, find everything you need to enhance your beauty routine. Explore top brands and trending products to achieve your desired look. Shop now and elevate your beauty game with TrendZ!" />
        <meta name="keywords" keywords="beauty products, skincare, makeup, hair care, TrendZ beauty, beauty essentials, cosmetics, beauty brands, organic beauty, natural skincare, TrendZ blog, beauty tips, beauty routines, luxury beauty, beauty trends, beauty reviews, best beauty products, TrendZ beauty products, self-care, beauty must-haves" />
      </Helmet>


        <div>

            <Beautybanner />


        </div>

        <div>

          <div className='flex flex-wrap m-4'>

            <Dotkey />

            <div className='mx-10'>
              <a href='https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2F'>
              <img src='https://www.dotandkey.com/cdn/shop/files/desk_3_c727ec41-ced1-47a4-866e-64df4e7e9fab.gif?v=1711776376' alt='' />
              </a>
            </div>

          </div>

          <div className='border rounded-xl'>
            <h1 className='text-xl lg:text-3xl text-center font-semibold my-5'>Trendz Intro X Foxtale Best seller Product</h1>

            <Foxtalecarousel />
          </div>


        </div>
        



        <Footer />

    </div>
  )
}

export default Beauty