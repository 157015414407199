const data= [

    {
        brand:'Hammer:',
        title:'Screen TWS Bluetooth Earbuds with 32 db ANC + ENC and On Case Display',
        paragraph:`Active Noise Cancellation in Screen TWS Shut Out Noise And Unwanted Sound Upto 32db`,
        thumbnail:`https://hammeronline.in/cdn/shop/files/BestHammerScreenTWSEarbuds.webp?v=1713940395&width=1800`,
        H1:`TWS Bluetooth Earbuds`,
        P1:`Hammer Screen TWS Bluetooth Earbuds with 32 db ANC + ENC and On Case Display`,
        H2:`Smart Case To Access Everything`,
        P2:`Hammer screen tws wireless earbuds comes with a digital smart case by which you can control volume, play/pause music, play previous/next track, change wallpapers, enable noise reduction & music equalizer.`,
        H3:`Ultimate Bass`,
        P3:`With a strong 13mm sound driver, these ear buds will give you a relishing sound that you'll admire. Experience the heartbeat of your music with earbuds that boast deep, resonant bass.`,
        H4:`Find my Earbuds`,
        P4:`Locate your earbuds effortlessly by just tapping onto the screen tws. Whether they're misplaced in your bag or left behind, our app helps you locate them quickly, so you can get back to enjoying your music without a hitch.`,
        tradeRate:`₹2,799.`,
        mrpRate:`₹8,999`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fhammeronline.in%2Fproducts%2Fhammer-screen-tws-bluetooth-earbuds-with-anc-and-on-case-display%3F_pos%3D1%26_sid%3Db16f24719%26_ss%3Dr%26variant%3D42546015371400`,
        

    }

]

export default data 