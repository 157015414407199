const cardContent=[

    {   logo:`https://bira91.com/makeplay/svgs/monkey.svg`,
        img:'https://cdn.anscommerce.com/image/tr:e-sharpen-01,h-1000,w-800,cm-pad_resize/data/bira91/20may2024/8906147769577-1.jpg',
        title:'Bira 91 Mascot Opener',
        rate:'₹ 299', 
        category:'New Launches', 
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fshop.bira91.com%2Fbar-68413%2Fbira91-mascot-opener-8906147769577`
    },
    {
        logo:`https://bira91.com/makeplay/svgs/monkey.svg`,
        img:'https://cdn.anscommerce.com/image/tr:e-sharpen-01,h-1000,w-800,cm-pad_resize/data/bira91/20may2024/8906147769584-1.jpg',
        title:'Bira 91 Classic Opener',
        rate:'₹399', 
        category:`Limited Edition`, 
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fshop.bira91.com%2Fall-product%2Fbira91-classic-opener-8906147769584`
    },
    {
        logo:`https://bira91.com/makeplay/svgs/monkey.svg`,
        img:`https://cdn.anscommerce.com/image/tr:e-sharpen-01,h-1000,w-800,cm-pad_resize/data/bira91/21may2024/8906147769041_1.jpg`,
        title:`Makeplay Dart Board`,
        rate:`₹1,799`,
        category:'Most Selling',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fshop.bira91.com%2Fmakeplay-collection%2Fmakeplay-dart-board-8906147769041`  
    },
    {
        logo:`https://bira91.com/makeplay/svgs/monkey.svg`,
        img:'https://cdn.anscommerce.com/image/tr:e-sharpen-01,h-1000,w-800,cm-pad_resize/data/bira91/20-Jan-2023/8906101187522.jpg',
        title:'Festive Poker Set - 200 Chips',
        rate:'₹2,699',
        category:'best seller',
        blink:`https://shop.bira91.com/makeplay-collection/festive-poker-set-200-chips-8906101187522-bira91`,  
    },
    {
        logo:`https://bira91.com/makeplay/svgs/monkey.svg`,
        img:'https://cdn.anscommerce.com/image/tr:e-sharpen-01,h-1000,w-800,cm-pad_resize/data/bira91/20-Jan-2023/8906101187102.jpg',
        title:`Tumbling Tower - 54 Blocks`,
        rate:'₹1,199',
        category:'Trending',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fshop.bira91.com%2Fmakeplay-collection%2Ftumbling-tower-54-blocks-8906101187102-bira91`,  
    },
    {
        logo:`https://bira91.com/makeplay/svgs/monkey.svg`,
        img:`https://cdn.anscommerce.com/image/tr:e-sharpen-01,h-1000,w-800,cm-pad_resize/data/bira91/20-Jan-2023/8906101189663.jpg`,
        title:`Spin the Bottle`,
        rate:'₹1,199',
        category:`People's Choice`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fshop.bira91.com%2Fmakeplay-collection%2Fbira-91-spin-the-bottle-8906101189663-bira91`,  
    },
    {
        logo:`https://bira91.com/makeplay/svgs/monkey.svg`,
        img:`https://cdn.anscommerce.com/image/tr:e-sharpen-01,h-1000,w-800,cm-pad_resize/data/bira91/14-Feb-2023/8906101181315_1.jpg`,
        title:`Gold Fastest Finger First Board Game`,
        rate:'₹1259',
        category:'GenZ Choice',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fshop.bira91.com%2Fbira-91-gold-fastest-finger-first-board-game-8906101181315-bira91`,  
    },

    {
        logo:`https://bira91.com/makeplay/svgs/monkey.svg`,
        img:`https://cdn.anscommerce.com/image/tr:e-sharpen-01,h-1000,w-800,cm-pad_resize/data/bira91/07-jan-2022/8904130884405_1.jpg`,
        title:`Bira 91 X Boat Stone Spinx 2.0`,
        rate:`₹3,495`,
        category:'Boat collab',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fshop.bira91.com%2Fcollaborations-bira91-x-boat-boom-collection-68454%2Fbira91-x-boat-stone-spinx-2-0-8904130884405-bira91`,  
    },


]

export default cardContent;