 
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import { useEffect } from "react";
import Aos from "aos";

//pages
import Home from "./pages/Home";
import About from "./pages/About";
import Articlelist from "./pages/Articlelist";
import News from "./pages/News";

import Grocerylist from "./pages/Grocerylist";
import Article from "./pages/Article";
import Grocery from "./pages/Grocery";
import Electronic from "./pages4/Electronic";

import Watch from './pages/watch';
import Perfume from './pages/perfume';
import Airpods from "./pages/airpods";
import Glasses from "./pages/Glasses";
import Beauty from "./pages/Beauty";
import Footwear from "./pages/Footwear";


import Collection from './pages/collection';
import Bira from './pages/bira';
import Dailyobject from './pages/dailyObject';
import Micas from './pages/micas';
import { Helmet } from "react-helmet";





import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import NotFound from "./pages/Notfound";
//components





function App() {
  useEffect(() =>{
    Aos.init();
  },
  []);

  <Helmet>
  <title>TrendZ - Shop Watches, Airpods, Perfumes, Sunglasses, Beauty Products & Footwear | Top Brands: Puma, G-Shock, Aldo, Prada, Armani Exchange, and More</title>
  <meta name="description" content="Discover Trendz, your ultimate destination for top brands like Puma, G-Shock, Aldo, Prada, and more. Shop the latest in watches, Airpods, perfume, sunglasses, beauty products, and footwear from leading brands including Armani Exchange, Daily Object, Bira91, Micas, Dot & Key, Park Avenue, Foxtale, Amazon, and Flipkart. Elevate your style with Trendz today!" />

  <meta name="keywords" keywords="TrendZ, TrendZ fashion, TrendZ watches, TrendZ airpods, TrendZ perfume, TrendZ sunglasses, TrendZ beauty products, TrendZ footwear, Puma, G-shock, Aldo, Prada, Armani Exchange, Daily object, Bira91, Micas, dot&key, Park Avenue, Foxtale, Amazon, Flipkart, trendy fashion, online shopping, stylish accessories, luxury brands, high-quality products" />
</Helmet>



  return ( <Router>
    
    
    <div>

      
      
    
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/About" element={<About />} />
      <Route path="/Articlelist" element={<Articlelist />} />
      <Route path="/News" element={<News />} />
      
      <Route path= "/Grocerylist" element={<Grocerylist />} />
      <Route path="/Article/:name/" element={<Article />} />
      <Route path="/Grocery/:name" element={<Grocery />} />
      <Route path="/Electronic" element={<Electronic />} />
      <Route path="/Electronic/Airpods" element={<Airpods />} />
      <Route path="/Watch" element={<Watch />} />
      <Route path="/Perfume" element={<Perfume />} />
      <Route path="/Airpods" element={<Airpods />} />
      <Route path="/Glasses" element={<Glasses />} />
      <Route path="/Beauty" element={<Beauty />} />
      <Route path="/Footwear" element={<Footwear />} />
      
      <Route path="/collection" element={<Collection />} />
      <Route path="brand/bira" element={<Bira />} />
      <Route path="brand/Daily-objects" element={<Dailyobject />} />
      <Route path="brand/shopmicas" element={<Micas />} />


      
      <Route path='*' element={< NotFound />} />
      
      </Routes>
      
    </div>
    
   
    
    </Router>
    



    );

};

export default App;
