import React from "react";
import { Link } from "react-router-dom";
import homeContent from "../pages/homeContent"


              




const Navbarf = () => {
    return (
        <>
        {homeContent.map((home, index)=>(
            <div className=" flex flex-col items-center">
        <div className="flex flex-row pt-9 gap-2" key={index}>
           
        <Link to="/" > <img
        data-aos="fade-up"
        data-aos-duration="1500" className="w-24 h-24  rounded-full" src={home.thumbnail}  alt="logo"/></Link>
      <Link to="/"><h1
      data-aos="fade-down"
      data-aos-duration="1500"
       className="text-3xl pt-3  text-black font-medium font-jakarta">TrendZ</h1>

        </Link>
       
        </div>
          
          
     
          <div className="mt-8 mx-5 items-center md:left-1/2 md:mx-[14rem] xl:mx-[28rem] rounded-3xl  p-4 bg-white bg-opacity-[-.08] 
        backdrop-blur-lg border border-black boder-opacity-[.08]">
        
        <nav className="flex-col items-center">
              <ul 
            data-aos="fade-left"
            data-aos-duration="2000"
            className="flex felx-col  gap-1 text-[#bdbdc0] mx-2 font-normal  md:font-medium font-jakarta">
                <li  className="">
                    <Link to="/" className="text-black px-3 py-4 hover:bg-gradient-to-r from-orange-500 to-red-500 rounded-full">Home</Link>

                </li>
                <li >
                    <Link to="/About" className=" text-black px-3 pl-3 py-4 hover:bg-gradient-to-r from-orange-500 to-red-500 rounded-full ">About</Link>

                </li>
                <li>
                    <Link to="/Articlelist" className=" text-black px-3 py-4 hover:bg-gradient-to-r from-orange-500 to-red-500 rounded-full ">Articles</Link>


                </li>
              
                
               
           
            </ul>
        </nav>
        

        </div>
        

        </div>
        )
        )}
   

        
      
       </> 
    )

};

export default Navbarf;