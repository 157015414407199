import { MantineProvider, Card, Image, Text, Group, Badge, Button } from '@mantine/core';
import { IconGardenCart } from '@tabler/icons-react';

import classes from './mantineCss/FeaturesCard.module.css';










 const watchList = ({data}) =>{ 

  return (
    <MantineProvider >
      {data.map((product, index) => (
    <Card key={index} withBorder radius="md"  className={classes.card}>
      <Card.Section  className={classes.imageSection}>
        <Image src={product.thumbnail} alt="Tesla Model S" />
      </Card.Section>

      <Group justify="space-between" mt="md">
        <div>
          <Text fw={500}>{product.title.substring(0, 35)}...</Text>
          <Text fz="xs" c="dimmed">
            Original
          </Text>
        </div>
        <Badge variant="outline">{product.wear}</Badge>
      </Group>

      <Card.Section className={classes.section} mt="md">
        <Text fz="sm" c="dimmed" className={classes.label}>
          Available On
        </Text>

        <Group gap={8} mb={-8}>
          <div className='flex flex-wrap gap-3 '>
          <a href={product.blink}> <img className='w-12' src={product.brand}  alt=''/> </a>
          <a href={product.blink1}><img className='w-12' src={product.brand1}  alt=''/> </a>
          </div>
        </Group>
      </Card.Section>

      <Card.Section className={classes.section}>
        <Group gap={30}>
          <div className=''>
            <Text fz="xl" fw={700} style={{ lineHeight: 1 }}>
              {product.tradeRate}
            </Text>
            <Text fz="sm" c="dimmed" fw={500} style={{ lineHeight: 1 }} mt={3}>
              <del>{product.mrpRate}</del>
            </Text>
            </div>
            

          <Button radius="xl" color='orange' style={{ flex: 1 }}>
            
            <a href={product.blink} className='' ><IconGardenCart /> </a>
            
          </Button>
          
        </Group>
      </Card.Section>
    </Card>
      ))}

    </MantineProvider>
  );
}
 export default watchList
