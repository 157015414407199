import React from 'react'
import Sunglasslistcontent from '../content/sunglassListContent.js'

const sunglassList = () => {
  return (
    <div> <div className='flex  items-center justify-center my-7 mx-5'>
       
    <div  className='grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 '>
    {Sunglasslistcontent.map((card,index) => (
      
      <div key={index}  className='group relative item-center justify-center overflow-hidden cursor-pointer  hover:shadow-xl hover:shadow-black/30 transition-shadow border rounded-xl'  >
      
         <div  className='h-60 w-84 md:h-96 md:w-72'><img src={card.thumbnail}
          alt='bnner' className='h-full w-full object-cover group-hover:rotate-1 group-hover:scale-125 transition-transform duration-500'/>
         </div>
         
      
         <div className='absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black  group-hover:via-black/60 to group-hover:to-black/70'>
         </div>
         <div className='absolute inset-0 flex flex-col items-center justify-center px-9 text-center translate-y-[60%] group-hover:translate-y-0 transition-all' >
           <h1 className='text-lg lg:text-3xl font-dmserif font-bold text-white '>{card.title}</h1>
           <p className='text-lg italic text-white mb-3 opacity-0 group-hover:opacity-100 transition-opacity'>{card.name}</p>
           <a href={card.blink} className='rounded-full shadow shadow-black/60 py-2 bg-white px-3.5 text-xl capitalize  text-black font-semibold text-center'><img className='w-2/4 center' src={card.brand} alt='' />{card.tradeRate}</a>
         
         </div>
         
         
         
       </div>

     
       
       ) )}
     
     </div>
   </div>
   </div>
  )
}

export default sunglassList