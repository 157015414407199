import React from 'react'
import { Carousel } from '@mantine/carousel';
import { MantineProvider } from '@mantine/core';
import { Link } from 'react-router-dom';



const brandCarousel = () => {
  return (
    <div>
         <MantineProvider withGlobalStyles withNormalizeCSS>
         <h1 className='text-5xl font-light mb-5 text-center'>Brands</h1>

         <Carousel className='  '
          mx="auto"
          
      withIndicators
      loop
      align="start"
      slidesToScroll={1}
    >
        
      <Carousel.Slide className='bg-rose-500 rounded-xl overflow-hidden'>
        <Link to='brand/bira'className='' ><img className='hover: w-full' src='https://asset.brandfetch.io/idz16AejGA/idduyTT5Tk.png'
       alt='brand' />
      
       <h1 className='xl:text-3xl text-center my-3 text-xl font-semibold '>Bira</h1>
       </Link>
      
     
       </Carousel.Slide>
       <Carousel.Slide   className=' border-2 bg-green-500 border-opacity-60 rounded-xl overflow-hidden'>
        <Link to='brand/Daily-objects'><img className='hover:' src='https://m.media-amazon.com/images/S/stores-image-uploads-eu-prod/f/AmazonStores/A21TJRUUN4KGV/10dadb443756ab7a8d65018b47143bc7.w500.h500.png'
       alt='brand' />
      
       <h1 className='text-3xl font-semibold text-center my-3'>Daily Objects</h1></Link>
      
     
       </Carousel.Slide>

       <Carousel.Slide className=' border-2 bg-white  border-opacity-60 rounded-xl overflow-hidden'>
        <Link to='brand/shopmicas' className='w-32 h-46 lg:w-72 lg:h-84'> <img className='mt-10 lg:mt-14  p-3 hover:bg-white' src='https://1000logos.net/wp-content/uploads/2023/11/Micas-Logo.png'
       alt='brand' />
      <h1 className='text-3xl font-semibold text-center my-3'>Micas</h1></Link>
       

       
      
     
       </Carousel.Slide>
       
      
       
      
      
       
     
       
      
      {/* ...other slides */}
    </Carousel>

         </MantineProvider>
      
    </div>
  )
}

export default brandCarousel
