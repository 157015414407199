const data = [
    {
        
        name: `FRANCESCA BEIGE EMBROIDERED`,
        href: `https://www.snitch.co.in/products/francesca-beige-embroidered-shirts?ref=3wSXfTh7wAmkpb&variant=44755820937378`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/12c55027aabc868f9163d9278ede6084.webp?v=1720634638&width=1800`,
        imageAlt: "Front of men's Shirt in beige.",
        price: 'INR 999',
        color: 'BEIGE',
    },
    {
        
        name: `LINING BLACK`,
        href: `https://www.snitch.co.in/products/lining-black-shirt?ref=3wSXfTh7wAmkpb&variant=41704440660130`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/products/Snitch_Feb3rd_22_4060.jpg?v=1644603996&width=1800`,
        imageAlt: "Front of men's Shirt in Black.",
        price: 'INR 999',
        color: 'Shirt',
    },
    {
        
        name: `HANNE ABSTRACT WHITE`,
        href: `https://www.snitch.co.in/products/hanne-abstract-white-shirt?ref=3wSXfTh7wAmkpb`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/05f3c942241254f0a1413ab383921106.webp?v=1718186797&width=1800`,
        imageAlt: "Front of men's Shirt in White.",
        price: 'INR 1,199',
        color: 'Shirt',
    },
    {
        
        name: `VIDAR WHITE PLAIN `,
        href: `https://www.snitch.co.in/products/vidar-white-plain-shirts?ref=3wSXfTh7wAmkpb&variant=44694920036514`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/f9e0c19705a928178bc14649e1f0ef43.webp?v=1719506889&width=1800`,
        imageAlt: "Front of men's Shirt in White.",
        price: 'INR 899',
        color: 'Shirt',
    },

    {
        
        name: `NORDIC BEIGE POLO`,
        href: `https://www.snitch.co.in/products/nordic-beige-polo-t-shirt?ref=3wSXfTh7wAmkpb&variant=43737078169762`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4MST2180-03-M21.jpg?v=1696841179&width=1800`,
        imageAlt: "Front of men's Polo T-shirt in Beige.",
        price: 'INR 1,299',
        color: 'T-shirt',
    },

    {
        
        name: `FRESCO BEIGE KNITTED POLO`,
        href: `https://www.snitch.co.in/products/fresco-beige-knitted-polo-t-shirt?ref=3wSXfTh7wAmkpb&variant=44727913513122`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/dc19be3e8cbe5d7775976f8c73cecc99.webp?v=1720001279&width=1800`,
        imageAlt: "Front of men's Polo T-shirt in Beige.",
        price: 'INR 1,299',
        color: 'T-shirt',
    },
    {
        
        name: `MESH KNIT BEIGE POLO`,
        href: `https://www.snitch.co.in/products/mesh-knit-beige-polo-t-shirt?ref=3wSXfTh7wAmkpb&variant=43326258708642`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4MST2080-01-M14777.jpg?v=1686661049&width=1800`,
        imageAlt: "Front of men's Polo T-shirt in Beige.",
        price: 'INR 1,199',
        color: 'T-shirt',
    },

    {
        
        name: `BALDASSARE BROWN KNITTED POLO`,
        href: `https://www.snitch.co.in/products/baldassare-brown-knitted-polo-t-shirt?ref=3wSXfTh7wAmkpb&variant=44632889000098`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/bcaac71cd5f6c55638e3c86f39933bea.webp?v=1718971920&width=1800`,
        imageAlt: "Front of men's Polo T-shirt in Brown.",
        price: 'INR 1,299',
        color: 'T-shirt',
    },

    {
        
        name: `COULTER KNITTED WHITE POLO`,
        href: `https://www.snitch.co.in/products/coulter-knitted-white-polo-t-shirt?ref=3wSXfTh7wAmkpb&variant=43813404377250`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4MST2178-03-M155.webp?v=1698998250&width=1800`,
        imageAlt: "Front of men's Polo T-shirt in Off-White.",
        price: 'INR 1,299',
        color: 'T-shirt',
    },
    {
        
        name: `NORDIC BLUE POLO`,
        href: `https://www.snitch.co.in/products/nordic-blue-polo-t-shirt?ref=3wSXfTh7wAmkpb&variant=43737032982690`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4MST2180-02-M37.jpg?v=1696841034&width=1800`,
        imageAlt: "Front of men's Polo T-shirt in Blue.",
        price: 'INR 1,299',
        color: 'T-shirt',
    },
    {
        
        name: `NORDIC BLACK POLO`,
        href: `https://www.snitch.co.in/products/nordic-black-polo-t-shirt?ref=3wSXfTh7wAmkpb&variant=43737016008866`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4MST2180-01-M45.jpg?v=1696915777&width=1800`,
        imageAlt: "Front of men's Polo T-shirt in Black.",
        price: 'INR 1,299',
        color: 'T-shirt',
    },

    {
        
        name: `SELF CHECKER PINK`,
        href: `https://www.snitch.co.in/products/self-checker-pink-shirt?ref=3wSXfTh7wAmkpb&variant=44747810603170`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4MSS2334-01-M25.jpg?v=1690705194&width=1800`,
        imageAlt: "Front of men's Shirt in Pink.",
        price: 'INR 999',
        color: 'Shirt',
    },

    {
        
        name: `SPARKLING BEIGE`,
        href: `https://www.snitch.co.in/products/sparkling-beige-shirt?ref=3wSXfTh7wAmkpb&variant=44746507583650`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/products/Snitch_July22_3018.jpg?v=1688542099&width=1800`,
        imageAlt: "Front of men's Shirt in Beige.",
        price: 'INR 1199',
        color: 'Shirt',
    },

    {
        
        name: `TERRAKNIT KNITTED CROCHET BROWN `,
        href: `https://www.snitch.co.in/products/terraknit-textured-brown-shirt?ref=3wSXfTh7wAmkpb&variant=44599596875938`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/2807613e3a5e1e77a40283dd8fbca538_7abcbb04-b59d-4726-84cc-dc9c0c38146e.webp?v=1718884509&width=1800`,
        imageAlt: "Front of men's Shirt in Brown.",
        price: 'INR 1199',
        color: 'Shirt',
    },

    {
        
        name: `ORNATE SQUARE PINK`,
        href: `https://www.snitch.co.in/products/ornate-square-pink-shirt?ref=3wSXfTh7wAmkpb&variant=44681098166434`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/products/Snitch_March22_0100-1.jpg?v=1652164963&width=180`,
        imageAlt: "Front of men's Shirt in Pink.",
        price: 'INR 899',
        color: 'Shirt',
    },

    {
        
        name: `ENZO DARK BROWN CHECKS`,
        href: `https://www.snitch.co.in/products/enzo-dark-brown-checks-shirts?ref=3wSXfTh7wAmkpb&variant=44727903715490`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4ff9a5481b97fe2a7afcc0c97ae6dd17.webp?v=1719983115&width=1800`,
        imageAlt: "Front of men's Shirt in Maroon.",
        price: 'INR 1199',
        color: 'Shirt',
    },

    {
        
        name: `VIDAR OLIVE PLAIN`,
        href: `https://www.snitch.co.in/products/vidar-olive-plain-shirts?ref=3wSXfTh7wAmkpb&variant=44694926065826`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/5251574088d96029e4926787b7d0fe6a.webp?v=1719506699&width=1800`,
        imageAlt: "Front of men's Shirt in Olive.",
        price: 'INR 899',
        color: 'Shirt',
    },

    {
        
        name: `CAMRIC BLUE LINEN `,
        href: `https://www.snitch.co.in/products/camric-blue-linen-shirt?ref=3wSXfTh7wAmkpb&variant=44556071567522`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/f3f62543fda058f8e6842b674964fd89.webp?v=1718609898&width=1800`,
        imageAlt: "Front of men's Shirt in Blue.",
        price: 'INR 1,899',
        color: 'Shirt',
    },

    {
        
        name: `MADRIGO BLACK`,
        href: `https://www.snitch.co.in/products/madrigo-black-shirt?ref=3wSXfTh7wAmkpb&variant=44075825266850`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4MSS2513-01-M38.jpg?v=1711023702&width=1800`,
        imageAlt: "Front of men's Shirt in Black.",
        price: 'INR 1,299',
        color: 'Shirt',
    },

    {
        
        name: `VIGGO PLAIN WHITE`,
        href: `https://www.snitch.co.in/products/madrigo-black-shirt?ref=3wSXfTh7wAmkpb&variant=44075825266850`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/acfaeb59bd0f82c8c53307f8e630385d.webp?v=1717584063&width=1800`,
        imageAlt: "Front of men's Shirt in White.",
        price: 'INR 999',
        color: 'Shirt',
    },

    {
        
        name: `SIGRID NAVY CHECKED`,
        href: `https://www.snitch.co.in/products/sigrid-navy-checked-shirt?ref=3wSXfTh7wAmkpb&variant=44604919120034`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/ecf0afc0f331eab6f7ad74b5cc1b7fd5.webp?v=1718972102&width=1800`,
        imageAlt: "Front of men's Checked Shirt.",
        price: 'INR 1,199',
        color: 'Shirt',
    },

    {
        
        name: `SHIRTOLO PLAIN SYRUP BROWN`,
        href: `https://www.snitch.co.in/products/shirtolo-plain-syrup-brown-shirt?ref=3wSXfTh7wAmkpb&variant=44604882354338`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/3955f02e1ab891590c7077027ac56818.webp?v=1717596041&width=1800`,
        imageAlt: "Front of men's Shirt in Brown.",
        price: 'INR 1,199',
        color: 'Shirt',
    },

    {
        
        name: `LINEN LINE PEACH STRIPE`,
        href: `https://www.snitch.co.in/products/linen-line-peach-stripe-shirt?ref=3wSXfTh7wAmkpb&variant=44686558101666`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4aa3c039c780db93f10c086c01bd5056_006c1984-1312-490b-8146-6b0e7c76d694.webp?v=1717223196&width=1800`,
        imageAlt: "Front of men's Shirt in Peach.",
        price: 'INR 1,199',
        color: 'Shirt',
    },

    {
        
        name: `TEXTURITY TEXTURED OLIVE`,
        href: `https://www.snitch.co.in/products/texturity-textured-olive-shirt?ref=3wSXfTh7wAmkpb&variant=44505823871138`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/7a2161cfd16e1b7419e0ac5497ec8156.webp?v=1716785102&width=1800`,
        imageAlt: "Front of men's Shirt in Olive.",
        price: 'INR 1,199',
        color: 'Shirt',
    },

    {
        
        name: `EINAR LIGHT BLUE CHECKS`,
        href: `https://www.snitch.co.in/products/einar-light-blue-checks-shirt?ref=3wSXfTh7wAmkpb&variant=44727902863522`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/d0c782b781d414d18bb990f4d40e922a.webp?v=1719987368&width=1800`,
        imageAlt: "Front of men's Shirt in Blue.",
        price: 'INR 1,199',
        color: 'Shirt',
    },

    {
        
        name: `CLEMENCE MAROON STRIPES`,
        href: `https://www.snitch.co.in/products/clemence-maroon-stripes-shirts?ref=3wSXfTh7wAmkpb&variant=44661834612898`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/b1283801796894c0882b70414aaa7187_0dd77475-f598-4848-9cdb-287f0d6db2af.webp?v=1718971094&width=1800`,
        imageAlt: "Front of men's Shirt in Maroon.",
        price: 'INR 999',
        color: 'Shirt',
    },

    {
        
        name: `SVERRE STRIPE BEIGE`,
        href: `https://www.snitch.co.in/products/sverre-stripe-beige-shirt?ref=3wSXfTh7wAmkpb&variant=44632821661858`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/2c22632a759cd5245554767e52ab09d0.webp?v=1718272026&width=1800`,
        imageAlt: "Front of men's Shirt in Beige.",
        price: 'INR 999',
        color: 'Shirt',
    },

    {
        
        name: `SUEDINEER ASH GREY PLAIN`,
        href: `https://www.snitch.co.in/products/suedineer-ash-grey-plain-shirt?ref=3wSXfTh7wAmkpb&variant=44686502658210`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/2c73b63c030e29bbb5341f54c68c8ec0.webp?v=1716315995&width=1800`,
        imageAlt: "Front of men's Shirt in Grey.",
        price: 'INR 1299',
        color: 'Shirt',
    },

    {
        
        name: `FLEXIFORM WHITE TEXTURED`,
        href: `https://www.snitch.co.in/products/flexiform-white-textured-shirt?ref=3wSXfTh7wAmkpb&variant=44505932431522`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/3c5cde5260b8437731b2958addda85e3.webp?v=1715689975&width=1800`,
        imageAlt: "Front of men's Shirt in White.",
        price: 'INR 1,199',
        color: 'Shirt',
    },

    {
        
        name: `STRIPEHAVEN GREEN STRIPE`,
        href: `https://www.snitch.co.in/products/stripehaven-green-stripe-shirt?ref=3wSXfTh7wAmkpb&variant=44512611696802`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4a1edc0a92dfb40e40ca5c4e868614e7_0983938b-e65b-48ed-9e1f-5e786bc085c0.webp?v=1716314834&width=1800`,
        imageAlt: "Front of men's Shirt in Green.",
        price: 'INR 1,199',
        color: 'Shirt',
    },

    {
        
        name: `STRIPARIFFIC NAVY SELF-DESIGN`,
        href: `https://www.snitch.co.in/products/stripariffic-navy-self-design-shirt?ref=3wSXfTh7wAmkpb&variant=44506043056290`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/eb1d9ae53b558bcb4caa5ba7fccfd3b3.webp?v=1715690495&width=1800    `,
        imageAlt: "Front of men's Shirt in Navy Blue.",
        price: 'INR 999',
        color: 'Shirt',
    },

    {
        
        name: `CHECKSQUE WHITE CHECKERED`,
        href: `https://www.snitch.co.in/products/checksque-white-checkered-shirt?ref=3wSXfTh7wAmkpb&variant=44505997410466`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/c3ea5a950bc46026caa380ad77a82aa5.webp?v=1715751669&width=1800`,
        imageAlt: "Front of men's Checkered Shirt in White.",
        price: 'INR 1,299',
        color: 'Shirt',
    },

    {
        
        name: `SLUMBERQUILT GREEN SELF DESIGN`,
        href: `https://www.snitch.co.in/products/slumberquilt-green-self-design-shirt?ref=3wSXfTh7wAmkpb&variant=44746503913634`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/fdff0ad277e9ba0dee16fcfdd0027461.webp?v=1714803300&width=1800`,
        imageAlt: "Front of men's Shirt in Green.", 
        price: 'INR 1,299',
        color: 'Shirt',
    },

    {
        
        name: `SLEEK TWILL BROWN`,
        href: `https://www.snitch.co.in/products/sleek-twill-brown-shirt?ref=3wSXfTh7wAmkpb&variant=44341580824738`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4MSS2740-02-M16.jpg?v=1715681750&width=1800`,
        imageAlt: "Front of men's Shirt in Brown.",
        price: 'INR 1,399',
        color: 'Shirt',
    },

    {
        
        name: `CHARBONYX STRIPE BLACK`,
        href: `https://www.snitch.co.in/products/charbonyx-stripe-black-shirt?ref=3wSXfTh7wAmkpb`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4MSS2756-02-M26.jpg?v=1714814925&width=1800`,
        imageAlt: "Front of men's Shirt in Black.",
        price: 'INR 999',
        color: 'Shirt',
    },

    {
        
        name: `SKYTHREAD YELLOW`,
        href: `https://www.snitch.co.in/products/skythread-yellow-shirt?ref=3wSXfTh7wAmkpb&variant=44746440868002`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/5ad9c2e8d67877271d0b2eac4a14f331.webp?v=1715165760&width=1800`,
        imageAlt: "Front of men's Shirt in Yellow.",
        price: 'INR 1,199',
        color: 'Shirt',
    },
    {
        
        name: `OCTET SKY BLUE`,
        href: `https://www.snitch.co.in/products/octet-sky-blue-shirt?ref=3wSXfTh7wAmkpb&variant=44681091448994`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/products/geovany48573.jpg?v=1668701823&width=1800`,
        imageAlt: "Front of men's Shirt in Sky Blue.",
        price: 'INR 1,099',
        color: 'Shirt',
    },















    

]

export default data