import { Carousel } from '@mantine/carousel';
import {  MantineProvider } from '@mantine/core';
import Snitch from './ProductList';


const Dailobjectcarousel = ({data}) =>{
  

  return (
   <MantineProvider >
    <div className=''> 
  <Carousel withIndicators 
 >
  

  <Carousel.Slide > 
  <Snitch /></Carousel.Slide>
  </Carousel>
 </div>
    </MantineProvider> 
  

)}

export default Dailobjectcarousel