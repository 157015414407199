const data =[
    

     {
        title:`Palermo OG `,
        tradeRate:`₹6,999`,
        feature:`unisex Sneaker`,
        thumbnail:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/383011/07/sv01/fnd/IND/fmt/png/Palermo-OG-Unisex-Sneakers`,
        blink:`https://www.tkqlhce.com/click-101131564-15577250?url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fpd%2Fpalermo-og-unisex-sneakers%2F383011%3Fswatch%3D07`,
     },

     {
        title:`CLOUD TEC SHOES`,
        tradeRate:`₹2759`,
        feature:`Running Shoes`,
        thumbnail:`https://assets.adidas.com/images/h_840,f_auto,q_auto,fl_lossy,c_fill,g_auto/3e335bb7c4594edab05529e72581589c_9366/CLOUD_TEC_SHOES_Black_IU6261_01_standard.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.adidas.co.in%2Fcloud-tec-shoes%2FIU6261.html`,
     },

     {
        title:`Supernova 3`,
        tradeRate:`₹5999`,
        feature:`Running Shoes`,
        thumbnail:`https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/e8d33874b9d149f6b345d938877059c4_9366/Supernova_3_Running_Shoes_White_HQ1806_HM1.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.adidas.co.in%2Fsupernova-3-running-shoes%2FHQ1806.html`,
     },

     {
        title:`Nike Pegasus 41`,
        tradeRate:`₹11,895`,
        feature:`Running Shoes`,
        thumbnail:`https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/0558cdb5-4d42-4f4d-9b37-5038f1f97f9f/AIR+ZOOM+PEGASUS+41.png`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.nike.com%2Fin%2Ft%2Fpegasus-41-road-running-shoes-RZm89S%2FFD2722-103`,
     },

     {
        title:`ZAPCORE SHOES`,
        tradeRate:`₹2,399`,
        feature:`Running Shoes`,
        thumbnail:`https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/1a33e71ae22d4c06a3e9b077ee4335e7_9366/ZAPCORE_SHOES_Black_IU6705_01_standard.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.adidas.co.in%2Fzapcore-shoes%2FIU6705.html`,
     },

     {
        title:`Duramo SL Shoes`,
        tradeRate:`₹3959`,
        feature:`Running Shoes`,
        thumbnail:`https://assets.adidas.com/images/h_840,f_auto,q_auto,fl_lossy,c_fill,g_auto/82d2cd160ee84a5189e319c8b08b13d4_9366/Duramo_SL_Shoes_Black_IE7963_01_standard.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.adidas.co.in%2Fduramo-sl-shoes%2FIE7963.html`,
     },

     {
      title:`Marvel Edition Shoes`,
      tradeRate:`₹2,799`,
      feature:`Walking Shoes`,
      thumbnail:`https://images.bewakoof.com/t1080/men-s-maroon-black-deadpool-color-block-high-top-sneakers-630669-1721909806-1.jpg`,
      blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.bewakoof.com%2Fp%2Fmens-maroon-black-color-block-high-top-sneakers`,
   },

   {
      title:`Wolverine: Weapon X`,
      tradeRate:`₹1,999`,
      feature:`Walking Shoes`,
      thumbnail:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1721458947_6270300.jpg?format=webp&w=480&dpr=1.5`,
      blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.thesouledstore.com%2Fproduct%2Fwolverine-logans-kicks-men-low-top-sneakers%3Fgte%3D1`,
   },

   {
      title:`Harry Potter: Slytherin`,
      tradeRate:`₹2,499`,
      feature:`Walking Shoes`,
      thumbnail:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1722481821_5261951.jpg?format=webp&w=480&dpr=1.5`,
      blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.thesouledstore.com%2Fproduct%2Fmen-low-top-sneaker-harry-potter-slytherin%3Fgte%3D1`,
   },

   {
      title:`Urban Blaze: Grassland`,
      tradeRate:`₹2,499`,
      feature:`Walking Shoes`,
      thumbnail:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1722085680_8179082.jpg?format=webp&w=480&dpr=1.5`,
      blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.thesouledstore.com%2Fproduct%2Ftss-originals-natural-men-low-top-sneakers%3Fgte%3D1`,
   },

     
]

export default data