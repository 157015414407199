import React from 'react'

const snitch = () => {
  return (


          <div className="relative overflow-hidden bg-white">
            <div className="pb-80 pt-16 sm:pb-40 sm:pt-24 lg:pb-48 lg:pt-40">
              <div className="relative mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
                <div className="sm:max-w-lg">
                  <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                  TrendZ X Intro's Snitch
                  </h1>
                  <p className="mt-4 text-xl text-gray-500">
                  Snitch offers trendy, high-quality clothing for men, combining style and comfort for a modern wardrobe..
                  </p>
                  <p className="mt-4 text-xl text-gray-500 font-bold">
                  Get Extra 15% Discount Use Coupon 
                 <span className='font-bold text-black text-2xl'> TRENDZBLOG </span>
                  </p>
                </div>
                <div>
                  <div className="mt-10">
                    {/* Decorative image grid */}
                    <div
                      aria-hidden="true"
                      className="pointer-events-none lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl"
                    >
                      <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                        <div className="flex items-center space-x-6 lg:space-x-8">
                          <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                            <div className="h-64 w-44 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100">
                              <img
                                src="https://www.snitch.co.in/cdn/shop/files/657b235705553b9102e118d69c164174_84140feb-6f71-4378-a4d6-c76b4ed89c3f.webp?v=1719232506&width=1800"
                                alt=""
                                className="h-full w-full object-cover object-center"
                              />
                            </div>
                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                              <img
                                src="https://www.snitch.co.in/cdn/shop/files/0c3c77eb63a7ea8ef641a123748b47cb.webp?v=1717516527&width=1800"
                                alt=""
                                className="h-full w-full object-cover object-center"
                              />
                            </div>
                          </div>
                          <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                              <img
                                src="https://www.snitch.co.in/cdn/shop/files/4MSO4515-04-324.jpg?v=1696008288&width=1800"
                                alt=""
                                className="h-full w-full object-cover object-center"
                              />
                            </div>
                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                              <img
                                src="https://www.snitch.co.in/cdn/shop/files/4MSS2719-01-M25.jpg?v=1711519165&width=1800"
                                alt=""
                                className="h-full w-full object-cover object-center"
                              />
                            </div>
                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                              <img
                                src="https://www.snitch.co.in/cdn/shop/files/4MSS3012-03-M35.jpg?v=1717497214&width=1800"
                                alt=""
                                className="h-full w-full object-cover object-center"
                              />
                            </div>
                          </div>
                          <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                              <img
                                src="https://www.snitch.co.in/cdn/shop/files/b8feb1c90f4d9829f6fafa4e812e977c.webp?v=1719548007&width=1800"
                                alt=""
                                className="h-full w-full object-cover object-center"
                              />
                            </div>
                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                              <img
                                src="https://www.snitch.co.in/cdn/shop/files/4MSS2850-05-M46.jpg?v=1708507731&width=1800"
                                alt=""
                                className="h-full w-full object-cover object-center"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
      
                    <a
                      href="https://www.snitch.co.in/collections/new-drops?gad_source=1&gclid=CjwKCAjwp4m0BhBAEiwAsdc4aN2dzOqKlt5LlZScnrQIA3TPhoDu64zqJJcRP_dRlIdi3dEUHWB0uxoCCosQAvD_BwE&page=2&ref=3wSXfTh7wAmkpb&utm_campaign=VM_Performance_Max_CatchAll_21Jun%2722&utm_medium=cpc&utm_source=google"
                      className="inline-block rounded-md border border-transparent bg-orange-600 px-8 py-3 text-center font-medium text-white hover:bg-indigo-700"
                    >
                      Shop Collection
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      
  

export default snitch