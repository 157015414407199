const data=[
    {

        
        name:``,
        wear:`Best Seller`,
        title:`JBL TUNE BUDS`,
        saleCategory:`BestSeller`,
        mrpRate:'₹6,999',
        tradeRate:'₹4,499',
        thumbnail:`https://in.jbl.com/dw/image/v2/BFND_PRD/on/demandware.static/-/Sites-masterCatalog_Harman/default/dw57a7735c/01.JBL_Tune%20Buds_Product%20Image_Hero_Blue.png?sw=535&sh=535`,
        brand:`https://in.jbl.com/on/demandware.static/-/Library-Sites-SharedLibrary-JB/default/dw7c092073/images/jbl-logo.svg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fin.jbl.com%2FTrue-Wireless-Earbuds%2FTUNE-BUDS.html%3Fcgid%3DTrue-Wireless-Earbuds`
        

    },
    {

        
        name:``,
        wear:`4/5(140) Reviews`,
        title:`JBL Live Pro 2 TWS`,
        saleCategory:`BestSeller`,
        mrpRate:'₹16,999',
        tradeRate:'₹9,999',
        thumbnail:`https://in.jbl.com/dw/image/v2/BFND_PRD/on/demandware.static/-/Sites-masterCatalog_Harman/default/dw22a77f9e/1.JBL_Live%20Pro%202_Product%20image_Hero_White.png?sw=535&sh=535`,
        brand:`https://in.jbl.com/on/demandware.static/-/Library-Sites-SharedLibrary-JB/default/dw7c092073/images/jbl-logo.svg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fin.jbl.com%2FLive-Series%2FLIVE-PRO-2-TWS-.html%3Fdwvar_LIVE-PRO-2-TWS-_color%3DSilver-GLOBAL-Current%26cgid%3DLive-Series`
        

    },

    {

        
        name:``,
        wear:`3.4/5(1185) rating `,
        title:`JBL Tune Beam`,
        saleCategory:`BestSeller`,
        mrpRate:'₹7,999',
        tradeRate:'₹5,499',
        thumbnail:`https://in.jbl.com/dw/image/v2/BFND_PRD/on/demandware.static/-/Sites-masterCatalog_Harman/default/dwdc6188c6/01.JBL_Tune%20Beam_Product%20Image_Hero_White.png?sw=535&sh=535`,
        brand:`/images/amazon.jpg`,
        blink:`https://www.amazon.in/JBL-Customized-Headphones-Talk-Thru-Bluetooth/dp/B0C3DH79GL?th=1`
        

    },

    {

        
        name:``,
        wear:`4/5(3805) ratings`,
        title:`JBL Live Pro 2 TWS`,
        saleCategory:`BestSeller`,
        mrpRate:'₹16,999',
        tradeRate:'₹9,999',
        thumbnail:`https://in.jbl.com/dw/image/v2/BFND_PRD/on/demandware.static/-/Sites-masterCatalog_Harman/default/dwbab3a4f9/1.JBL_LIVE_PRO_2_Product%20Image_Hero_Black.png?sw=535&sh=535`,
        brand:`/images/amazon.jpg`,
        blink:`https://www.amazon.in/JBL-Adaptive-Cancellation-Playtime-Compatible/dp/B0B1SJ7YSK?th=1&tag=inrdeal123-21`
        

    },
    {

        
        name:``,
        wear:`New Launch`,
        title:`JBL Live 770NC`,
        saleCategory:`BestSeller`,
        mrpRate:'₹14,999',
        tradeRate:'₹9,999',
        thumbnail:`https://in.jbl.com/dw/image/v2/BFND_PRD/on/demandware.static/-/Sites-masterCatalog_Harman/default/dw16026c06/01.JBL_Live_770NC_Product%20Image_Hero_Black.png?sw=1605&sh=1605`,
        brand:`/images/jbl.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fin.jbl.com%2FLive-Series%2FLIVE770NC.html%3Fdwvar_LIVE770NC_color%3DBlack-GLOBAL-Current%26cgid%3DLive-Series`
        

    },

    {

        
        name:``,
        wear:`New Launch`,
        title:`JBL Tune 215TWS`,
        saleCategory:`BestSeller`,
        mrpRate:'₹8,999',
        tradeRate:'₹5,999',
        thumbnail:`https://in.jbl.com/dw/image/v2/BFND_PRD/on/demandware.static/-/Sites-masterCatalog_Harman/default/dw972f9ea1/JBL_TUNE%20215TWS_Product%20Image_Lavender_Hero.png?sw=535&sh=535`,
        brand:`/images/jbl.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fin.jbl.com%2FTrue-Wireless-Earbuds%2FTUNE215TWS-.html%3Fdwvar_TUNE215TWS-_color%3DPurple-GLOBAL-Current%26cgid%3DTrue-Wireless-Earbuds`
        

    },
    

]

export default data;