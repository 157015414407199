const bannerContent = [
    {
        img:`https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/category/catban-020240730115139.jpg?format=webp&w=1500&dpr=1.5`,
        link:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.thesouledstore.com%2Fproduct%2Ftss-originals-musafir-mini-bagpack%3Fgte%3D1`,


    },
    {
        img:'https://www.boat-lifestyle.com/cdn/shop/files/d2c_banner_copy_1440x.jpg?v=1721715421',
        link:'https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.boat-lifestyle.com%2Fproducts%2Fairdopes-alpha-deadpool-edition',


    },
    {
        img:`https://prod-img.thesouledstore.com/public/theSoul/uploads/themes/7726020240801115151.jpg?format=webp&w=1500&dpr=1.5`,
        link:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.thesouledstore.com%2Fproduct%2Fmen-low-top-sneaker-harry-potter-slytherin%3Fgte%3D1`,
    },
  

    {

        img:`/images/5-13june/watchbanner4.jpg`,
        link:`https://amzn.urlvia.com/O8CGoT`,

    },

        {
            img:'https://m.media-amazon.com/images/G/31/img2020/fashion/WA_2020/JUNEWRSWA/Premium-Western-Wear_48.png',
            link:'https://www.amazon.in/b?node=29889091031&ref=ac_in_carousel-SLOT5&discounts-widget=%22%7B%5C%22state%5C%22%3A%7B%5C%22refinementFilters%5C%22%3A%7B%7D%7D%2C%5C%22version%5C%22%3A1%7D%22&tag=inrdeal123-21',

        },

        
        {
            img:'https://www.libas.in/cdn/shop/files/Mask_group_3_51290918-9122-4e92-9cb4-7ded49e31761.png?v=1716282670',
            link:'https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.libas.in%2Fcollections%2Flibas-x-kiara-advani',
    
    
        },

        {

            img:`/images/5-13june/watchbanner3.jpg`,
            link:`https://amzn.urlvia.com/4mvj0G`,
    
        },
        {

            img:`https://www.boultaudio.com/cdn/shop/files/collection_mob_1600x.png?v=1719155851`,
            link:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.boultaudio.com%2Fcollections%2Fboult-x-mustang`,
    
        },
        {
            img:`https://images.dailyobjects.com/marche/assets/images/other/hsp-revert-desktop-carousal-rolltop-bag.jpg?tr=cm-pad_crop,v-2,dpr-1`,
            link:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dailyobjects.com%2Fdp%3Ff%3Dpid~KELP-MILS-ROLL-TOP-BACKPCK`,
        },
        
        {
            img:'https://cdn.anscommerce.com/catalog/brandstore/Bira91/623-1716402600-1920x667website.jpg',
            link:'https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fshop.bira91.com%2Fmakeplay-collection',
        },
        {
            img:`https://images-eu.ssl-images-amazon.com/images/G/31/img24/Media/STL/toys/june/Header_PC_Revised.gif`,
            link:'https://www.amazon.in/Toys-Games/b/?ie=UTF8&node=1350380031&ref_=nav_cs_toys&tag=inrdeal123-21',
        },
        {
            img:`https://images-eu.ssl-images-amazon.com/images/G/31/img24hp/jbl/jbl/SMARTCASE_-_GIF.gif`,
            link:'https://www.amazon.in/l/99976637031/ref=QAHzEditorial_en_IN_5?pf_rd_r=AXHYJJ7W619BMGPQDYQE&pf_rd_p=50821721-b232-4f1b-bb0c-0f847c26144e&pf_rd_m=A1VBAL9TL5WCBF&pf_rd_s=merchandised-search-7&pf_rd_t=&pf_rd_i=1388921031&tag=inrdeal123-21',
        },
        
    


]

export default bannerContent;