import React from 'react';
import Navbar from '../components/NavBar';
import Footer from '../components/Footer';
import Biracarousel from './layout/biraCarousel';
import Productcard from './layout/productCard';
import Trendingproduct from './layout/trendingProducts';
import { Helmet } from "react-helmet";


const bira = () => {
  return (
    <div>
      <Navbar />

      <Helmet>
        <title>Shop Craft Beers & Merchandise | Premium Beer, Apparel & Accessories | Bira91</title>
        <meta name="description" content="Explore Bira91 (shop.bira91.com) for a wide range of craft beers and merchandise. Discover unique flavors, exclusive collections, and stylish accessories. Shop now for premium beer, clothing, glassware, and more. Elevate your beer experience with Bira91’s exceptional quality and innovative offerings. Cheers to good times with Bira91!" />
        <meta name="keywords" keywords="craft beer, Bira91, premium beer, beer merchandise, beer accessories, Bira91 apparel, buy craft beer, unique beer flavors, beer glassware, beer gifts, online beer shop, beer collections, beer clothing, Bira91 store, beer products, beer shopping, Bira91 accessories" />
      </Helmet>

      <div>
        <Biracarousel />
        </div>

        <div className=' w-full  justify-center items-center lg:px-32 px-5'>
        <h1 className='text-4xl font-semibold text-center pt-12 pb-10'>New Launches</h1>

        </div>


        <div className='flex gap-8 flex-wrap justify-center w-full pb-8 '>

        <Productcard  />
      </div>
      <div>

      </div >
      <div className='py-7 flex flex-col lg:flex-row justify-center items-center lg:px-32 px-5'>

      <img className='border rounded-xl w-2/3 md:w-1/4' src='https://lh3.googleusercontent.com/CaL5Sg1sg46wBT1q7AIbglkvOe4lqCl09yNvhAuzD9NS5T8zakyfEHq9XrYTwgoU_TPquSLDRyVUExBQItrsl05hxj-MSg=s750'  alt=''/>
      <div className='space-y-4 lg:pt-14'>
        <h1 className='font-semiBold md:text-4xl md:py-5 md:pl-5 md:pb-5 text-2xl text-center md:text-start '>TrendZ About Bira91 </h1>
       
        <p className='mx-7'>
        READY TO TURN HEADS AND RAISE GLASSES?
CHECK OUT THE COOLEST MERCH IN TOWN
Whether you're chilling at home, hitting the streets, or gearing up for a beer-filled adventure, our merch is your go-to style companion.
        </p>
        
        </div>
        <div className='mt-4 border justify-center items-center py-3 px-16 bg-black rounded-full '>
        <a href='www.google.com' className='text-white hover:text-orange-500 text-lg inline-text'>Checkout Now
        </a>
        </div>

      
      </div>
      <div className=' w-full  justify-center items-center lg:px-32 px-5'>
        <h1 className='text-4xl font-semibold text-center pt-24 pb-10'>Trending & Best seller </h1>


        <div className='flex gap-8 flex-wrap justify-center  pb-8 lg:mx-16 '>

          <Trendingproduct />

        </div>


      </div>

      
        
      <Footer />
    </div>



  )
}

export default bira ;
