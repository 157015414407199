import React from 'react'
import { Link } from 'react-router-dom'
import { IoMdSearch } from 'react-icons/io';
import { FaCaretDown } from 'react-icons/fa';


const navbar = () => {
  return (
    <div className='bg-white dark:bg-gray-900 dark:text-white'>
        <div className='py-4'>
            <div className='container flex justify-between items-center'>
                {/* logo and link section */}
                <div className='flex items-center gap-4'>
                    <div className='flex flex-row gap-4'>

                  <h1> Trend'Z </h1>
                  <a href='www.trendz.blog/Electronic' className='text-red-700 text-2xl upprcase sm:font-semibold tracking-widest sm:text-3xl' >Electronics</a>
                  

                  </div>
                    
                    {/* menu items  */}
                    <div className='hidden lg:block'>
                      <ul className='flex flex-center gap-4'>
                        <li>
                          <Link  to="/Electronic" className='inline-block px-4 text-gray-500 font-semibold hover:text-black'>Home</Link>
                        </li>
                        <li>
                          <Link to="/Electronic" className='inline-block px-4 text-gray-500 font-semibold hover:text-black' >Brands</Link>
                        </li>
                        <li>
                          <Link to="/Electronic" className='inline-block px-4 text-gray-500 font-semibold hover:text-black' >Blogs</Link>
                        </li>
                        {/* quick links */}
                        <li className='relative cursor-pointer group'>
                          <h1  className='flex items-center gap-[2px] font-semibold text-gray-500 py-2'>Quick links
                          <span>
                            <FaCaretDown className='group-hover:rotate-180 duration-300 ' />
                            </span>
                            </h1>
                            {/* dropdown Links */}
                            <div className='absolute z-[9999] hidden group-hover:block w-[200px] rounded-md bg-white shadow-md p-2'>
                              <ul className='space-y-2'>
                                <li>
                                  <Link to='/trend' className='text-gray-500 duration-200 hover:text-black hover:bg-orange-200 inline-block p-2 w-full font-semibold rounded-lg' >Most Trends</Link>
                                </li>
                                <li>
                                  <Link to='/bestseller' className='text-gray-500 hover:text-black hover:bg-orange-200 inline-block p-2 w-full font-semibold rounded-lg' >Best Seller</Link>
                                </li>
                                <li>
                                  <Link to='/trend' className='text-gray-500 hover:text-black hover:bg-orange-200 inline-block p-2 w-full font-semibold rounded-lg' >Top Rated</Link>
                                </li>
                              
                              </ul>
                            </div>

                        </li>


                      </ul>
                    </div>
                </div>
                {/* navbar right section */}
                <div className='flex justify-between items-center gap-4'>
                
               

                <div className='relative group hidden sm:block'>
                  {/* search bar section */}

                  <input type="text" placeholder="Search" className='search-bar' />  
                  <IoMdSearch
                  className='text-xl text-gray-600 group-hover:text-petty absolute top-1/2 -translate-y-1/2 right-3'
                  />




                  {/* search bar section */}
                </div>
                </div>

            </div>
        </div>
      
    </div>
  )
}

export default navbar
