import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import ProductListCarousel from '../layout/ProductListCarousel';
import ShoesHim from '../content/ShoesHim';
import ShoeHer from '../content/ShoeHer';
import KidsShoes from '../content/KidsShoe';





function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const  Tabs4 =() => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%',  }}>
      <Box sx={{ borderBottom: 0 , }} >
        <div className='lg:mx-[25rem] mx-14 font-semibold mt-10'>
        <Tabs value={value}  onChange={handleChange} aria-label="basic tabs example"   variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile>
          <Tab label='Him'   {...a11yProps(0)} />
          <Tab label='Her'  {...a11yProps(1)} />
          <Tab label='Kids'{...a11yProps(2)} />
          
        </Tabs>
        </div>
      </Box>
      <CustomTabPanel  value={value} index={0}>

    
        
        <ProductListCarousel data={ShoesHim} />  

        
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
     
        

      <ProductListCarousel data={ShoeHer} /> 

      
       
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>

      
        

      <ProductListCarousel data={KidsShoes} /> 

      
      
      </CustomTabPanel>


    </Box>
  );
}

export default Tabs4;