import React from 'react'


const aldo = ({data}) => {
  return (
    <div>  <div className='flex min-h-screen items-center justify-center my-16 px-5'>
       
    <div  className='grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5 '>
    {data.map((article,index) => (
      <a key={index} href={article.blink}>
      <div   className='group relative item-center justify-center overflow-hidden cursor-pointer hover:shadow-xl hover:shadow-black/30 transition-shadow'  >
      
         <div  className='h-60 w-84 md:h-96 md:w-72'><img src={article.thumbnail}
          alt='' className='h-full w-full object-cover group-hover:rotate-1 group-hover:scale-125 transition-transform duration-500'/>
         </div>
         
      
         <div className='absolute inset-0 bg-gradient-to-b from-transparent via-transparent  group-hover:via-black/60 to group-hover:to-black/70'>
         </div>
         <div className='absolute inset-0 flex flex-col items-center justify-center px-9 text-center translate-y-[60%] group-hover:translate-y-0 transition-all' >
           <h1 className='text-3xl font-dmserif font-bold text-white '>{article.name}</h1>
           <p className='text-lg italic text-white mb-3 opacity-0 group-hover:opacity-100 transition-opacity'>{article.title}</p>
           <a href={article.blink} className='rounded-full shadow shadow-black/60 py-2 bg-neutral-900 px-3.5 text-sm capitalize text-white'>{article.tradeRate}</a>
         
         </div>
         
       </div>
       </a>
       ) )}
     
     </div>
   </div>
</div>
  )
}

export default aldo