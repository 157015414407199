const data = [

    
    {

        name:``,
        wear: "Skincare",
        title:"Strawberry Dew Sunscreen Stick SPF 50",
        saleCategory:'Trending',
        mrpRate:'Rs.595',
        tradeRate:'Rs.506',
        thumbnail:'https://www.dotandkey.com/cdn/shop/files/image_10_68cfb580-855a-4624-9f0e-832b7232535a_1800x1800.png?v=1715238587',
        brand:'https://smytten-image.gumlet.io/shop_store/1614104530_Brand-icon.jpg',
        blink:'https://www.amazon.in/DOT-KEY-Strawberry-Protection-Hyaluronic/dp/B0CDX9LJM2?tag=inrdeal123-21'
        
    },
    {

        name:``,
        wear: "Skin Care",
        title:"Watermelon Cooling SPF 50 Sunscreen",
        saleCategory:'Girls choice',
        mrpRate:'Rs.595',
        tradeRate:'Rs.506',
        thumbnail:'https://www.dotandkey.com/cdn/shop/files/watersunscreen80gm_1800x1800.jpg?v=1714459973',
        brand:'https://smytten-image.gumlet.io/shop_store/1614104530_Brand-icon.jpg',
        blink:'https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fproducts%2Fwatermelon-cooling-spf-50-face-sunscreen'
        
    },
    
    {

        name:``,
        wear: `Skin Care`,
        title:`Vitamin C + E Sunscreen, SPF 50+ PA++++`,
        saleCategory:`best seller`,
        mrpRate:`Rs.595`,
        tradeRate:`Rs.506`,
        thumbnail:`https://www.dotandkey.com/cdn/shop/files/vitcsun80_1800x1800.jpg?v=1714460138`,
        brand:`https://smytten-image.gumlet.io/shop_store/1614104530_Brand-icon.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fproducts%2Fdot-key-vitamin-c-e-spf-50-pa-face-sunscreen-for-glowing-skin-uv-protection-for-dull-skin`
        
    },


{

        name:``,
        wear: `Skin Care`,
        title:`STRAWBERRY DEW TINTED SUNSCREEN SPF 50+ PA++++`,
        saleCategory:`best seller`,
        mrpRate:`Rs: 549.00`,
        tradeRate:`Rs.506`,
        thumbnail:`https://www.dotandkey.com/cdn/shop/files/image_4_1a5f72f2-ba3a-4cf0-901d-cb3152eb274b_1800x1800.png?v=171454519`,
        brand:`https://smytten-image.gumlet.io/shop_store/1614104530_Brand-icon.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fproducts%2Fstrawberry-dew-tinted-sunscreen-spf-50-pa`
        
    },



{

        name:``,
        wear: `Skin Care`,
        title:`Barrier Repair Sunscreen, SPF 50+`,
        saleCategory:`best seller`,
        mrpRate:`Rs: 595.00`,
        tradeRate:`Rs.506`,
        thumbnail:`https://www.dotandkey.com/cdn/shop/files/brsun80_512ade3d-84ad-48db-a2ed-b11a102fbe39_1800x1800.jpg?v=171446030`,
        brand:`https://smytten-image.gumlet.io/shop_store/1614104530_Brand-icon.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fproducts%2Fbarrier-repair-sunscreen`
        
    },



{

        name:``,
        wear: `Skin Care`,
        title:`Strawberry Dew Strobe Cream`,
        saleCategory:`best seller`,
        mrpRate:`Rs: 345.00`,
        tradeRate:`Rs.294`,
        thumbnail:`https://www.dotandkey.com/cdn/shop/files/Artboard2_738f4527-e69f-4f7c-95b2-178d6d171ca3_1800x1800.jpg?v=1706167265`,
        brand:`https://smytten-image.gumlet.io/shop_store/1614104530_Brand-icon.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fproducts%2Fstrawberry-dew-strobe-cream`
        
    },



{

        name:``,
        wear: `Skin Care`,
        title:`Watermelon Cooling Underarm Roll On`,
        saleCategory:`best seller`,
        mrpRate:`Rs: 299.00`,
        tradeRate:`Rs.255`,
        thumbnail:`https://www.dotandkey.com/cdn/shop/files/Artboard1_1800x1800.jpg?v=1714460533`,
        brand:`https://smytten-image.gumlet.io/shop_store/1614104530_Brand-icon.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fproducts%2Fwatermelon-cooling-underarm-roll-on`
        
    },


{

        name:``,
        wear: `Skin Care`,
        title:`Swim + Sports SPF 50 Sunscreen`,
        saleCategory:`best seller`,
        mrpRate:`Rs: 495.00`,
        tradeRate:`Rs.421`,
        thumbnail:`https://www.dotandkey.com/cdn/shop/files/swimsuncopy_1800x1800.jpg?v=1708151908`,
        brand:`https://smytten-image.gumlet.io/shop_store/1614104530_Brand-icon.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fproducts%2Flime-rush-swim-sports-spf-50-sunscreen`
        
    },



{

        name:``,
        wear: `Skin Care`,
        title:`Strawberry Dew Daily Cleansing Balm`,
        saleCategory:`best seller`,
        mrpRate:`Rs: 499.00`,
        tradeRate:`Rs.423`,
        thumbnail:`https://www.dotandkey.com/cdn/shop/files/strawcleaningbalm_1800x1800.jpg?v=1706167234`,
        brand:`https://smytten-image.gumlet.io/shop_store/1614104530_Brand-icon.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fproducts%2Fstrawberry-dew-daily-cleansing-balm`
        
    },



{

        name:``,
        wear: `Skin Care`,
        title:`Lime Rush Sports Facewash`,
        saleCategory:`best seller`,
        mrpRate:`Rs: 249.00`,
        tradeRate:`Rs.212`,
        thumbnail:`https://www.dotandkey.com/cdn/shop/files/lime_1800x1800.jpg?v=1710331662`,
        brand:`https://smytten-image.gumlet.io/shop_store/1614104530_Brand-icon.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fproducts%2Flime-rush-sports-facewash`
        
    },





{

        name:``,
        wear: `Skin Care`,
        title:`Vitamin C + E Sunscreen, SPF 50+ PA++++`,
        saleCategory:`best seller`,
        mrpRate:`Rs: 595.00`,
        tradeRate:`Rs.506`,
        thumbnail:`https://www.dotandkey.com/cdn/shop/files/vitcsun80_1800x1800.jpg?v=1714460138`,
        brand:`https://smytten-image.gumlet.io/shop_store/1614104530_Brand-icon.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fproducts%2Fdot-key-vitamin-c-e-spf-50-pa-face-sunscreen-for-glowing-skin-uv-protection-for-dull-skin`
        
    },



{

        name:``,
        wear: `Skin Care`,
        title:`Vitamin C + E Moisturizer`,
        saleCategory:`best seller`,
        mrpRate:`Rs: 495.00`,
        tradeRate:`Rs.421`,
        thumbnail:`https://www.dotandkey.com/cdn/shop/files/vitcm_1800x1800.jpg?v=1711452937`,
        brand:`https://smytten-image.gumlet.io/shop_store/1614104530_Brand-icon.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fproducts%2Fvitamin-c-e-super-bright-moisturizer`
        
    },



{

        name:``,
        wear: `Skin Care`,
        title:`10% Vitamin C+E & 5% Niacinamide Serum`,
        saleCategory:`best seller`,
        mrpRate:`Rs: 695.00`,
        tradeRate:`Rs.591`,
        thumbnail:`https://www.dotandkey.com/cdn/shop/files/vitcserum_1800x1800.jpg?v=1706166348`,
        brand:`https://smytten-image.gumlet.io/shop_store/1614104530_Brand-icon.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fproducts%2Fdot-key-10-vitamin-c-e-5-niacinamide-serum-for-glowing-skin-beginner-friendly`
        
    },




{

        name:``,
        wear: `Skin Care`,
        title:`Vitamin C + E Gel Facewash`,
        saleCategory:`best seller`,
        mrpRate:`Rs: 249.00`,
        tradeRate:`Rs.212`,
        thumbnail:`https://www.dotandkey.com/cdn/shop/files/Artboard1_2_287fb550-eba2-4005-a6fd-894b60ea3f43_1800x1800.jpg?v=1710401337`,
        brand:`https://smytten-image.gumlet.io/shop_store/1614104530_Brand-icon.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fproducts%2Fvitamin-c-e-gel-facewash`
        
    },



{

        name:``,
        wear: `Skin Care`,
        title:`Vitamin C + E Eye Cream with SPF 30`,
        saleCategory:`best seller`,
        mrpRate:`Rs: 495.00`,
        tradeRate:`Rs.421`,
        thumbnail:`https://www.dotandkey.com/cdn/shop/files/Artboard8_1800x1800.jpg?v=1706167495`,
        brand:`https://smytten-image.gumlet.io/shop_store/1614104530_Brand-icon.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fproducts%2Fvitamin-c-e-eye-cream-with-spf-30`
        
    },



{

        name:``,
        wear: `Skin Care`,
        title:`Vitamin C Daily Glow Booster Combo`,
        saleCategory:`best seller`,
        mrpRate:`Rs: 1,930.00`,
        tradeRate:`Rs.1641`,
        thumbnail:`https://www.dotandkey.com/cdn/shop/files/1_e3bbea83-7681-4373-9e3f-7eb244b15932_1800x1800.jpg?v=1710313556`,
        brand:`https://smytten-image.gumlet.io/shop_store/1614104530_Brand-icon.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.dotandkey.com%2Fproducts%2Fvitamin-c-daily-glow-booster-combo`
        
    },

















]

export default data