import React from 'react'
import Navbar from '../components/NavBar';
import Footer from '../components/Footer';
import Banner from './layout/banner';
import AirpodBannerContent from './content/airpodBannerContent'
import Aproductcarousel from './layout/aProductCarousel';
import Tabs3 from './layout/tabs3';


import Tabs1 from './layout/tabs2';

import { Helmet } from "react-helmet";

const airpods = () => {
  return (
    <div>

        <Navbar />

        <Helmet>
        <title>Shop Premium Earbuds | TrendZ - High-Quality Wireless Earbuds Collection</title>
        <meta name="description" content="Discover the latest earbuds at TrendZ (https://trendz.blog/Airpods). Shop top-quality wireless earphones with exceptional sound, sleek design, and comfortable fit. Perfect for music lovers and on-the-go lifestyles. Experience superior audio performance with TrendZ earbuds today earbuds, AirPods, wireless earbuds, TrendZ earbuds, Bluetooth earphones, high-quality earbuds, noise-canceling earbuds, affordable earbuds, trendy earbuds, best earbuds, TrendZ AirPods, premium sound, wireless earphones, buy earbuds online, TrendZ audio accessories" />
        <meta name="keywords" keywords="earbuds, AirPods, wireless earbuds, TrendZ earbuds, Bluetooth earphones, high-quality earbuds, noise-canceling earbuds, affordable earbuds, trendy earbuds, best earbuds, TrendZ AirPods, premium sound, wireless earphones, buy earbuds online, TrendZ audio accessories" />
      </Helmet>


        <div className='mt-9'> 

            <Banner data={AirpodBannerContent} />


        </div>
        <div className='flex flex-col lg:px-32 px-5 my-5 bg-black py-5 rounded-3xl lg:mx-10 mx-5'>
          <h1 className='text-3xl font-bold text-center text-white pb-8'>Best seller </h1>

          <Aproductcarousel />
        </div>



       <div className=' border rounded-3xl bg-gray-200 lg:mx-32  my-10'>
        <Tabs1 />
        </div>
          
        


        
          <div className='border bg-orange-700 rounded-xl  '>
            <h1 className='text-white text-center text-xl lg:text-3xl font-bold m-8'>Wireless, Immersive, Premium Sound!</h1>
          </div>
          
          <div className=''>
            <Tabs3 className='bg-lime-200' />

            </div>
            
          
      











        <Footer />


    </div>
  )
}

export default airpods