import React from 'react'
import Navbar from '../components/NavBar';
import Footer from '../components/Footer';
import PerfumeCarousel from './layout/perfumeCarousel';
import PerfumeCarouselContent from './content/perfumeCarouselContent'
import Perfumebanner from './layout/perfumeBanner';
import PerfumeBannerContent from './content/perfumeBannerContent'
import Perfumelist from './layout/productList1';
import perfumeContent from '../pages/content/perfumeContent';
import { Helmet } from "react-helmet";

const perfume = () => {
  return (
    <>
    <Navbar />
    <div className='my-5'>
      

      <Helmet>
<title>Explore Premium Perfumes | TrendZ - Luxurious Fragrances Collection</title>
<meta name="description" content="Discover premium perfumes at TrendZ (trendz.blog/Perfume). Explore our exquisite collection of fragrances for men and women, crafted to elevate your senses. Shop now for unique scents, luxurious bottles, and long-lasting aromas. Find your signature fragrance with TrendZ and leave a lasting impression." />
<meta name="keywords" keywords="luxury perfume, TrendZ perfume, premium fragrances, best perfumes, buy perfume online, designer scents, perfume collection, fragrance shop, TrendZ fragrances, top perfumes, perfume store, signature scents, men's perfume, women's perfume, TrendZ blog, perfume deals, scented products, long-lasting fragrances, exclusive perfumes, trendy scents" />
</Helmet>


      <div className=' mx-auto mt-14 '>

        <PerfumeCarousel data={PerfumeCarouselContent} />


      </div>
      <div>


        <div className=''>

          <Perfumebanner data={PerfumeBannerContent} />


        </div>
      </div>

      <div className="container py-4  mx-auto">
          <div className='grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-5 px-8 lg:mx-24'>

          <Perfumelist data={ perfumeContent }  />



        </div>


      </div>


          <Footer />

    </div>

    </>
  )
}

export default perfume