import React from "react";
import homeContent from "./homeContent"

import icon from "./icon";
import NavbarF from "../components/NavBar"
import Banner from "./layout/banner";
import Bannercontent from './content/bannerContent';
import Footer from  "../components/Footer";

import '@mantine/carousel/styles.css';
import Brandcarousel from "./brandCarousel";
import CardCarousel from "./CardCrousel";
import { Helmet } from "react-helmet";


import ProductListCarousel from "./layout/ProductListCarousel";
import AccessoriesContent from './content/accessoriesContent'
import Dailydeal from "./layout/DailyDeal";
import DailydealContent from './content/DailyDealContent'
import NewLaunch from './layout/DailyDeal';
import NewLaunchContent from './content/newLaunch';
import MobileCase from "./layout/MobileCase";
import Snitch from "./Blog/snitch";


import BasicTabs from './layout/taabs';



// import { Link } from 'react-router-dom'
// import { FaShoppingBag } from 'react-icons/fa'









const Home = () =>{
    return (
        <>
        <NavbarF />
       

        <Helmet>
        <title>TrendZ - Shop Watches, Airpods, Perfumes, Sunglasses, Beauty Products & Footwear | Top Brands: Puma, G-Shock, Aldo, Prada, Armani Exchange, Daily Objects, Nike, Foxtale, Micas, Amazon, Flipkart, Mynta, Dot&Key, Bira91, Crossbeats, boult, boat, Realme, Oneplus, Snitch</title>
        <meta name="description" content="Discover Trend, Shop for Fashion Accessories, your ultimate destination for top brands like Puma, G-Shock, Aldo, Prada, and more. Shop for best retailers, Shop the latest in watches, Airpods, perfume, sunglasses, beauty products, and footwear from leading brands including Armani Exchange, Daily Object, Bira91, Micas, Dot & Key, Park Avenue, Foxtale, Amazon, and Flipkart. Elevate your style with Trendz today!" />

        <meta name="keywords" keywords="Trend, Trend fashion, Trend watches, Trend airpods, TrendZ perfume, TrendZ sunglasses, TrendZ beauty products, TrendZ footwear, Puma, G-shock, Aldo, Prada, Armani Exchange, Daily object, Bira91, Micas, dot&key, Park Avenue, Foxtale, Amazon, Flipkart, trendy fashion, online shopping, stylish accessories, luxury brands, high-quality products" />
      </Helmet>

      <div className=" mx-auto my-16">

        <Banner data ={Bannercontent} /> 

        </div> 


        
                <div className="max-w-screen-md mx-auto">
        
        
            
            

            

        <div className="w-84">
            <h5 data-aos="fade-right"
            data-aos-duration="1500"
            className="text-headingColor font-[600] dark:text-neutral-300 text-[16px] ml-7">
                Hello! Welcome

            </h5>
            <h1 data-aos="fade-up"
            data-aos-duration="1500"
            className="ml-6 text-headingColor fond-[800] text-[1.8rem] sm:text-[40px] leading-[35px]
            sm:leding-[46px] dark:text-neutral-300 mt-3">
                TrendZ
            </h1>
            <div>
            {homeContent.map((home, index)=>(
            <div key={index} data-aos="fade-up"
            data-aos-duration="1800"
            data-aos-delay="200"
            className="flex text-base items-center gap-6 ml-6 mt-5 md:mt-0">
              <p className="font-jakarta lg:max-auto text-headingColor dark:text-neutral-300 font-[500]">
                    {home.paragraph} 
              </p>
             
              <img
              data-aos=""
              data-aos-duration="1500" className="w-24 h-24 md:w-60 md:h-60 rounded-full border-2" src={home.thumbnail} alt="logo"/>
            </div>
            )
            )}
        
            </div>
            {icon.map((icon, index) =>(
            <div className="flex">
               

               
                <span className="flex gap-4 m-6 md:m-0" key={index}>
                  <a data-aos="fade-right"
                  data-aos-duration="1900"
                   href="https://in.pinterest.com/trend_AZ" ><img src={icon.icon} alt="icon"/></a>
                   <a data-aos="fade-left"
                  data-aos-duration="1900"
                    href="https://instagram.com/treind_z?igshid=NzZlODBkYWE4Ng%3D%3D&utm_source=qr"><img src={icon.icon1} alt="icon2" /> </a>
                </span>
                <div className="flex flex-wrap  ">
                

                    
                </div>
                
                     
            </div>
            
            )

        
            )}
            <div className="flex flex-wrap -m-4">
                        

        </div> 
        </div>
        </div>
        
     

        <div className="flex m-4 lg:mt-32 lg:mx-24 py-10 px-10 border bg-black rounded-xl ">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3  ">

            <div className="flex flex-col text-center">
                <h1 className="text-white font-bold text-2xl my-3">Daily Deal</h1>

                
  
            <Dailydeal data ={DailydealContent} />

            
           
            </div>

            <div className="flex flex-col text-center ">
                <h1 className="text-white font-bold text-2xl my-3">New Launches</h1>
                <NewLaunch data ={NewLaunchContent} />

            </div>
            </div>
        </div>

        



        <CardCarousel />

     {/*   <div>
<div className=' bg-primaryBG py-12 xl:px-28 px-10'>
        <div className='md:flex xl:ml-[15rem] items-center justify-center lg:w-full '>
            <div className='md:w-1/2 ml-16'>
                <h1 className='text-5xl font-light mb-5'>Collections</h1>
                <p className='text-xl mb-7'>You can explore many new collections trending product as well as best seller from various brands are here</p>
                <Link to='/collection' className=' item-center gap-2 bg-black hover:bg-orange-600 px-6 py-2 text-white font-semibold rounded-sm'>
                    <FaShoppingBag className='inline-flex mr-3' />Shop Now</Link>

            </div>
            <div className='md:w-1/2 ml-10 my-10'>
                <img src='https://img.freepik.com/free-vector/fashion-woman-instagram-puzzle-collection_23-2148706640.jpg?size=338&ext=jpg&ga=GA1.1.1700460183.1712707200&semt=ais' alt='' />
            </div>
        </div>

    </div>
        </div>
        */}

        <div className="border bg-black lg:p-14 lg:mx-12 py-10 rounded-xl">
            <h1 className="text-black lg:text-3xl text-xl text-center font-semibold  border bg-white xl:mx-[25rem] py-5 my-5 rounded-full mx-14 md:mx-[20rem]">Accessories</h1>
            <div>
                <ProductListCarousel data = {AccessoriesContent} />
            </div>
        </div>

        <div>
            <Snitch />
             </div>



            <div>
            <BasicTabs  />
            </div>


 


        <div className="max-w-screen-lg mx-auto mt-10">
          <div className='lg:mx-96 mx-24 '>
      
          <Brandcarousel />

        </div>
        </div>

        <div className="text-center place-self-center my-10 ">
            <h1 className="text-xl lg:text-3xl font-bold border bg-black text-white p-5 mx-24 mb-10 lg:mx-[15rem] rounded-3xl my-5"> Cool Mobile Case </h1>
            <div className=""> 
                <MobileCase />
            </div>
            </div>

           

       
        

        <Footer />
        

        </>
        
    );
};

export default Home