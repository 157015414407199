const data = [

    {
        name:'watches',
        to:'/watch',
        img:'https://image-cdn.hypb.st/https%3A%2F%2Fhypebeast.com%2Fimage%2F2017%2F08%2Fmichael-lau-casio-g-shock-mr-g-watch-1.jpg?cbr=1&q=90',
        sentence:'',
        link:`Precision crafted`

    },
    {
        name:'Perfumes',
        to:'/perfume',
        img:'https://i.pinimg.com/originals/c4/32/e4/c432e4c2021f5302918107dc5a1f6b9c.jpg',
        sentence:'',
        link:`Smell Good`,

    },
    {
        name:'Airpodes',
        to:'/Airpods',
        img:'https://img.etimg.com/photo/94471901/94471901.jpg?v=3',
        sentence:'',
        link:`Listen Wireless`,

    },
    {
        name:'Glasses',
        to:'/Glasses',
        img:'https://shopmicas.com/cdn/shop/files/6d25d396-4ec2-4657-8667-9165b70ce830_1800x1800.jpg?v=1709518156',
        sentence:'',
        link:`See Better`,

    },

    {
        name:'Beauty',
        to:'/beauty',
        img:'https://www.shutterstock.com/image-photo/amber-glass-dropper-bottle-flowers-260nw-2136224347.jpg',
        sentence:'',
        link:`Refresh always`,

    },
    {
        name:'Footwears',
        to:'/footwear',
        img:'https://img4.dhresource.com/webp/m/0x0/f3/albu/km/s/27/fdad0f59-78ec-4b81-ba3c-f32b4d8ec133.jpg',
        sentence:'',
        link:`Run Good`,

    },
    

]

export default data;