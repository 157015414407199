import React from "react";
import { Link } from 'react-router-dom';



const grocery = ({groceries}) =>{
    return(
        <>
              {groceries.map((blog, index)=>(
                        <div key={index} className="p-4 w-1/2 xl:w-1/4 2xl:w-1/4 lg:w-1/4 md:w-1/3">
                            <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                                 <Link to={`/grocery/${blog.name}`}>
                                    <img className="lg:h-48 md:h-36 w-full object-cover object-center" src={blog.thumbnail}
                                    alt="blog"/>
                                 </Link>
                                 <div className="p-6">
                                    <Link key={index} to={`/grocery/${blog.name}`}>
                                        <h3 className="text-sm md:text-lg font-medium text-black mb-3">{blog.title.substring(0, 20)}...</h3>
                                    </Link>
                                    <p className="leading-relaxed mb-3 text-xs md:text-lg">
                                        {blog.content[0].substring(0, 45)}...
                                    </p>
                                    <div className="flex item-center flex-wrap">
                                        <Link className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0 md:text-lg text-xs" to={`/grocery/${blog.name}`}>
                                            Read More
                                        </Link>
    
                                    </div>
                                    </div>
                            </div>
                            
                        </div>))}
    
    
        </>
    
    );
              };

              export default grocery 
    