const watchContent = [

    
 
    {

        name:``,
        wear:`Men's Watch`,
        title:`Briggs Analog Watch - For Men CH2927I#JustHere`,
        saleCategory:`Limited Deal`,
        mrpRate:'₹3,512',
        tradeRate:'₹9,995',
        thumbnail:'https://rukminim2.flixcart.com/image/850/1000/xif0q/watch/z/p/c/-original-imagpzjfgmbpmscn.jpeg?q=20&crop=false',
        brand:`/images/flipkart.png`,
        blink:`https://fktr.in/dHK8fqt`
        
    },
    {

        name:``,
        wear:`Men's Watch`,
        title:`Timex Fashion Men's Black Dial Round Case Multifunction Function Watch -TWEG18508`,
        saleCategory:`Good Look`,
        mrpRate:'₹4,395',
        tradeRate:'₹3,516',
        thumbnail:'https://shop.timexindia.com/cdn/shop/products/tweg18508_670x.jpg?v=1690220117',
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3A-GGj7VmxtY1_38oL7KHE67suXeRinRB4A&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fshop.timexindia.com%2Fcollections%2Foffers%2Fproducts%2Ftweg18410`
        
    },



    {

        name:``,
        wear:`Men's Watch`,
        title:`Timex Men Black Analog Round Stainless Steel Dial Watch - TW2V57500X6`,
        saleCategory:`Best Selling`,
        mrpRate:'₹15,995',
        tradeRate:'₹12.796',
        thumbnail:`https://shop.timexindia.com/cdn/shop/files/TW2V57500_0dfb30e6-62c8-4688-99f6-22ca03de48e8_670x.jpg?v=1695634157`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3A-GGj7VmxtY1_38oL7KHE67suXeRinRB4A&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fshop.timexindia.com%2Fproducts%2Ftimex-men-black-analogue-round-stainless-steel-dial-watch-tw2v57500x6`
        
    },
    {

        name:``,
        wear:`Men's Watch`,
        title:`Timex E-Class Premium-Sport Collection Multifunction Men Analog Green Dial Coloured Quartz Watch, Round Dial With 43 Mm Case Width - TWEG22201`,
        saleCategory:`Premium Look`,
        mrpRate:'₹8,295',
        tradeRate:'₹5,807',
        thumbnail:`https://shop.timexindia.com/cdn/shop/products/tweg22201_1_670x.jpg?v=1690235896`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3A-GGj7VmxtY1_38oL7KHE67suXeRinRB4A&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fshop.timexindia.com%2Fcollections%2Foffers%2Fproducts%2Ftimex-e-class-premium-sport-collection-multifunction-men-analog-green-dial-coloured-quartz-watch-round-dial-with-43-mm-case-width-tweg22201`
        
    },

    {

        name:``,
        wear:`Men's Watch`,
        title:`Timex FitGen 1.43" Round AMOLED Smartwatch With 466x466 Pixel Resolution|Single Sync Bluetooth Calling| Rotating Crown| Metallic Body|Always On Display|AI Voice Assist|100+ Sports Modes|200+ Watchfaces|Upto 7 Days Battery(Normal Usage) - TWTXW502T`,
        saleCategory:`SmartWatch`,
        mrpRate:'₹6,995',
        tradeRate:'₹3,498',
        thumbnail:`https://shop.timexindia.com/cdn/shop/files/1_753c1be6-f4af-4c90-bf69-788fc0966f3d_670x.jpg?v=1708336097`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3A-GGj7VmxtY1_38oL7KHE67suXeRinRB4A&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fshop.timexindia.com%2Fcollections%2Foffers%2Fproducts%2Ftimex-fitgen-1-43-round-amoled-smartwatch-with-466x466-pixel-resolution-single-sync-bluetooth-calling-rotating-crown-metallic-body-always-on-display-ai-voice-assist-100-sports-modes-200-watchfaces-upto-7-days-batterynormal-usage-2`
        
    },

    {

        name:``,
        wear:`Men's Watch`,
        title:`Timex Men Blue Round Stainless Steel Dial Analog Watch-TW2V48900UJ`,
        saleCategory:`SportyLook`,
        mrpRate:'',
        tradeRate:'₹16,995',
        thumbnail:`https://shop.timexindia.com/cdn/shop/files/TW2V48900_670x.jpg?v=1699794847`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3A-GGj7VmxtY1_38oL7KHE67suXeRinRB4A&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fshop.timexindia.com%2Fcollections%2Foffers%2Fproducts%2Ftimex-men-blue-round-stainless-steel-dial-analog-watch-tw2v48900uj`
        
    },
    {

        name:``,
        wear:`Men's Watch`,
        title:`Timex Fashion Men's Blue Dial Round Case Multifunction Function Watch -TWEG19604`,
        saleCategory:`GroomChoice`,
        mrpRate:'₹5,495',
        tradeRate:'₹4,396',
        thumbnail:`https://shop.timexindia.com/cdn/shop/products/tweg19604_670x.jpg?v=1690222049`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3A-GGj7VmxtY1_38oL7KHE67suXeRinRB4A&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fshop.timexindia.com%2Fcollections%2Foffers%2Fproducts%2Fall-stainless-steel-multifcution-watch-tweg19604`
        
    },

    {

        name:``,
        wear:`Men's Watch`,
        title:`Timex UFC Strength Black Round Digital Digital Men Watch - TW5M537000D`,
        saleCategory:`Budget`,
        mrpRate:'₹5,995',
        tradeRate:'₹4,796',
        thumbnail:`https://www.justwatches.com/cdn/shop/files/TW5M537000D_1.jpg?v=1708679110&width=3000`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3A-GGj7VmxtY1_38oL7KHE67suXeRinRB4A&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.justwatches.com%2Fcollections%2Ftimex-ufc-watches%2Fproducts%2Ftimex-ufc-strength-black-round-digital-digital-men-watch-tw5m537000d`
        
    },
    {

        name:``,
        wear:`Men's Watch`,
        title:`Timex Ufc Strength Digital Dial Round Case Quartz Digital Men Watch - TW5M532000D`,
        saleCategory:`UFC Logo`,
        mrpRate:'₹5,495',
        tradeRate:'₹3,846',
        thumbnail:`https://www.justwatches.com/cdn/shop/files/TW5M53200.jpg?v=1699436125&width=3000`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3A-GGj7VmxtY1_38oL7KHE67suXeRinRB4A&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.justwatches.com%2Fcollections%2Ftimex-ufc-watches%2Fproducts%2Ftimex-ufc-strength-digital-dial-round-case-quartz-digital-men-watch-tw5m532000d`
        
    },
    {

        name:``,
        wear:`Men's Watch`,
        title:`Guess Black Dial Round Case Quartz Analog Men Watch - GW0661G3`,
        saleCategory:`GUESS Special`,
        mrpRate:'',
        tradeRate:'₹14,495',
        thumbnail:`https://www.justwatches.com/cdn/shop/files/GW0661G3.jpg?v=1703150344&width=3000`,
        brand:`https://lh3.googleusercontent.com/aPBc20vY-cmFgf00WbwT7n0nHGK5y5qaQ8bxg5jtaV6RIvcompVz1upQKIgv-ytqCLnqn336kywSCnTntL8x4O41-eVu6e_CMfjyhyM=w256-rw`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.justwatches.com%2Fcollections%2Fmens-watches%2Fproducts%2Fguess-black-dial-round-case-quartz-analog-men-watch-gw0661g3`
        
    },
    {

        name:``,
        wear:`Men's Watch`,
        title:`Timex IConnect Gen+ Smartwatch|2.01" TFT Display With 240x296 Pixel Resolution|Rotational Crown|Single Sync Bluetooth Calling|AI Voice Assist|100+ Sports Modes|200+ Watchfaces|Upto 7 Days Battery(Normal Usage) - TWIXW301T`,
        saleCategory:`Gym Stuff`,
        mrpRate:'₹5,495',
        tradeRate:'₹2,473',
        thumbnail:`https://shop.timexindia.com/cdn/shop/files/NewProject_26_670x.png?v=1708080632`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3A-GGj7VmxtY1_38oL7KHE67suXeRinRB4A&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fshop.timexindia.com%2Fcollections%2Foffers%2Fproducts%2Ficonnect-by-timex-unisex-iconnect-calling-dial-rectangular-case-bluetooth-calling-full-touch-activity-tracking-hrm-spo2-blood-pressure-multiple-watch-faces-ai-voice-100-sports-mode-smart-watch-twixw207t-1`
        
    },
    {

        name:``,
        wear:`WomenWatch`,
        title:`Timex Fashion Women's Rose Gold Dial Round Case Multifunction Function Watch -TW000X209`,
        saleCategory:`PartyChoice`,
        mrpRate:'₹4,395',
        tradeRate:'₹3,516',
        thumbnail:`https://shop.timexindia.com/cdn/shop/files/tw000x209_670x.jpg?v=1693559765`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3A-GGj7VmxtY1_38oL7KHE67suXeRinRB4A&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fshop.timexindia.com%2Fcollections%2Foffers%2Fproducts%2Ftw000x209`
        
    },
    {

        name:``,
        wear:`MenWatch`,
        title:`Timex Fashion Men's Green Dial Round Case Multifunction Function Watch -TWEG20008`,
        saleCategory:`PartyChoice`,
        mrpRate:'₹5,195',
        tradeRate:'₹4,156',
        thumbnail:`https://shop.timexindia.com/cdn/shop/files/Untitled-1_f5756ea4-96ec-4697-918f-90c3d3f0a320_670x.jpg?v=1691662106`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3A-GGj7VmxtY1_38oL7KHE67suXeRinRB4A&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fshop.timexindia.com%2Fcollections%2Foffers%2Fproducts%2Ftw000x209`
        
    },
    {

        name:``,
        wear:`Unisex`,
        title:`Titan Crest with 3.63 cm AMOLED Display with AOD, Functional Crown, BT Calling, Premium Smartwatch with Teal Strap`,
        saleCategory:`OfficeWear`,
        mrpRate:'₹11,995',
        tradeRate:'₹5,995',
        thumbnail:`https://www.titan.co.in/dw/image/v2/BKDD_PRD/on/demandware.static/-/Sites-titan-master-catalog/default/dwca0ec4a1/images/Titan/Catalog/90197AP03K_1.jpg?sw=800&sh=800`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPPIu7UMTpuUls3HDJaJaVshQtqpN9S9k80g&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titan.co.in%2Fproduct%2Ftitan-crest-with-3.63-cm-amoled-display-with-aod-functional-crown-bt-calling-premium-smartwatch-90197ap03k.html%3Flang%3Den_IN%26plp%3Dfalse`
        
    },
    {

        name:``,
        wear:`Unisex`,
        title:`Titan Crest with 3.63 cm AMOLED Display with AOD, Functional Crown, BT Calling, Premium Smartwatch with Teal Strap`,
        saleCategory:`OfficeWear`,
        mrpRate:'₹11,995',
        tradeRate:'₹5,995',
        thumbnail:`https://www.titan.co.in/dw/image/v2/BKDD_PRD/on/demandware.static/-/Sites-titan-master-catalog/default/dw3b51417e/images/Titan/Catalog/90197AP01K_1.jpg?sw=800&sh=800`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPPIu7UMTpuUls3HDJaJaVshQtqpN9S9k80g&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titan.co.in%2Fproduct%2Ftitan-crest-with-3.63-cm-amoled-display-with-aod-functional-crown-bt-calling-premium-smartwatch-90197ap01k.html%3Flang%3Den_IN`
        
    },
    {

        name:``,
        wear:`Unisex`,
        title:`Titan Crest with 3.63 cm AMOLED Display with AOD, Functional Crown, BT Calling, Premium Smartwatch with Teal Strap`,
        saleCategory:`SmartWatch`,
        mrpRate:'₹11,995',
        tradeRate:'₹4,995',
        thumbnail:`https://www.titan.co.in/dw/image/v2/BKDD_PRD/on/demandware.static/-/Sites-titan-master-catalog/default/dwcf9305b4/images/Titan/Catalog/90188AP03_1.jpg?sw=800&sh=800`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPPIu7UMTpuUls3HDJaJaVshQtqpN9S9k80g&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titan.co.in%2Fproduct%2Ftitan-smart-watch-amoled-4.97-cm-%7C-410x502-pixels-%7C-always-on-%7C-singlesync-bt-%7C-fast-charging-%7C-ai-assistant-%7C-ip68-%7C-many-styles-90188ap03.html%3Flang%3Den_IN%26plp%3Dfalse`
        
    },
    {

        name:``,
        wear:`Unisex`,
        title:`Titan Crest with 3.63 cm AMOLED Display with AOD, Functional Crown, BT Calling, Premium Smartwatch with Teal Strap`,
        saleCategory:`SmartWatch`,
        mrpRate:'₹11,995',
        tradeRate:'₹4,995',
        thumbnail:`https://www.titan.co.in/dw/image/v2/BKDD_PRD/on/demandware.static/-/Sites-titan-master-catalog/default/dw69175cab/images/Titan/Catalog/90188AP05_1.jpg?sw=800&sh=800`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPPIu7UMTpuUls3HDJaJaVshQtqpN9S9k80g&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titan.co.in%2Fproduct%2Ftitan-smart-watch-amoled-4.97-cm-%7C-410x502-pixels-%7C-always-on-%7C-singlesync-bt-%7C-fast-charging-%7C-ai-assistant-%7C-ip68-%7C-many-styles-90188ap05.html%3Flang%3Den_IN`
        
    },
    {

        name:``,
        wear:`MensWatch`,
        title:`Titan Men's Timeless Style Watch: Refined Black Dial and Metal Strap`,
        saleCategory:`BestSeller`,
        mrpRate:'₹',
        tradeRate:'₹3,995',
        thumbnail:`https://www.titan.co.in/dw/image/v2/BKDD_PRD/on/demandware.static/-/Sites-titan-master-catalog/default/dwcfb00132/images/Titan/Catalog/1806NM01_1.jpg?sw=800&sh=800`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPPIu7UMTpuUls3HDJaJaVshQtqpN9S9k80g&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titan.co.in%2Fproduct%2Ftitan-quartz-analog-with-date-watch-for-men-black-dial-with-metal-strap-1806nm01.html%3Flang%3Den_IN%26plp%3Dfalse`
        
    },
    {

        name:``,
        wear:`MensWatch`,
        title:` Titan Neo Splash Quartz Multifunction Black Dial Stainless Steel Strap Watch for Men`,
        saleCategory:`BestSeller`,
        mrpRate:'',
        tradeRate:'₹7,345',
        thumbnail:`https://www.titan.co.in/dw/image/v2/BKDD_PRD/on/demandware.static/-/Sites-titan-master-catalog/default/dw65f44689/images/Titan/Catalog/1805QM04_2.jpg?sw=800&sh=800`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPPIu7UMTpuUls3HDJaJaVshQtqpN9S9k80g&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titan.co.in%2Fproduct%2Ftitan-black-dial-analog-with-date-watch-for-men-1805qm04.html%3Flang%3Den_IN%26plp%3Dtrue%26catID%3Dcasual-sporty-watches`
        
    },
    {

        name:``,
        wear:`MensWatch`,
        title:`Titan Grandmaster Silver ChronoMetal Strap Watch for Men`,
        saleCategory:`Exclusive`,
        mrpRate:'₹13,995',
        tradeRate:'₹6,995',
        thumbnail:`https://www.titan.co.in/dw/image/v2/BKDD_PRD/on/demandware.static/-/Sites-titan-master-catalog/default/dwf9606282/images/Titan/Catalog/1786KM01_1.jpg?sw=800&sh=800`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPPIu7UMTpuUls3HDJaJaVshQtqpN9S9k80g&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titan.co.in%2Fproduct%2Fthe-king-by-titan---chronograph-watch-with-retrograde-movement-1786km01.html%3Flang%3Den_IN%26plp%3Dtrue%26catID%3Dcasual-sporty-watches`
        
    },
    {

        name:``,
        wear:`MensWatch`,
        title:`Titan Neo Splash Blue Dial Quartz Multifunction Stainless Steel Strap watch for Men`,
        saleCategory:`Mechanical`,
        mrpRate:'',
        tradeRate:'₹7,995',
        thumbnail:`https://www.titan.co.in/dw/image/v2/BKDD_PRD/on/demandware.static/-/Sites-titan-master-catalog/default/dwe4c4118b/images/Titan/Catalog/1805QM01_2.jpg?sw=800&sh=800`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPPIu7UMTpuUls3HDJaJaVshQtqpN9S9k80g&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titan.co.in%2Fproduct%2Ftitan-blue-dial-analog-with-date-watch-for-men-1805qm01.html%3Flang%3Den_IN%26plp%3Dtrue%26catID%3Dcasual-sporty-watches`
        
    },
    {

        name:``,
        wear:`Unisex`,
        title:`Titan Talk- Touch Screen Watch with Grey Strap, BT Calling, AI- Voice Assistant, and Amoled Display`,
        saleCategory:`Ai-Watch`,
        mrpRate:'₹14,995',
        tradeRate:'₹9,995',
        thumbnail:`https://www.titan.co.in/dw/image/v2/BKDD_PRD/on/demandware.static/-/Sites-titan-master-catalog/default/dw25c714c1/images/Titan/Catalog/90156AP03_1.jpg?sw=800&sh=800`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPPIu7UMTpuUls3HDJaJaVshQtqpN9S9k80g&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titan.co.in%2Fproduct%2Ftitan-talk-smart-bluetooth-watch-with-grey-silicone-strap-90156ap03.html%3Flang%3Den_IN%26plp%3Dfalse`
        
    },
    {

        name:``,
        wear:`MensWatch`,
        title:`Titan Octane Black Dial Chronograph Watch for Men`,
        saleCategory:`Trending`,
        mrpRate:'',
        tradeRate:'₹11,765',
        thumbnail:`https://www.titan.co.in/dw/image/v2/BKDD_PRD/on/demandware.static/-/Sites-titan-master-catalog/default/dw97202022/images/Titan/Catalog/90086KM05_2.jpg?sw=800&sh=800`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPPIu7UMTpuUls3HDJaJaVshQtqpN9S9k80g&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titan.co.in%2Fproduct%2Ftitan-octane-black-dial-stainless-steel-strap-watch-for-men-90086km05.html%3Flang%3Den_IN%26plp%3Dtrue%26catID%3Dcasual-sporty-watches`
        
    },
    {

        name:``,
        wear:`MensWatch`,
        title:`Titan Octane Blue Dial Quartz Multifunction Stainless Steel Strap watch for Men`,
        saleCategory:`TrendSetter`,
        mrpRate:'',
        tradeRate:'₹8,565',
        thumbnail:`https://www.titan.co.in/dw/image/v2/BKDD_PRD/on/demandware.static/-/Sites-titan-master-catalog/default/dw20d75152/images/Titan/Catalog/90104KM01_1.jpg?sw=800&sh=800`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPPIu7UMTpuUls3HDJaJaVshQtqpN9S9k80g&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titan.co.in%2Fproduct%2Ftitan-blue-dial-multifunction-watch-for-men-90104km01.html%3Flang%3Den_IN%26plp%3Dtrue%26catID%3Dcasual-sporty-watches`
        
    },
    {

        name:``,
        wear:`MensWatch`,
        title:`Titan Neo Splash Brown Dial Analog with Date Stainless Steel Strap Watch for Men`,
        saleCategory:`DateTime`,
        mrpRate:'',
        tradeRate:'₹5,995',
        thumbnail:`https://www.titan.co.in/dw/image/v2/BKDD_PRD/on/demandware.static/-/Sites-titan-master-catalog/default/dw7e4f7900/images/Titan/Catalog/1806QM03_1.jpg?sw=800&sh=800`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPPIu7UMTpuUls3HDJaJaVshQtqpN9S9k80g&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titan.co.in%2Fproduct%2Ftitan-neo-splash-quartz-analog-with-date-brown-dial-for-men-with-brown-colour-stainless-steel-strap-1806qm03.html%3Flang%3Den_IN%26plp%3Dtrue`
        
    },
    {

        name:``,
        wear:`MensWatch`,
        title:`Titan Quartz Chronograph Black Dial Stainless Steel Strap Watch for Men`,
        saleCategory:`MostViewed`,
        mrpRate:'',
        tradeRate:'₹10,895',
        thumbnail:`https://www.titan.co.in/dw/image/v2/BKDD_PRD/on/demandware.static/-/Sites-titan-master-catalog/default/dwaf526e53/images/Titan/Catalog/90077NM01J_1.jpg?sw=800&sh=800`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPPIu7UMTpuUls3HDJaJaVshQtqpN9S9k80g&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titan.co.in%2Fproduct%2Ftitan-quartz-chronograph-watch-for-men-black-dial-with-stainless-steel-strap-90077nm01j.html%3Flang%3Den_IN%26plp%3Dtrue`
        
    },
    {

        name:``,
        wear:`MensWatch`,
        title:`Titan Quartz Chronograph Blue Dial Stainless Steel Strap Watch for Men`,
        saleCategory:`Trending`,
        mrpRate:'',
        tradeRate:'₹9,635',
        thumbnail:`https://www.titan.co.in/dw/image/v2/BKDD_PRD/on/demandware.static/-/Sites-titan-master-catalog/default/dw0eb6b153/images/Titan/Catalog/90077KM02J_1.jpg?sw=800&sh=800`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPPIu7UMTpuUls3HDJaJaVshQtqpN9S9k80g&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titan.co.in%2Fproduct%2Ftitan-quartz-chronograph-watch-for-men-blue-dial-with-stainless-steel-strap-90077km02j.html%3Flang%3Den_IN`
        
    },
    {

        name:``,
        wear:`MensWatch`,
        title:`Titan Octane White Dial Quartz Multifunction Stainless Steel Strap watch for Men`,
        saleCategory:`BestSeller`,
        mrpRate:'',
        tradeRate:'₹9,415',
        thumbnail:`https://www.titan.co.in/dw/image/v2/BKDD_PRD/on/demandware.static/-/Sites-titan-master-catalog/default/dw171113d9/images/Titan/Catalog/90104KM03_1.jpg?sw=800&sh=800`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPPIu7UMTpuUls3HDJaJaVshQtqpN9S9k80g&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.titan.co.in%2Fproduct%2Ftitan-white-dial-multifunction-watch-for-men-90104km03.html%3Flang%3Den_IN%26plp%3Dtrue`
        
    },





]


export default  watchContent;