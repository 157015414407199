const data =[

    {

        name:`Wide`,
        electronics: `airpodes`,
        title:`Gold White Full Rim Round`,
        saleCategory:`airpodes`,
        mrpRate:`₹4999`,
        tradeRate:`₹2,000`,
        thumbnail:`https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//model/v/i/gold-white-full-rim-round-eyeglasses_29_may_m-f274700_206264_08_06_2023.jpg`,
        brand:'https://static.lenskart.com/media/desktop/img/site-images/main_logo.svg',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Fvincent-chase-vc-e15346-c2-eyeglasses.html`
        
    },
    {

        name:`Extra Wide`,
        electronics: `airpodes`,
        title:`Black Full Rim Rectangle/ Square`,
        saleCategory:`airpodes`,
        mrpRate:`₹4999`,
        tradeRate:`₹1,500`,
        thumbnail:`https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//model/v/i/karan-johar-:-black-blue-gradient-full-rim-square-vincent-chase--urban-retro-vc-s14537-c2-polarized-sunglasses_18_oct_2022_male_shoot_vc159697_151554_20_10_2022.jpg`,
        brand:'https://static.lenskart.com/media/desktop/img/site-images/main_logo.svg',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Fvincent-chase-vc-s14537-c2-sunglasses.html`
        
    },

    {

        name:`Medium`,
        electronics: `lenskart`,
        title:`Brown Blue Tortoise Full Rim Aviator`,
        saleCategory:`airpodes`,
        mrpRate:`₹2,000`,
        tradeRate:`₹2,000   `,
        thumbnail:`https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//model/v/i/brown-blue-tortoise-brown-full-rim-square-vincent-chase-classic-acetate-vc-e14722-c2-eyeglasses_23_march_2023_m_f_shoot235012_152906_28_march23.jpg`,
        brand:'https://static.lenskart.com/media/desktop/img/site-images/main_logo.svg',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Fvincent-chase-vc-e14722-c2-eyeglasses.html`
        
    },
    {

        name:`Extra Wide`,
        electronics: `lenskart`,
        title:`Brown Transparent Full Rim Square`,
        saleCategory:`airpodes`,
        mrpRate:`₹3,500`,
        tradeRate:`₹3,500`,
        thumbnail:`https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//model/j/i/brown-full-rim-square-john-jacobs-kjo-x-jj-e15686-c2-eyeglasses_imgplp6306.jpg`,
        brand:'https://static.lenskart.com/media/desktop/img/site-images/main_logo.svg',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Fjohn-jacobs-jj-e15686-c2-eyeglasses.html`
        
    },
    {

        name:`medium`,
        electronics: `lenskart`,
        title:`Blue Full Rim Square`,
        saleCategory:`airpodes`,
        mrpRate:`₹3,500`,
        tradeRate:`₹2,000`,
        thumbnail:`https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//model/l/i/blue-full-rim-square-lenskart-studio-classic-acetate-lk-e17006-c1-eyeglasses_21_feb_2023_m_f381426_217541_23_02_2024.jpg`,
        brand:'https://static.lenskart.com/media/desktop/img/site-images/main_logo.svg',
        blink:`https://www.lenskart.com/lenskart-studio-lk-e17006-c1-eyeglasses.html`
        
    },
    {

        name:`Wide`,
        electronics: `lenskart`,
        title:`Gold Full Rim Aviator`,
        saleCategory:`airpodes`,
        mrpRate:`₹3,500`,
        tradeRate:`₹2,000`,
        thumbnail:`https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//model/l/i/gold-full-rim-aviator-lenskart-studio-sleek-steel-lk-e17009-c1-eyeglasses_21_feb_2023_m_f381350_217113_23_02_2024.jpg`,
        brand:'https://static.lenskart.com/media/desktop/img/site-images/main_logo.svg',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Fvincent-chase-vc-e16946-c1-eyeglasses.html`
        
    },
    {

        name:`Vincent Chase`,
        electronics: `lenskart`,
        title:`Black Full Rim Square`,
        saleCategory:`airpodes`,
        mrpRate:`₹3,500`,
        tradeRate:`₹2,000`,
        thumbnail:`https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//v/i/black-full-rim-square-vincent-chase--urban-retro-vc-s14537-c3-eyeglasses_csvfile-1662541466575-copy_of_kwk_-_26-79918final.jpg`,
        brand:'https://static.lenskart.com/media/desktop/img/site-images/main_logo.svg',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Fvincent-chase-vc-s14537-c3-dup-eyeglasses.html`
        
    },
    {

        name:`Vincent Chase`,
        electronics: `lenskart`,
        title:`Brown Full Rim Square`,
        saleCategory:`airpodes`,
        mrpRate:`₹3,500`,
        tradeRate:`₹2,000`,
        thumbnail:`https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//l/i/brown-full-rim-square-lenskart-studio-ls-e15666-c8-eyeglasses_5_jan_m_f361251_217191_10_01_2024.jpg`,
        brand:'https://static.lenskart.com/media/desktop/img/site-images/main_logo.svg',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Fvincent-chase-vc-e15666-c8-eyeglasses.html`
        
    },
    {

        name:`Lenskart Hustlr`,
        electronics: `lenskart`,
        title:`Denim Full Rim Wayfarer`,
        saleCategory:`airpodes`,
        mrpRate:`₹3,500`,
        tradeRate:`₹1,500`,
        thumbnail:`https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//model/l/i/denim-full-rim-wayfarer-lenskart-hustlr-lh-e16985-w-c1-eyeglasses_csvfile-1705906507951-18_jan_m-f367076_217201.jpg`,
        brand:'https://static.lenskart.com/media/desktop/img/site-images/main_logo.svg',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Flenskart-hustlr-lh-e16985-w-c1-eyeglasses.html`
        
    },
    {

        name:`John Jacobs`,
        electronics: `lenskart`,
        title:`Gold Full Rim Square`,
        saleCategory:`airpodes`,
        mrpRate:`₹3,500`,
        tradeRate:`₹6,000`,
        thumbnail:`https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//model/j/i/gold-tortoise-grey-gradient-full-rim-square-john-jacobs-jj-tints-jj-s16883-c2-sunglasses_216880_2_10apr24.jpg`,
        brand:'https://static.lenskart.com/media/desktop/img/site-images/main_logo.svg',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Fjohn-jacobs-jj-s16883-c2-sunglasses.html`
        
    },

    {

        name:`Vincent Chase`,
        electronics: `lenskart`,
        title:`Brown Blue Tortoise Aviator`,
        saleCategory:`airpodes`,
        mrpRate:`₹3,500`,
        tradeRate:`₹2,000`,
        thumbnail:`https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//model/v/i/brown-blue-tortoise-brown-full-rim-square-vincent-chase-classic-acetate-vc-e14722-c2-eyeglasses_23_march_2023_m_f_shoot234728_152906_28_march23.jpg`,
        brand:'https://static.lenskart.com/media/desktop/img/site-images/main_logo.svg',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.lenskart.com%2Fvincent-chase-vc-e14722-c2-eyeglasses.html`
        
    },

]

export default data 