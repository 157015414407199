import React from "react";
import { useParams } from "react-router-dom";
// pages 
import articleContent from "./Articlecontent"
import  { useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from '../components/NavBar'


//component




//apges
import Notfound from "./Notfound";






const Article = () =>{

   
    const { name } = useParams();

        const article = articleContent.find((article) => article.name === name);
        const [activeImg, setActiveImage] =useState(article.thumbnail)
       

    if(!article) return <Notfound />;
    
    

   

    
    return (
        <>

        <Navbar />
         <Helmet>
        <title>{article.title}</title>
        <meta name="description" content={article.content}/>
        <meta name="keywords" keywords={article.keyword}/>
      </Helmet>
        <div className="xl:m-14 dark:text-white sm:m-6 lg:m-10 m-3">

                
                 <div className="flex flex-col jutify-between lg:flex-row gap-16 lg:items-center" >
                    <div className="flex flex-col gap-6 lg:w-2/4  2xl:w-1/3">
                 <img  className="w-full h-full mt-5 object-cover rounded-xl " src={activeImg} alt="blog"/>

                 <div className="flex flex-row sm:justify-between ml-14 items-center gap-2 lg:gap- md:gap-1 h-24">
                 <img  className="w-20 h-24 rounded-md cursor-pointer" src={article.thumbnail} alt="blog"  onClick={()=>setActiveImage(article.thumbnail)}/>

                 <img  className="w-20 h-24 rounded-md cursor-pointer" src={article.image} alt="blog"  onClick={()=>setActiveImage(article.image)}/>
                 <img className="w-20 h-24 rounded-md cursor-pointer" src={article.image1} alt="blog" onClick={()=>setActiveImage(article.image1)} />
                 </div>
                 </div>
                 {/*title and other detail */ }

                 <div className="flex flex-col gap-3  lg:w-2/4 2xl:w-3/4 ">
                    <div>
                    <span className={article.Scolor}>{article.wear}</span>
                    <h1 className={article.tcolor}>{article.title}</h1>
                    </div>
                    <p className="text-grey-700">{article.content}</p>
                    <div>
                        <div className=" flex flex-row ">
                            <span className="bg-orange-600 py-2 px-5 border rounded-l-lg">
                                <h1 className="lg:text-2xl text-lg">Available on</h1>
                            </span>
                           

                        </div>
                        <div className="flex gap-6 m-8">
                                <a className="" href={article.blink}><img className="h-10 w-10 " src={article.buy} alt=""/></a>
                                <a className="" href={article.blink1}><img className="h-10 w-10" src={article.buy1} alt="" /></a>
                                <a className="" href={article.blink2}><img className="h-10 w-10" src={article.buy2} alt="" /></a>
                            </div>
                           
                            
                    </div>
                    <div className="flex flex-row rounded">
                            <span className="bg-black py-2 px-3 xl:px-3 border rounded-lg">
                                <h1 className="text-sm lg:text-xl text-white">Follow for latest Update</h1>
                            </span>
                        </div>
                        <div className="flex gap-6 m-4 mb-5 ">
                                <a className="" href={article.instagram}><img className="h-10 w-10 box-content" src={article.icon} alt=""/></a>
                                <a className="" href={article.pinterest}><img className="h-10 w-10 box-content" src={article.icon1} alt="" /></a>
                                <a className="" href={article.whatsapp}><img className="h-10 w-10 box-content" src={article.icon2} alt="" /></a>
                            </div>
                            <div className="mb-5 items-end">
                                <a className="" href={article.jlink}><img className={article.bclass} src={article.summary} alt="jlink"/></a>
                                
                            </div>

                 </div>
                


                 </div>

                 <div className="m-8 border rounded-3xl bg-gray-400 lg:mx-[15rem]">
                    <h3 className="text-xl font-bold text-center m-4 ">
                    Find its usage and features below
                    </h3>
                 </div>

                 



                 <div className="flex flex-col justify-center m-6  sm:py-12">
                    <div className="w-full py-2 px-2 sm:max-w-xl sm:mx-auto sm:px-0">
                        {/*verticle line in middle */}
                        <div className="hidden absolute w-1 sm:block bg-black h-full left-1/2 transform -translate-x-1/2">                        
                        </div>
                         {/*============left card start ==============*/}
                        <div className="mt-6 sm:mt-0 sm:mb-12">
                            <div className="flex items-center flex-col sm:flex-row">
                                   
                                <div className="flex justify-start w-full mx-auto items-center">
                                    <div className="w-full sm:w-1/2 sm:pr-8">
                                        <div data-aos="fade-right" data-aos-duration="1200" 
                                        className="bg-white p-4 rounded shadow group hover:bg-orange-600
                                        cursor-pointer ease-in duration-150">
                                            <h3 className="text-orange-400 font-[700] mb-3 group-hover:text-white 
                                            group-hover:font-[600] md:text-xl sm:tex-xl text-2xl">{article.heading}</h3>
                                            <p className="text-[15px] text-black group-hover:text-white
                                            group-hover:font-[500] leading-7">{article.paragraph}
                                             </p>
                                        </div>
                                    </div>
                                </div>
                               

                               
                                
                            </div>
                        </div>
                                            {/*============left card end ==============*/} 

                                            
                                            {/*============right card start ==============*/} 
                                            <div className="mt-6 sm:mt-0 sm:mb-12">
                            <div className="flex items-center flex-col sm:flex-row">
                                   
                                <div className="flex justify-end w-full mx-auto items-center">
                                    <div className="w-full sm:w-1/2 sm:pl-8">
                                        <div data-aos="fade-left" data-aos-duration="1200" 
                                        className="bg-white p-4 rounded shadow group hover:bg-gray-600
                                        cursor-pointer ease-in duration-150">
                                            <h3 className="text-black font-[700] mb-3 group-hover:text-white 
                                            group-hover:font-[600] text-2xl">{article.heading1}</h3>
                                            <p className="text-[15px] text-black group-hover:text-white
                                            group-hover:font-[500] leading-7">{article.paragraph1}
                                             </p>
                                        </div>
                                    </div>
                                </div>
                               

                                
                                
                            </div>
                        </div>
                         {/*============right card end ==============*/} 

                         <div className="hidden absolute w-1 mt-0 sm:block bg-black left-1/2 transform -translate-x-1/2">                        
                        </div>
                        <div className="hidden absolute w-1 sm:block bg-black h-full left-1/2 transform -translate-x-1/2">                        
                        </div>
                          {/*============left card start ==============*/}
                          
                          <div className="mt-6 sm:mt-0 sm:mb-12">
                            <div className="flex items-center flex-col sm:flex-row">
                                   
                                <div className="flex justify-start w-full mx-auto items-center">
                                    <div className="w-full sm:w-1/2 sm:pr-8">
                                        <div data-aos="fade-right" data-aos-duration="1200" 
                                        className="bg-white p-4 rounded shadow group hover:bg-black
                                        cursor-pointer ease-in duration-150">
                                            <h3 className="text-green-600 font-[700] sm:text-xl mb-3 group-hover:text-white 
                                            group-hover:font-[600] text-2xl">{article.heading2}</h3>
                                            <p className="text-[15px] text-black group-hover:text-white
                                            group-hover:font-[500] leading-7">{article.paragraph2}
                                             </p>
                                        </div>
                                    </div>
                                </div>
                
                                
                                   
                                
                            </div>
                        </div>
                        

                          {/*============right card start ==============*/} 
                          <div className="mt-6 sm:mt-0 sm:mb-12">
                            <div className="flex items-center flex-col sm:flex-row">
                                   
                                <div className="flex justify-end w-full mx-auto items-center">
                                    <div className="w-full sm:w-1/2 sm:pl-8">
                                        <div data-aos="fade-left" data-aos-duration="1200" 
                                        className="bg-white p-4 rounded shadow group hover:bg-red-300
                                        cursor-pointer ease-in duration-150">
                                            <h3 className="text-red-600  font-[600] mb-3 group-hover:text-white 
                                            group-hover:font-[600] md:text-xl sm:text-xl text-2xl">{article.heading3}</h3>
                                            <p className="text-[15px] text-black group-hover:text-white
                                            group-hover:font-[500] leading-7">{article.paragraph3}
                                             </p>
                                        </div>
                                    </div>
                                </div>
                               

                                
                                
                            </div>
                        </div>
                          {/*============right card end ==============*/} 
                             {/*============left card start ==============*/}
                        <div className="mt-6 sm:mt-0 sm:mb-12">
                            <div className="flex items-center flex-col sm:flex-row">
                                   
                                <div className="flex justify-start w-full mx-auto items-center">
                                    <div className="w-full sm:w-1/2 sm:pr-8">
                                        <div data-aos="fade-right" data-aos-duration="1200" 
                                        className="bg-white p-4 rounded shadow group hover:bg-purple-300
                                        cursor-pointer ease-in duration-150">
                                            <h3 className="text-purple-600 font-[700] mb-3 group-hover:text-white 
                                            group-hover:font-[600] text-2xl">{article.heading4}</h3>
                                            <p className="text-[15px] text-black group-hover:text-white
                                            group-hover:font-[500] leading-7">{article.paragraph4}
                                             </p>
                                        </div>
                                    </div>
                                </div>
                               

                               
                                
                            </div>
                        </div>
                          {/*============left card end ==============*/} 









                    </div>
                </div>
                
                           
     

                 <br/>


        </div>
                 

        </>
    );
};

export default Article