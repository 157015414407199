const data =[
    
    {

        img:`/images/5-13june/watchbanner0.jpg`,
        link:`https://amzn.urlvia.com/57gmdU`,

    },
    {

        img:`/images/5-13june/watchbanner1.jpg`,
        link:`https://amzn.urlvia.com/4uYW2j`,

    },
    {

        img:`/images/5-13june/watchbanner2.jpg`,
        link:`https://amzn.urlvia.com/Y2yHnZ`,

    },
    {

        img:`/images/5-13june/watchbanner3.jpg`,
        link:`https://amzn.urlvia.com/4mvj0G`,

    },
    {

        img:`/images/5-13june/watchbanner4.jpg`,
        link:`https://amzn.urlvia.com/O8CGoT`,

    },
]

export default data