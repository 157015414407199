const content = [
    
    {
        name: "logo",
        thumbnail: "/images/logo.jpg",
        whatsapp:"/my-blog/public/images/whatsapp.png",
        paragraph:['Welcome to Trendz, the ultimate fashion hub designed exclusively for the trendsetters of Generation Z. We understand that your style is a powerful form of self-expression,  ',
       ' and Trendz is here to empower you with the latest clothing and accessories that not only look good but also resonate with your values. ',
      ' Lets dive into the world of Trendz and explore how we were reshaping the fashion landscape for the Gen Z trailblazers.'],
        

      },

    

     
      




  
]


export default content ;