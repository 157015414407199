import React from 'react'
import { GoArrowRight } from "react-icons/go";



import Dotkeycontent from '../content/dotkeycontent'


const dotkey = () => {
  return (
    <>
    {Dotkeycontent.map((article, index)=>(
    <div key={index} className="lg:p-4 w-1/2  2xl:w-1/6 lg:w-1/4 md:w-1/3  ">
    <div className="w-[10rem] h-auto md:h-[27rem] md:w-[15rem]  m-5 border-gray-200 border-opacity-60 rounded-xl overflow-hidden">
         <a href={article.blink}>
            <img className="h-[9rem] lg:h-[13rem]  md:h-1/2 w-full object-cover " src={article.thumbnail}
            alt="blog"/>

            <div className='flex px-3 gap-6'>
            <h4 className='test-sm text-orange-400 ' >{article.saleCategory} </h4>
            <p className='text-sm'>{article.wear}</p>
            </div>

            
         </a>
         <div className="p-4 flex gap-3">
            
            
                <h3 className='text-lg font-semibold'>{article.title.substring(0, 12)}...</h3>
                
            
            </div>
            <div className='flex p-3 gap-2 '>
            <del>{article.mrpRate}</del>
                <h1>{article.tradeRate}</h1>
                
            </div>

          
            <div className=" flex lg:mt-5 gap-1 lg:gap-3 justify-center items-center border bg-red-500 rounde-xl">
            <img className='w-1/4 h-auto pr-1 Hover:bg-orange-500 ' src={article.brand} alt='brandlogo' />
                <a href={article.blink} className='flex-inline inline-flex  font-semibold hover:text-orange-500 text-white  text-sm'>Buy Now</a>
                <a href={article.blink} className=" hover:border-2px hover:bg-orange-500 ml-4 rounded-xl  text-white  items-center" placeholder='read more' to={`/article/${article.name}`}>
                <GoArrowRight className='text-3xl ' /></a>
            </div>
            
    </div>
    
</div>))}

</>
  )
}

export default dotkey