const data =[
    {
        title:`Mayze Embroidery`,
        tradeRate:`₹3,299`,
        feature:`Youth Sneaker`,
        thumbnail:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/397281/01/sv01/fnd/IND/fmt/png/Mayze-Embroidery-Youth-Sneakers`,
        blink:`https://www.dpbolvw.net/click-101131564-15577250?url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fpd%2Fmayze-embroidery-youth-sneakers%2F397281%3Fswatch%3D01`,
    },

    {
        title:`BMW M Motorsport Drift Cat Dec.`,
        tradeRate:`₹3,299`,
        feature:`Kid's Sneaker`,
        thumbnail:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/307267/09/sv01/fnd/IND/fmt/png/BMW-M-Motorsport-Drift-Cat-Dec.-Kid's-Sneakers`,
        blink:`https://www.kqzyfj.com/click-101131564-15577250?url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fpd%2Fbmw-m-motorsport-drift-cat-dec--kids-sneakers%2F307267%3Fswatch%3D09`,
    },
    {
        title:`Mayze Embroidery`,
        tradeRate:`₹3,569`,
        feature:`Youth Sneaker`,
        thumbnail:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/397281/02/sv01/fnd/IND/fmt/png/Mayze-Embroidery-Youth-Sneakers`,
        blink:`https://www.anrdoezrs.net/click-101131564-15577250?url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fpd%2Fmayze-embroidery-youth-sneakers%2F397281%3Fswatch%3D02`,
    },
    {
        title:`ULTRA PLAY FG/AG`,
        tradeRate:`₹4,499`,
        feature:`Youth Football Boots`,
        thumbnail:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/107775/02/sv01/fnd/AUS/fmt/png/ULTRA-PLAY-FG/AG-Youth-Football-Boots`,
        blink:`https://www.anrdoezrs.net/click-101131564-15577250?url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fpd%2Fultra-play-fg-ag-youth-football-boots%2F107775%3Fswatch%3D02`,
    },
    {
        title:`Cali Court Match`,
        tradeRate:`₹3,369`,
        feature:`Kid's Sneakers`,
        thumbnail:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/393804/02/sv01/fnd/IND/fmt/png/Cali-Court-Match-Kids'-Sneakers`,
        blink:`https://www.tkqlhce.com/click-101131564-15577250?url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fpd%2Fcali-court-match-kids-sneakers%2F393804%3Fswatch%3D02`,
    },

    {
        title:`Mayze Sweater Weather`,
        tradeRate:`₹3,569`,
        feature:`Kid's Sneakers`,
        thumbnail:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/392633/01/sv01/fnd/IND/fmt/png/Mayze-Sweater-Weather-Kids'-Sneakers`,
        blink:`https://www.anrdoezrs.net/click-101131564-15577250?url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fpd%2Fmayze-sweater-weather-kids-sneakers%2F392633%3Fswatch%3D01`,
    },

    {
        title:`CREDULO 2.O`,
        tradeRate:`₹1,899`,
        feature:`Kid's Sneakers`,
        thumbnail:`https://assets.adidas.com/images/h_840,f_auto,q_auto,fl_lossy,c_fill,g_auto/d63dc61977414dd49d32be63eeb2d46e_9366/CREDULO_2.O_SHOES_Red_IQ7363_01_standard.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.adidas.co.in%2Fcredulo-2.o-shoes%2FIQ7363.html`,
    },
    {
        title:`RapidaSport`,
        tradeRate:`₹3,299`,
        feature:`Kid's Sneakers`,
        thumbnail:`https://assets.adidas.com/images/h_840,f_auto,q_auto,fl_lossy,c_fill,g_auto/abab67dc93ee4442992449a1361c38b0_9366/RapidaSport_Shoes_Kids_Beige_ID2714_01_standard.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.adidas.co.in%2Frapidasport-shoes-kids%2FID2714.html`,
    },

    {
        title:`Predator 24 League Turf Boots`,
        tradeRate:`₹2,999`,
        feature:`Youth Sneakers`,
        thumbnail:`https://assets.adidas.com/images/h_840,f_auto,q_auto,fl_lossy,c_fill,g_auto/e5fb1325911443c398448670ba52a7ca_9366/Predator_24_League_Turf_Boots_Black_IG5442_22_model.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.adidas.co.in%2Fpredator-24-league-turf-boots%2FIG5442.html`,
    },

    {
        title:`Disney x MONOFIT Trainer Lifestyle Slip-On`,
        tradeRate:`₹2,299`,
        feature:`Kids Sneakers`,
        thumbnail:`https://assets.adidas.com/images/h_840,f_auto,q_auto,fl_lossy,c_fill,g_auto/4822239dc90948feb232af5c00e5a6fc_9366/Disney_x_MONOFIT_Trainer_Lifestyle_Slip-On_Shoes_Black_HP7774_01_standard.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.adidas.co.in%2Fdisney-x-monofit-trainer-lifestyle-slip-on-shoes%2FHP7774.html`,
    },

    {
        title:`X Speedflow.3 Firm Ground Boots`,
        tradeRate:`₹2,239`,
        feature:`Youth Sneakers`,
        thumbnail:`https://assets.adidas.com/images/h_840,f_auto,q_auto,fl_lossy,c_fill,g_auto/c80b0937d3774274b5e1ad7a009a4bf6_9366/X_Speedflow.3_Firm_Ground_Boots_Blue_GW7486_01_standard.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.adidas.co.in%2Fx-speedflow.3-firm-ground-boots%2FGW7486.html`,
    },

]

export default data ;