const icon = [

    {
        icon : "/images/pinterest.png",
        icon1 : "/images/instagram.png",
        myntra: "/images/myntra.png",
        adidas: "/images/adidas.png",
        nike: "/images/nike.png",
        puma:"/images/puma.png",
        ajio:"/images/ajio.png",
        tatacliq:"/images/tatacliq.png",
        shopperstop:"/images/shopperstop.png",

    },
    
]
export default icon