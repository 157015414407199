import React from "react";
import { Link } from "react-router-dom";
import icon from "../pages/icon";
import homeContent from "../pages/homeContent";



const footer = () => {
    return (

        <>

        <div className="bg-gradient-to-r from-orange-300 to-orange-500 text-black md:rounded-3xl md:mx-[5rem] md:mb-[10rem] lg:mx-[5rem]">

        
            <div className="flex flex-col md:flex-row justify-between p-8 lg:px-[1rem] md:px-[3rem] px-5">


                <div className="lg:m-[1rem] md:mt-4 mb-5">
                {homeContent.map((home, index)=>(
                    <span>
                <img
              data-aos="fade-left"
              data-aos-duration="1500" className="w-14 h-14 lg:w-20 lg:h-20 md:w-20 md:h-20 rounded-full" src={home.thumbnail} alt="logo"/>

                 </span>
                ))}
                </div>



                <div className="w-full md:w-1/3">
                    <h1 data-aos="fade-down" data-aos-duration="1200" className="font-semibold text-xl pb-4">
                        Trend'Z
                    </h1>
                    <p
                    data-aos="fade-up" data-aos-duration="1200"  className="text-sm">
                    Share your Trendz finds directly on your favorite platforms and get your friends in on the style revolution.
                    </p>

                </div>

                <div className="">
                <h1 data-aos="fade-right" data-aos-duration="1200" 
                 className="flex font-medium text-xl pb-4 pt-5 md:pt-0">Pages</h1>
                <ul>

                    <li data-aos="fade-left" data-aos-duration="1200" >
                    <Link to="/" className="">Home</Link>
                    </li>

                    <li data-aos="fade-right" data-aos-duration="1200" >
         
                    <Link to="/About" className="">About</Link>
                    </li>

                    <li data-aos="fade-up" data-aos-duration="1200" >
                    <Link to="/Articlelist" className="">Articles</Link>
                    </li>
                    </ul>

                
                </div>
                <div className="">
                <h1 data-aos="fade-right" data-aos-duration="1200" 
                 className="flex font-medium text-xl pb-4 pt-5 md:pt-0">Segments</h1>
                <ul>

                    <li data-aos="fade-left" data-aos-duration="1200" >
                    <Link to="/Watch" className="">Watches</Link>
                    </li>

                    <li data-aos="fade-right" data-aos-duration="1200" >
         
                    <Link to="/Perfume" className="">Perfume</Link>
                    </li>

                    <li data-aos="fade-up" data-aos-duration="1200" >
                    <Link to="/Airpods" className="">Airpods</Link>
                    </li>
                    <li data-aos="fade-up" data-aos-duration="1200" >
                    <Link to="/Glasses" className="">Sunglasses</Link>
                    </li>
                    <li data-aos="fade-up" data-aos-duration="1200" >
                    <Link to="/Beauty" className="">Beauty</Link>
                    </li>
                    <li data-aos="fade-up" data-aos-duration="1200" >
                    <Link to="/Footwear" className="">Footwear</Link>
                    </li>
                    </ul>

                
                </div>
                <div>
                
                    <h1 data-aos="fade-right" data-aos-duration="1200" 
                     className="flex font-medium text-xl pb-4 pt-5 md:pt-0">Contact Us</h1>
                    <ul>
                    <li data-aos="fade-left" data-aos-duration="1200" 
                    className="hover:text-backgroundColor transition all cursor-pointer">contact_support@trendz.blog</li>
                    </ul>
                    {icon.map((icon, index) =>(
                        <span  className="flex gap-4 m-6 md:m-0"key={index} >

                    <a data-aos="fade-right"
                  data-aos-duration="1900"
                   href="https://in.pinterest.com/trend_AZ/" ><img src={icon.icon} alt="icon"/></a>
                   <a data-aos="fade-left"
                  data-aos-duration="1900"
                    href="https://instagram.com/treind_z?igshid=NzZlODBkYWE4Ng%3D%3D&utm_source=qr"><img src={icon.icon1} alt="icon2" /> </a>
                    </span> ))}

                </div>

            </div>

           

        </div>
        </>
    );
};
export default footer;


