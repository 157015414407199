const perfumeContent=[

    {

        name:``,
        wear: "Men's Item",
        title:"Bella Vita Luxury Man Perfume Gift Set 4 x 20 ml for Men with KLUB, OUD, CEO, G.O.A.T Perfume | Woody, Citrusy Long Lasting EDP Fragrance Scent",
        saleCategory:'best seller',
        mrpRate:'Rs.849',
        tradeRate:'Rs.549',
        thumbnail:'https://m.media-amazon.com/images/I/611TuNkQopL._SL1500_.jpg',
        brand:'/images/amazon.jpg',
        blink:'https://www.amazon.in/Bella-Vita-Organic-Perfumes-Fragrance/dp/B09231NL5H/ref=zg_bs_g_1374302031_d_sccl_1/258-5499624-4894819?psc=1&tag=inrdeal123-21'
        
    },
    {

        name:``,
        wear: "Women's Item",
        title:"Bella Vita Luxury Date Woman Eau De Parfum Perfume with Pink Pepper, Jasmine & Vanilla|Premium, Long Lasting Floral & Fruity Fragrance for Women, 20ML",
        saleCategory:'best Seller',
        mrpRate:'Rs.399',
        tradeRate:'Rs.175',
        thumbnail:'https://m.media-amazon.com/images/I/51UDGDJMJkL._SL1500_.jpg',
        brand:'/images/amazon.jpg',
        blink:'https://www.amazon.in/Bella-Vita-Luxury-Perfume-Frgarance/dp/B0CC2XFD87/ref=zg_bs_g_1374302031_d_sccl_3/258-5499624-4894819?psc=1&tag=inrdeal123-21'
        
    },
    {

        name:``,
        wear: "Unisex ",
        title:"Bella Vita Luxury White Oud Unisex Eau De Parfum Perfume with Orange,Patchouli,Musk|Premium, Long Lasting Oud & Fruity Fragrance for Men & Women,100ML",
        saleCategory:'Best Seller',
        mrpRate:'Rs.999',
        tradeRate:'Rs.565',
        thumbnail:'https://m.media-amazon.com/images/I/41vndKUPh1L._SL1100_.jpg',
        brand:'/images/amazon.jpg',
        blink:'https://www.amazon.in/Bella-Vita-Organic-Perfume-Fragrance/dp/B08XBVLF8B/ref=zg_bs_g_1374302031_d_sccl_6/258-5499624-4894819?psc=1&tag=inrdeal123-21'
        
    },
    {

        name:``,
        wear: "Men's Item",
        title:"Park Avenue Men Voyage Amazon Woods Liquid Perfume, 120Ml",
        saleCategory:'Best seller',
        mrpRate:'Rs.349',
        tradeRate:'Rs.174',
        thumbnail:'https://www.bigbasket.com/media/uploads/p/l/40272547_4-park-avenue-voyage-amazon-woods-perfume-spray-premium-zero-gas.jpg',
        brand:'/images/amazon.jpg',
        blink:'https://www.amazon.in/Park-Avenue-Voyage-Amazon-Perfume/dp/B084V46KGT/ref=zg_bs_g_1374302031_d_sccl_5/258-5499624-4894819?psc=1&tag=inrdeal123-21'
        

    },
    {

        name:``,
        wear: `Unisex`,
        title:`United Dreams Aim High Eau De Toilette for Unisex`,
        saleCategory:`Most selling`,
        mrpRate:`Rs2,000`,
        tradeRate:`Rs1,300`,
        thumbnail:`https://sslimages.shoppersstop.com/sys-master/images/h5b/h03/16919730749470/201071305_NoColour_alt1.jpg_2000Wx3000H`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8v1Ml1pOTOg1WjIklaVHO7U0NJvSh58jX-w&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.shoppersstop.com%2Funited-colors-of-benetton-united-dreams-aim-high-eau-de-toilette-for-unisex%2Fp-201071305%2FcolorChange%2F201071305_NoColour%3FcurrentPosition%3D5%26searchQueryUrl%3D%26totalResultVal%3D2105%26searchPageType%3Dcategory`
        
    },

    {

        name:``,
        wear: `men's Perfume`,
        title:`Hugo Boss Bottled Eau De Toilette for Men - 30 ml`,
        saleCategory:`new season`,
        mrpRate:`₹3600`,
        tradeRate:`₹2520`,
        thumbnail:`https://ssbimages.ssbeauty.in/pub/media/catalog/product/images/AW1BOS2351001OR/AW1BOS2351001OR_AW1BOS2351001OR_Bottled_Eau_De_Toilette_for_Men_30_ml_3.jpg`,
        brand:`https://cmsimages.ssbeauty.in/ssb_logo_18083e0a02.png`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.ssbeauty.in%2Fhugo-boss-bottled-eau-de-toilette-for-men-30-ml%2Fp%2FAW1BOS2351001OR_base%3FcolorCode%3D9308`
        
    },
    {

        name:``,
        wear: `Body Spray`,
        title:`One Deodorant Spray | 150.0 ml`,
        saleCategory:`Most Selling`,
        mrpRate:`₹1700`,
        tradeRate:`₹1309`,
        thumbnail:`https://assets.ajio.com/medias/sys_master/root/20230712/5cwz/64aecda6a9b42d15c94fb9d4/-1117Wx1400H-4938432490-multi-MODEL.jpg`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrEW8xaACcj4Ze6zFzTXPI9RXD4ORT4Pjmtg&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.ajio.com%2Fcalvin-klein-one-deodorant-spray%2Fp%2F4938432490_multi`
        
    },
    {

        name:``,
        wear: `Body Spray`,
        title:`The Man Company Kopar Body Perfume - 120 ml`,
        saleCategory:`Trending Item`,
        mrpRate:``,
        tradeRate:`₹399`,
        thumbnail:`https://ssbimages.ssbeauty.in/pub/media/catalog/product/images/S21TMC7015215/S21TMC7015215_base.jpg`,
        brand:`https://cmsimages.ssbeauty.in/ssb_logo_18083e0a02.png`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.ssbeauty.in%2Fthe-man-company-kopar-body-perfume-120-ml%2Fp%2FS21TMC7015215_base%3FcolorCode%3D9308%26size%3D871`
        
    },
    {

        name:``,
        wear: `Body Spray`,
        title:`The Man Company Kopar Body Perfume - 120 ml`,
        saleCategory:`Most trend`,
        mrpRate:``,
        tradeRate:`₹299`,
        thumbnail:`https://www.themancompany.com/cdn/shop/products/1_208037d5-b15b-40ee-8318-c4089430a13c_765x.jpg?v=1667801342`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQp8e7S_2TtzgWtOE5JXmSgJaMwkjcjTXlrqg&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.themancompany.com%2Fcollections%2Ffragrance%2Fproducts%2Fbody-spray-limitless-150ml%3F_pos%3D11%26_fid%3D813963375%26_ss%3Dc`
        
    },
    {

        name:``,
        wear: `Toilette`,
        title:`Man Green Eau de Toilette`,
        saleCategory:`Trending`,
        mrpRate:`₹2700`,
        tradeRate:`₹1300`,
        thumbnail:`https://sslimages.shoppersstop.com/B8AC9759D45547D9AEF177F0DE13B7C8/img/082B171F87B340C78B2F242BF2483A32/204231789_9999_alt2_082B171F87B340C78B2F242BF2483A32.jpg`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8v1Ml1pOTOg1WjIklaVHO7U0NJvSh58jX-w&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.shoppersstop.com%2Funited-colors-of-benetton-colors-man-green-eau-de-toilette%2Fp-204231789%2FcolorChange%2F204231789_UNMAPPED%3FcurrentPosition%3D50%26searchQueryUrl%3D%26totalResultVal%3D2105%26searchPageType%3Dcategory`
        
    },
    {

        name:``,
        wear: `Perfume`,
        title:`Elle Body Mist Coco Amour - 250 ml, Earthy & Woody, Warm & Spicy Fragrance`,
        saleCategory:`New Seasons`,
        mrpRate:`₹`,
        tradeRate:`₹1195`,
        thumbnail:`https://ssbimages.ssbeauty.in/pub/media/catalog/product/images/A22ELLE9180210/A22ELLE9180210_base.jpg`,
        brand:`https://cmsimages.ssbeauty.in/ssb_logo_18083e0a02.png`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.ssbeauty.in%2Felle-body-mist-coco-amour-250-ml-earthy-woody-warm-spicy-fragrance%2Fp%2FA22ELLE9180210_base%3FcolorCode%3D9308%26size%3D871`
        
    },
    {

        name:``,
        wear: `Men's Choice`,
        title:`Eau De Parfum | Achiever (100ml)`,
        saleCategory:`New Launches`,
        mrpRate:`₹2999`,
        tradeRate:`₹1,999`,
        thumbnail:`https://www.themancompany.com/cdn/shop/files/Achiever100mlPrimaryimages1_765x.jpg?v=1709119651`,
        brand:`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQp8e7S_2TtzgWtOE5JXmSgJaMwkjcjTXlrqg&s`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.themancompany.com%2Fcollections%2Ffragrance%2Fproducts%2Feau-de-parfum-achiever-100ml%3F_pos%3D7%26_fid%3D36f9445aa%26_ss%3Dc`
        
    },

]

export default perfumeContent;