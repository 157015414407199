const data =[
    {
        title:`Velocity NITRO™ 3 `,
        tradeRate:`₹12,999`,
        feature:`Running Shoe`,
        thumbnail:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/380081/01/sv01/fnd/AUS/fmt/png/Velocity-NITRO%E2%84%A2-3-Women's-Running-Shoes`,
        blink:`https://www.tkqlhce.com/click-101131564-15577250?url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fpd%2Fvelocity-nitro%25E2%2584%25A2-3-womens-running-shoes%2F380081%3Fswatch%3D01`,
    },

    {
        title:`BaseNew W`,
        tradeRate:`₹2279`,
        feature:`Running Shoe`,
        thumbnail:`https://assets.adidas.com/images/h_840,f_auto,q_auto,fl_lossy,c_fill,g_auto/b33a3c1ceef248bcbc61d4252da7f29a_9366/BaseNew_W_Black_IU9526_01_standard.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.adidas.co.in%2Fbasenew-w%2FIU9526.html`,
    },
    {
        title:`CUSHON WALKING`,
        tradeRate:`₹2279`,
        feature:`Walking Shoes`,
        thumbnail:`https://assets.adidas.com/images/h_840,f_auto,q_auto,fl_lossy,c_fill,g_auto/0e39a82a0ff541a492a7a8768370fead_9366/CUSHON_WALKING_SHOES_Pink_IU9509_01_standard.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.adidas.co.in%2Fcushon-walking-shoes%2FIU9509.html`,
    },

    {
        title:`Cush-Walk W`,
        tradeRate:`₹2,399`,
        feature:`Walking Shoes`,
        thumbnail:`https://assets.adidas.com/images/h_840,f_auto,q_auto,fl_lossy,c_fill,g_auto/02bc4e2c19a2494ba9d3ffd8c391ccbd_9366/Cush-Walk_W_Pink_IU9507_02_standard_hover.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.adidas.co.in%2Fcush-walk-w%2FIU9507.html`,
    },
    {
        title:`Smashic`,
        tradeRate:`₹2,699`,
        feature:`Running Shoes`,
        thumbnail:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/395111/01/sv01/fnd/IND/fmt/png/Smashic-Women's-Sneakers`,
        blink:`https://www.dpbolvw.net/click-101131564-15577250?url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fpd%2Fsmashic-womens-sneakers%2F395111%3Fswatch%3D01`,
    },

    {
        title:`Mayze Stack Feelin' Xtra`,
        tradeRate:`₹4,959`,
        feature:`Women's Shoes`,
        thumbnail:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/392062/01/sv01/fnd/IND/fmt/png/Mayze-Stack-Feelin'-Xtra-Women's-Sneakers`,
        blink:`https://www.tkqlhce.com/click-101131564-15577250?url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fpd%2Fmayze-stack-feelin-xtra-womens-sneakers%2F392062%3Fswatch%3D01`,
    },

    {
        title:`Smashic`,
        tradeRate:`₹2,449`,
        feature:`Women's Sneakers`,
        thumbnail:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/395111/04/sv01/fnd/IND/fmt/png/Smashic-Women's-Sneakers`,
        blink:`https://www.dpbolvw.net/click-101131564-15577250?url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fpd%2Fsmashic-womens-sneakers%2F395111%3Fswatch%3D04`,
    },

    {
        title:`Supernova Stride`,
        tradeRate:`₹5,999`,
        feature:`Running Shoes`,
        thumbnail:`https://assets.adidas.com/images/h_840,f_auto,q_auto,fl_lossy,c_fill,g_auto/3660df1c563a47388809a18a5e2bfb60_9366/Supernova_Stride_Running_Shoes_Green_IG8283_HM3_hover.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.adidas.co.in%2Fsupernova-stride-running-shoes%2FIG8283.html%3Fpr%3Dtaxonomy_rr%26slot%3D5%26rec%3Dmt`,
    },

    {
        title:`Samba OG`,
        tradeRate:`₹10,999`,
        feature:`Women's Sneakers`,
        thumbnail:`https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/011744ef273d4a66b9cc880b980340a2_9366/Samba_OG_Shoes_White_ID0478_01_standard.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.adidas.co.in%2Fsamba-og-shoes%2FID0478.html%3Fpr%3Dtaxonomy_rr%26slot%3D4%26rec%3Dmt`,
    },
    {
        title:`GAZELLE BOLD`,
        tradeRate:`₹11,999`,
        feature:`Women's Sneakers`,
        thumbnail:`https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/0faba7ce543d4efbad3695157ef23b55_9366/GAZELLE_BOLD_SHOES_Blue_IE0430_01_standard.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.adidas.co.in%2Fgazelle-bold-shoes%2FIE0430.html%3Fpr%3Dtaxonomy_rr%26slot%3D5%26rec%3Dmt`,
    },

    {
        title:`Cruise Rider Silk Road`,
        tradeRate:`₹5,249`,
        feature:`Women's Sneakers`,
        thumbnail:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/398107/01/sv01/fnd/IND/fmt/png/Cruise-Rider-Silk-Road-Women's-Sneakers`,
        blink:`https://www.anrdoezrs.net/click-101131564-15577250?url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fpd%2Fcruise-rider-silk-road-womens-sneakers%2F398107%3Fswatch%3D01`,
    },

    {
        title:`Samba OG`,
        tradeRate:`₹10,999`,
        feature:`Women's Sneakers`,
        thumbnail:`https://assets.adidas.com/images/h_840,f_auto,q_auto,fl_lossy,c_fill,g_auto/4c70105150234ac4b948a8bf01187e0c_9366/Samba_OG_Shoes_Black_B75807_01_standard.jpg`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.adidas.co.in%2Fsamba-og-shoes%2FB75807.html%3Fpr%3Dtaxonomy_rr%26slot%3D9%26rec%3Dmt`,
    },

    {
        title:`Slipstream Deboss`,
        tradeRate:`₹6,299`,
        feature:`Women's Sneakers`,
        thumbnail:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/393050/01/sv01/fnd/IND/fmt/png/Slipstream-Deboss-Women's-Sneakers`,
        blink:`https://www.anrdoezrs.net/click-101131564-15577250?url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fpd%2Fslipstream-deboss-womens-sneakers%2F393050%3Fswatch%3D01`,
    },

    {
        title:`RS-X Efekt Lux`,
        tradeRate:`₹7,199`,
        feature:`Women's Sneakers`,
        thumbnail:`https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/393771/01/sv01/fnd/IND/fmt/png/RS-X-Efekt-Lux-Women's-Sneakers`,
        blink:`https://www.kqzyfj.com/click-101131564-15577250?url=https%3A%2F%2Fin.puma.com%2Fin%2Fen%2Fpd%2Frs-x-efekt-lux-womens-sneakers%2F393771%3Fswatch%3D01`,
    },
    
]

export default data