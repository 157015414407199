import React from 'react'
import { BsStarFill , BsStarHalf } from 'react-icons/bs'

import Cardcontent from '../content/cardContent'

const productCard = () => {
  return (
    <>
    {Cardcontent.map((article, index)=>(
       <div key={index} className=' w-1/3 2xl:1/6 xl:w-1/5 lg:w-1/4 md:w-1/3'> 

    <div  className='w-auto justify-center p-5 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-lg'>
        <a href={article.blink}>
        <img className='w-full  object-cover border rounded-xl' src={article.img} alt='' />
        <div className='space-y-2'>
            <h3 className='font-semibold text-center text-xl pt-2 '>
            {article.title}
            </h3>
            <div className='flex flex-row justify-center'>
                <BsStarFill className='text-orange-600' />
                <BsStarFill className='text-orange-600' />
                <BsStarFill className='text-orange-600' />
                <BsStarHalf className='text-orange-600'/>

            </div>
            <div className='flex flex-row items-center justify-center'>
                <h3 className='font-bold text-center text-xl pt-2'>{article.rate}</h3>
            </div>
           
        </div>
        </a>
    </div>
    </div>
    ))}


  


</>
    
    
    
  )
}

export default productCard