const data =[
    
        {

        
            name:``,
            wear:`Hot Deal`,
            title:`Buds X Prime`,
            saleCategory:`BestSeller`,
            mrpRate:'₹4,499',
            tradeRate:'₹1399',
            thumbnail:`https://www.gonoise.com/cdn/shop/files/4_83ab5e13-fbce-4071-9630-470b86721e85.webp?v=1720440893`,
            brand:`/images/noise.jpg`,
            blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.gonoise.com%2Fproducts%2Fbuds-x-prime-truly-wireless-earbuds%3Fvariant%3D40256802848855`,
            brand1:`/images/amazon.jpg`,
            blink1:`https://www.amazon.in/Noise-Launched-Wireless-Playtime-Instacharge/dp/B0CFYCDDX1?tag=inrdeal123-21`,
            
    
        },
        {

        
            name:``,
            wear:`Hot Deal`,
            title:`Buds Combat X Gaming`,
            saleCategory:`BestSeller`,
            mrpRate:'₹3,999',
            tradeRate:'₹1,599',
            thumbnail:`https://www.gonoise.com/cdn/shop/files/1.4_ae40c598-2079-42d2-ad46-279f00c7dd66.png?v=1704952456`,
            brand:`/images/noise.jpg`,
            blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.gonoise.com%2Fproducts%2Fnoise-buds-combat-x-truly-wireless-earbuds`,
            brand1:`/images/flipkart.png`,
            blink1:`https://fktr.in/yImJ4Dc`,
            
    
        },

        {

        
            name:``,
            wear:`43% off`,
            title:`Buds Xero Adaptive ANC 50dB, Chrome Finish, 6 Mic Calling`,
            saleCategory:`BestSeller`,
            mrpRate:'₹6,999',
            tradeRate:'₹3,999',
            thumbnail:`https://www.gonoise.com/cdn/shop/files/Beige_b8bace91-1ca1-4182-a043-d58a3a7534c7.png?v=1714033033`,
            brand:`/images/noise.jpg`,
            blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.gonoise.com%2Fproducts%2Fnoise-buds-xero-truly-wireless-earbuds`,
            brand1:`/images/amazon.jpg`,
            blink1:`https://www.amazon.in/Noise-Launched-Wireless-Detection-Algorithm/dp/B0CNVP1G3Z?th=1&tag=inrdeal123-21`,
            
    
        },
        {

        
            name:``,
            wear:`57% off`,
            title:`Pure Pods AirWave Technology, Instacharge, Up To 80 hours of playtime`,
            saleCategory:`BestSeller`,
            mrpRate:'₹6,999',
            tradeRate:'₹2,998',
            thumbnail:`https://www.gonoise.com/cdn/shop/files/4_ee691dda-1aa5-4780-8547-c92bc0929e71.webp?v=1720444316`,
            brand:`/images/amazon.jpg`,
            blink: `https://www.amazon.in/Noise-AirWaveTM-Technology-Neodymium-Detachable/dp/B0CFYJ8HRS?th=1&tag=inrdeal123-21`,
            brand1:`/images/noise.jpg` ,
            blink1:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.gonoise.com%2Fproducts%2Fnoise-pure-pods-open-ear-headphones%3Fvariant%3D40406688890967`
            
    
        },
        {

        
            name:``,
            wear:`60% off`,
            title:`Buds Trance Up to 40 hours of playtime, Low Latency (Up to 40 MS)`,
            saleCategory:`BestSeller`,
            mrpRate:'₹2,499',
            tradeRate:'₹999',
            thumbnail:`https://www.gonoise.com/cdn/shop/files/1.3_6f5658c1-7229-4bdf-8794-06bd9ae59b2b.png?v=1686303983`,
            brand:`/images/flipkart.png`,
            blink: `https://fktr.in/cNWDX0c`,
            brand1:`/images/noise.jpg` ,
            blink1:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.gonoise.com%2Fproducts%2Fnoise-buds-trance-wireless-earbuds%3Fvariant%3D40194237497431`
            
    
        },
    
]

export default data 