import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/NavBar"
import Footer from  "../components/Footer";

const About = () => {
    return (

        <>
          <Navbar />
        

    <Helmet>
        <title>who are We</title>
        <meta name="description" content="choose your specific Product" />
        <meta name="keywords" keywords="explore Product, find your suitable product, Increase your fashion efficiency" />
      </Helmet>
        <div className="mb-20 mx-8 lg:mx-[24rem] md:mx-[10rem] text-headingColor font-[500] text-normal">
        <h1 className="sm:text-4xl text-2xl font-bold my-6 text-center text-orange-400">
            Hello , Welcome to our About </h1>
        <p className="mx-auto leading-relaxed text-base mb-4 tex">
        Trendz isn't just a fashion destination; it's a movement—a celebration of individuality, 
        sustainability, and the power of Gen Z style. Join us on this exciting journey as we redefine fashion for a new era. 
        Visit Trendz today, and let your style shine like never before. After all, fashion is not just about what you wear; 
        it's about how you wear it. Elevate your style with Trendz!
        </p>
        <p className="mx-auto leading-relaxed text-base mb-4">
        In the world of fashion, your style is your voice, and Trendz is here to amplify it. Embrace the latest trends, 
        discover unique pieces, and redefine your fashion journey with us. Visit Trendz today and experience the thrill of fashion at your fingertips. 
        Your style evolution starts here!
        </p>
        <h1 className="sm:text-xl text-2xl font-bold mt-6 text-normal text-black">
        Trend Guides: </h1>
        <p className="mx-auto leading-relaxed text-base mb-4">
        Feeling a bit unsure about the latest fashion trends? Fret not!
         Trendz provides trend guides and styling tips to help you effortlessly incorporate the latest looks into your wardrobe.
        </p>
        <h1 className="sm:text-xl text-2xl font-bold mt-6 text-normal text-black">
        Diverse and Inclusive Collections: </h1>
        <p className="mx-auto leading-relaxed text-base mb-4">
        At Trendz, we celebrate diversity and individuality. Our curated collections reflect a wide range of styles, allowing you 
        to explore and express your unique identity. From streetwear to sustainable fashion, we've got it all.
        </p>
        <h1 className="sm:text-xl text-2xl font-bold mt-6 text-normal text-black">
        Sustainability is Stylish: </h1>
        <p className="mx-auto leading-relaxed text-base mb-4">
        We know that sustainability matters to Gen Z. Trendz is committed to offering eco-friendly and ethically sourced fashion choices. Discover stylish pieces that 
        not only make a statement but also contribute to a better, more sustainable future.
        </p>
        <h1 className="sm:text-xl text-2xl font-bold mt-6 text-normal text-black">
        Empowering Details on Every Product: </h1>
        <p className="mx-auto leading-relaxed text-base mb-4">
        FWe believe that an informed choice is a confident choice. That's why each product on Trendz comes with comprehensive details, including materials,
         sizing guides, and care instructions. Know exactly what you're getting before you make a purchase.
        </p>
        <h1 className="sm:text-xl text-2xl font-bold mt-6 text-normal text-black">
        Seamless Shopping Experience: </h1>
        <p className="mx-auto leading-relaxed text-base mb-4">
        Embrace the future of shopping with Trendz. Our user-friendly interface makes navigation a breeze, and with just a few clicks, you can seamlessly explore our collections,
         read about products, and make a purchase—all from the comfort of your digital realm.
        </p>
        <h1 className="sm:text-xl text-2xl font-bold mt-6 text-normal text-black">
        Express Your Style, Your Way: </h1>
        <p className="mx-auto leading-relaxed text-base mb-4">
        Gen Z is all about breaking stereotypes and embracing individuality. Trendz empowers you to express your style authentically. 
        Mix and match, experiment with colors, and create looks that tell your unique story.
        </p>
        </div>

        <Footer />

        </>

    );

};
export default About;