const data=[


    {
        name:'',
        title:'Tom Ford Oud Minerale Unisex Eau De Parfum - Fresh, Earthy & Woody EDP - 100 ml',
        img:'https://ssbimages.ssbeauty.in/pub/media/catalog/product/images/TF-TCWY01/TF-TCWY01_TF-TCWY01_2.jpg',
        rate:'₹15400',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.ssbeauty.in%2Ftom-ford-oud-minerale-unisex-eau-de-parfum-fresh-earthy-woody-edp-100-ml%2Fp%2FTF-TCWY01_base%3FcolorCode%3D9308%26size%3D871`

    },
    {
        name:'',
        title:`Tom Ford Cafe Rose Eau De Parfum For Women - Floral, Warm & Spicy EDP - 100 ml`,
        img:`https://ssbimages.ssbeauty.in/pub/media/catalog/product/images/TF-TCY601/TF-TCY601_TF-TCY601_2.jpg`,
        rate:'₹15400',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.ssbeauty.in%2Ftom-ford-cafe-rose-eau-de-parfum-for-women-floral-warm-spicy-edp-100-ml%2Fp%2FTF-TCY601_base%3FcolorCode%3D9308%26size%3D871`,
    },
    {
        name:'',
        title:`Tom Ford Grey Vetiver Parfum For Men - Floral, Warm & Spicy EDP - 50 ml`,
        img:`https://ssbimages.ssbeauty.in/pub/media/catalog/product/images/TF-T9Y101/TF-T9Y101_TF-T9Y101_3.jpg`,
        rate:'₹13200',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.ssbeauty.in%2Ftom-ford-grey-vetiver-parfum-for-men-floral-warm-spicy-edp-50-ml%2Fp%2FTF-T9Y101_base%3FcolorCode%3D9308%26size%3D871`,
    },
    {
        name:'',
        title:`Tom Ford Costa Azzurra Unisex Eau De Parfum - Fresh, Earthy & Woody EDP - 100 ml`,
        img:`https://ssbimages.ssbeauty.in/pub/media/catalog/product/images/TF-T9AW01/TF-T9AW01_TF-T9AW01_3.jpg`,
        rate:'₹15400',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.ssbeauty.in%2Ftom-ford-costa-azzurra-unisex-eau-de-parfum-fresh-earthy-woody-edp-100-ml%2Fp%2FTF-T9AW01_base%3FcolorCode%3D9308%26size%3D871`,
    },
    {
        name:'',
        title:`Tom Ford Black Orchid Eau De Parfum For Women - Floral, Earthy & Woody EDP - 100 ml`,
        img:`https://ssbimages.ssbeauty.in/pub/media/catalog/product/images/205638399/205638399_205638399_2.jpg`,
        rate:'₹15400',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.ssbeauty.in%2Ftom-ford-black-orchid-eau-de-parfum-for-women-floral-earthy-woody-edp-100-ml%2Fp%2F205638399_base%3FcolorCode%3D9308%26size%3D871`,
    },
    {
        name:'',
        title:`Tom Ford Noir Extreme Eau De Parfum For Men - Earthy & Woody, Warm & Spicy EDP - 100 ml`,
        img:`https://ssbimages.ssbeauty.in/pub/media/catalog/product/images/205638411/205638411_205638411_2.jpg`,
        rate:'₹15400',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.ssbeauty.in%2Ftom-ford-noir-extreme-eau-de-parfum-for-men-earthy-woody-warm-spicy-edp-100-ml%2Fp%2F205638411_base%3FcolorCode%3D9308%26size%3D871`,
    },
    {
        name:'',
        title:`Tom Ford Ombre Leather Unisex Eau De Parfum - Floral, Warm & Spicy EDP - 100 ml`,
        img:`https://ssbimages.ssbeauty.in/pub/media/catalog/product/images/205638421/205638421_205638421_2.jpg`,
        rate:`₹15400`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.ssbeauty.in%2Ftom-ford-ombre-leather-unisex-eau-de-parfum-floral-warm-spicy-edp-100-ml%2Fp%2F205638377%3FcolorCode%3D9308%26size%3D871`,
    },

    {
        name:'',
        title:`Tom Ford Noir Extreme Eau De Parfum For Men - Earthy & Woody, Warm & Spicy EDP - 100 ml`,
        img:`https://ssbimages.ssbeauty.in/pub/media/catalog/product/images/205638411/205638411_205638411_2.jpg`,
        rate:'₹15400',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.ssbeauty.in%2Ftom-ford-noir-extreme-eau-de-parfum-for-men-earthy-woody-warm-spicy-edp-100-ml%2Fp%2F205638411_base%3FcolorCode%3D9308%26size%3D871`,
    },
   

{
        name:'',
        title:`Viktor & Rolf Good Fortune Eau De Parfum - 90 ml`,
        img:`https://ssbimages.ssbeauty.in/pub/media/catalog/product/images/AW23VIKTLD4707/AW23VIKTLD4707_AW23VIKTLD4707_4.jpg`,
        rate:'₹9350',
        blink:`https://www.ssbeauty.in/viktor-rolf-good-fortune-eau-de-parfum-90-ml/p/AW23VIKTLD4707_base?colorCode=9308&size=871`,
    },


{
        name:'',
        title:`Viktor & Rolf Spicebomb Infrared Eau De Toilette - 90 ml`,
        img:`https://ssbimages.ssbeauty.in/pub/media/catalog/product/images/AW23VIKTLC5007/AW23VIKTLC5007_AW23VIKTLC5007_7.jpg`,
        rate:'₹6928',
        blink:`https://www.ssbeauty.in/viktor-rolf-spicebomb-infrared-eau-de-toilette-90-ml/p/AW23VIKTLC5007_base?colorCode=9308&size=871`,
    },


{
        name:'',
        title:`Mugler Alien Goddess Eau De Parfum Refillable Spray for Women - 90 ml`,
        img:`https://ssbimages.ssbeauty.in/pub/media/catalog/product/images/AW23MUGLLD1794/AW23MUGLLD1794_base.jpg`,
        rate:'₹8500',
        blink:`https://www.ssbeauty.in/mugler-alien-goddess-eau-de-parfum-refillable-spray-for-women-90-ml/p/AW23MUGLLD1794_base?colorCode=9308&size=871`,
    },


{
        name:'',
        title:`Viktor & Rolf Flowerbomb Eau De Parfum - 100 ml`,
        img:`https://ssbimages.ssbeauty.in/pub/media/catalog/product/images/AW23VIKTL40728/AW23VIKTL40728_AW23VIKTL40728_2.jpg`,
        rate:'₹10800',
        blink:`https://www.ssbeauty.in/viktor-rolf-flowerbomb-eau-de-parfum-100-ml/p/AW23VIKTL40728_base?colorCode=9308&size=871`,
    },



{
        name:'',
        title:`Valentino Donna Born In Roma Coral Fantasy Eau De Parfum - 100 ml, Floral, Fruity EDP`,
        img:`https://ssbimages.ssbeauty.in/pub/media/catalog/product/images/AW23VALELD4976/AW23VALELD4976_AW23VALELD4976_4.jpg`,
        rate:'₹10800',
        blink:`https://www.ssbeauty.in/valentino-donna-born-in-roma-coral-fantasy-eau-de-parfum-100-ml-floral-fruity-edp/p/AW23VALELD4976_base?`,
    },



{
        name:'',
        title:`Valentino Uomo Born In Roma Eau De Toilette - 100 ml, Earthy & Woody, Warm & Spicy, Fresh EDT`,
        img:`https://ssbimages.ssbeauty.in/pub/media/catalog/product/images/AW23VALELB0405/AW23VALELB0405_AW23VALELB0405_5.jpg`,
        rate:'₹7200',
        blink:`https://www.ssbeauty.in/valentino-uomo-born-in-roma-eau-de-toilette-100-ml-earthy-woody-warm-spicy-fresh-edt/p/AW23VALELB0405_base?colorCode=9308&size=871`,
    },




{
        name:'',
        title:`Valentino Donna Born In Roma Intense Eau De Parfum For Women - 100 ml, Floral EDP`,
        img:`https://ssbimages.ssbeauty.in/pub/media/catalog/product/images/AW23VALEN790840/AW23VALEN790840_AW23VALEN790840_3.jpg`,
        rate:'₹11880',
        blink:`https://www.ssbeauty.in/valentino-donna-born-in-roma-intense-eau-de-parfum-for-women-100-ml-floral-edp/p/AW23VALEN790840_base?`,
    },



{
        name:'',
        title:`Mugler Alien Eau De Parfum Refillable Spray for Women - 90 ml`,
        img:`https://ssbimages.ssbeauty.in/pub/media/catalog/product/images/AW23MUGLLC6425/AW23MUGLLC6425_AW23MUGLLC6425_2.jpg`,
        rate:'₹9000',
        blink:`https://www.ssbeauty.in/mugler-alien-eau-de-parfum-refillable-spray-for-women-90-ml/p/AW23MUGLLC6425_base?`,
    },



{
        name:'',
        title:`Ralph Lauren Ralph's Club Eau De Parfum for Men - 100 ml`,
        img:`https://ssbimages.ssbeauty.in/pub/media/catalog/product/images/AW23RALPS26802/AW23RALPS26802_AW23RALPS26802_5.jpg`,
        rate:'₹8028',
        blink:`https://www.ssbeauty.in/ralph-lauren-ralphs-club-eau-de-parfum-for-men-100-ml/p/AW23RALPS26802_base?colorCode=9308&size=871`,
    },




{
        name:'',
        title:`Azzaro The Most Wanted Parfum - 100 ml`,
        img:`https://ssbimages.ssbeauty.in/pub/media/catalog/product/images/AW23AZZRLD4308/AW23AZZRLD4308_AW23AZZRLD4308_3.jpg`,
        rate:'₹7790',
        blink:`https://www.ssbeauty.in/azzaro-the-most-wanted-parfum-100-ml/p/AW23AZZRLD4308_base?colorCode=9308&size=871`,
    },




{
        name:'',
        title:`Giorgio Armani Acqua Di Gio Eau De Toilette - 50 ml`,
        img:`https://ssbimages.ssbeauty.in/pub/media/catalog/product/images/AW23ARM058861/AW23ARM058861_AW23ARM058861_4.jpg`,
        rate:'₹4675',
        blink:`https://www.ssbeauty.in/giorgio-armani-acqua-di-gio-eau-de-toilette/p/AW23ARM058861_base?colorCode=9308&size=871`,
    },





{
        name:'',
        title:`Giorgio Armani Acqua Di Gio Eau De Parfum - 125 ml`,
        img:`https://ssbimages.ssbeauty.in/pub/media/catalog/product/images/AW23ARM907521/AW23ARM907521_AW23ARM907521_3.jpg`,
        rate:'₹10070',
        blink:`https://www.ssbeauty.in/giorgio-armani-acqua-di-gio-eau-de-parfum/p/AW23ARM907521_base?colorCode=9308&size=871`,
    },





{
        name:'',
        title:`Ralph Lauren Polo Black Eau De Toilette for Men - 75 ml`,
        img:`https://ssbimages.ssbeauty.in/pub/media/catalog/product/images/AW23RAL7032750/AW23RAL7032750_AW23RAL7032750_5.jpg`,
        rate:'₹4590',
        blink:`https://www.ssbeauty.in/ralph-lauren-polo-black-eau-de-toilette-for-men-75-ml/p/AW23RAL7032750_base?colorCode=9308&size=871`,
    },




{
        name:'',
        title:`Azzaro Pour Homme Eau De Toilette - 50 ml, Fresh, Earthy & Woody EDT`,
        img:`https://ssbimages.ssbeauty.in/pub/media/catalog/product/images/AW23AZZRLC7133/AW23AZZRLC7133_AW23AZZRLC7133_3.jpg`,
        rate:'₹3400',
        blink:`https://www.ssbeauty.in/azzaro-pour-homme-eau-de-toilette-50-ml-fresh-earthy-woody-edt/p/AW23AZZRLC7133_base?colorCode=9308&size=871`,
    },





{
        name:'',
        title:`Azzaro Wanted Eau De Parfum For Men - 50 ml, Floral EDP`,
        img:`https://ssbimages.ssbeauty.in/pub/media/catalog/product/images/AW23AZZAR905428/AW23AZZAR905428_AW23AZZAR905428_3.jpg`,
        rate:'₹4845',
        blink:`https://www.ssbeauty.in/azzaro-wanted-eau-de-parfum-for-men-50-ml-floral-edp/p/AW23AZZAR905428_base?colorCode=9308&size=871`,
    },





{
        name:'',
        title:`Viktor & Rolf Bonbon Eau De Parfum - 50 ml`,
        img:`https://ssbimages.ssbeauty.in/pub/media/catalog/product/images/AW23VIKTL47219/AW23VIKTL47219_AW23VIKTL47219_3.jpg`,
        rate:'₹6120',
        blink:`https://www.ssbeauty.in/viktor-rolf-bonbon-eau-de-parfum-50-ml/p/AW23VIKTL47219_base?colorCode=9308&size=871`,
    },
















4





]
export default data;