import React from 'react';
import { Image, Container, Title, Button, Group, Text, List, ThemeIcon, rem, MantineProvider } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';

import classes from './mantineCss/HeroBullets.module.css';

const HeroBullets =({data})=> {
  return (
    
    <MantineProvider>
      {data.map((product, index) =>(
    <Container size="sm">
      <div key={index} className={classes.inner}>
        <div className={classes.content}>
          <Title className={classes.title}>
             <span className={classes.highlight} >{product.brand}</span> {product.title}
          </Title>
          <Text c="dimmed" mt="sm">{product.paragraph}
          
          </Text>

          <List
            
            spacing="sm"
            size="sm"
            icon={
              <ThemeIcon size={20} radius="xl" color='orange'>
                <IconCheck style={{ width: rem(12), height: rem(12) }}  stroke={1.5} />
              </ThemeIcon>
            }
          >
            <div className='ml-10'>
            <Image src={product.thumbnail} className={classes.image} />
            </div>
            <List.Item>
              <b>{product.H1}</b> – {product.P1}
            </List.Item>
            <List.Item>
              <b>{product.H2}</b> – {product.P2}
            </List.Item>
            <List.Item>
              <b>{product.H3}</b> – {product.P3}
            </List.Item>
            <List.Item>
              <b>{product.H4}</b> – {product.P4}
            </List.Item>
          </List>
          

          <Group mt={30}>
          <Text fz="xl" fw={700} style={{ lineHeight: 1 }}>
              {product.tradeRate}
            </Text>
            <Text fz="sm" c="dimmed" fw={500} style={{ lineHeight: 1 }} mt={3}>
              <del>{product.mrpRate}</del>
            </Text>
            <Button radius="xl" color='orange' size="md" className={classes.control}>
              <a href={product.blink}>Buy Now</a>
            </Button>
            
          </Group>
        </div>
          
      </div>
      
    </Container>
    ))}
    </MantineProvider>
    
  );
}

export default HeroBullets;