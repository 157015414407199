const data =[

    {
        img:'https://www.dotandkey.com/cdn/shop/files/vit_c_range_bbd238d9-2b7f-4644-8519-67f0b600070f.jpg?v=1714027018',
        link:'',

    },

    {
        img:'https://www.dotandkey.com/cdn/shop/files/DESK_1708329013_copy.jpg?v=1713857617',
        link:'',

    },

    {
        img:'https://www.dotandkey.com/cdn/shop/files/DESK1706161615.jpg?v=1711437327',
        link:'',

    },
    {
        img:'https://jaqulineusa.com/cdn/shop/files/1Rs-1920x650-02_1920x.jpg?v=1715326041',
        link:'',

    },

    {
        img:'https://jaqulineusa.com/cdn/shop/files/1920x650_80_1920x.jpg?v=1714722706',
        link:'',

    },

    {
        img:'https://img.forestessentialsindia.com/pub/media/mageplaza/bannerslider/banner/image/d/e/desktop_banner_without_cta_2.jpg',
        link:'',

    },
    {
        img:'https://www.ssbeauty.in/_next/image?url=https%3A%2F%2Fcmsimages.ssbeauty.in%2FMaybelline_and_L_Oreal_Desktop_2x_61a5178640.jpg&w=1920&q=75',
        link:'',

    },
    {
        img:'https://www.arata.in/cdn/shop/files/ACC_banner_desktop.jpg?v=1706892057&width=1600',
        link:'',

    },






]

export default data ;