import React from 'react'
import { useRef } from 'react';
import Autoscroll from 'embla-carousel-auto-scroll';
import { Carousel } from '@mantine/carousel';
import { MantineProvider } from '@mantine/core';
import Cardcontent from '../content/cardContent'
import {BsStarFill, BsStarHalf} from 'react-icons/bs'
import {GoArrowRight} from 'react-icons/go'

 

const TrendingProducts = () => {
    const autoscroll = useRef(Autoscroll({ delay: 2000 }));
  return (
    <>
      <MantineProvider withGlobalStyles withNormalizeCSS>
    

    <Carousel className=''
     mx="auto"
     sx={{ maxWidth: 320 }}
 withIndicators
 height={550}
 slideSize="20%"
 slideGap="xs"
 loop
 align="start"
 slidesToScroll={1}
 plugins={[autoscroll.current]}
                onMouseEnter={autoscroll.current.stop}
                onMouseLeave={autoscroll.current.reset}
                >

    {Cardcontent.map((article, index)=>(
        <Carousel.Slide key={index} className='-16 w-1/3 2xl:1/6 px-5 xl:w-1/5 lg:w-1/4 md:w-1/3'> 
     <div  className=' p-5 w-auto justify-center border  hover:bg-orange-200  shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-lg'>
         <img className='w-full  object-cover border rounded-xl ' src={article.img} alt='' />
         <div className='space-y-2'>
             <h3 className='font-semibold text-center text-sm lg:text-lg pt-2 '>
             {article.title}
             </h3>
             
             <div className='flex flex-row justify-center'>
                 <BsStarFill className='text-orange-600' />
                 <BsStarFill className='text-orange-600' />
                 <BsStarFill className='text-orange-600' />
                 <BsStarHalf className='text-orange-600'/>
 
             </div>
                              
             <h4 className='text-center'>{article.category}</h4>
             <div className='flex flex-col items-center justify-center '>
                 <h3 className='font-bold text-center text-xl pt-2'>{article.rate}</h3>
                 
             </div>
             <div className="flex lg:mt-5 gap-1 lg:gap-3 justify-center items-center rounded-lg  border bg-red-600 rounde-xl">
                                <img className='w-1/4 p-2   rounded-lg  ' src={article.logo} alt='' />
                                    <a href={article.blink} className='flex-inline inline-flex  font-semibold hover:text-orange-500 text-white  text-sm'>Buy Now</a>
                                    <a href={article.blink} className=" hover:border-2px hover:bg-orange-500 ml-4 rounded-xl  text-white  items-center" placeholder='read more' to={`/article/${article.name}`}>
                                    <GoArrowRight className='text-3xl ' /></a>
                                </div>

             
             
         </div>
         

     </div>
     
     </Carousel.Slide>

     
     ))}

</Carousel>

</MantineProvider>

 </>
  )
}

export default TrendingProducts