
const mockdata =[ 
        
      {
        image:'https://m.media-amazon.com/images/I/61v3W3K5rsL._SX679_.jpg',
        title: `Travel Trolly`,
        country: 'Kamiliant',
        description:`American Tourister Harrier 3 Pc Set 56 cms, 68 cms & 78 cms- Small, Medium & Large Polypropylene (PP) Hard Sided 4 Wheels Spinner`,
        mrprate:`₹29,250`,
        traderate:`₹4,999`,
        rating:`4.6`,
        keyword:`Travel, Luxury Trolly set, Heavy Discount, `,
        discount:`83%OFF`,
        blink:`https://www.amazon.in/dp/B0BL11QGDX?th=1&tag=inrdeal123-21`,

    
     },
     {
        image:`https://rukminim2.flixcart.com/image/850/1000/xif0q/mixer-grinder-juicer/a/d/m/-original-imagrzqyq6zvckgf.jpeg?q=90&crop=false`,
        title: `Flipkart Deal`,
        country: 'Sansui',
        description:`Sansui Allure Plus 500 W Juicer Mixer Grinder (3 Jars, Black, Blue)#JustHere`,
        mrprate:`₹3,599`,
        traderate:`₹1,399`,
        rating:`4.1Raating`,
        keyword:`Mixer, Juicer mixer, Limited time Deal`,
        discount:`61%`,
        blink:`https://fktr.in/qR1u99K`,


   },
   {
      image:`https://cdn.shopclues.com/images1/thumbnails/117697/320/320/153538259-117697692-1719231667.jpg`,
      title: `Stanless Steel With Temp. Display`,
      country: 'bottle',
      description:`Smart Vacuum Stainless Steel Insulated Water Water Bottle With Temprature Display 500ml (Black)`,
      mrprate:`₹699`,
      traderate:`₹139`,
      rating:`3.6`,
      keyword:`Water Bottle, Temperature display in bottle`,
      discount:`80%`,
      blink:`https://extp.in/GKXAD4`,


 },
 {
   image:`https://m.media-amazon.com/images/I/410qOrsMhtL._SL1100_.jpg`,
   title: `Hair Dryer High customer Review`,
   country: 'Hair Accessories',
   description:`Beurer HC25 Travel Hair Dryer 3 years Warranty 1600 Watts, Black`,
   mrprate:`₹2,690`,
   traderate:`₹849`,
   rating:`4.4`,
   keyword:`Hair Dryer, Beurer hair Dryer 3 year Warrenty`,
   discount:`68%`,
   blink:`https://www.amazon.in/dp/B01M4FRIJM?tag=inrdeal123-21&th=1`,


},
{
   image:`https://m.media-amazon.com/images/I/61SHkCsRUtL._SX679_.jpg`,
   title: `Zebronic Budget Friendly`,
   country: 'HeadPhone',
   description:`ZEBRONICS DUKE 2 Wireless Headphone, Supports Bluetooth, Dual Pairing, Deep Bass, up to 60h Battery Backup, AUX, Environmental Noise Cancellation, Gaming Mode, Now with Type C Charging (Black)`,
   mrprate:`₹2,190`,
   traderate:`₹999`,
   rating:`3.9`,
   keyword:`Budget Headphone, Upto 60h Battery Backup, Wireless Headphone` ,
   discount:`55%`,
   blink:`https://www.amazon.in/dp/B0BC9QY5KH?tag=inrdeal123-21`,


},
{
   image:`https://rukminim2.flixcart.com/image/850/1000/xif0q/watch/z/p/c/-original-imagpzjfgmbpmscn.jpeg?q=20&crop=false`,
   title: `Fossil: Watch`,
   country: 'Special Price',
   description:`Briggs Analog Watch - For Men CH2927I#JustHere`,
   mrprate:`₹9,995`,
   traderate:`₹3,512`,
   rating:`4.4`,
   keyword:`Fossil analog Watch, Classy Watch, Luxe Watch` ,
   discount:`64%`,
   blink:`https://fktr.in/dHK8fqt`,


},
  
{
   image:`https://m.media-amazon.com/images/I/71W3cJJh1nL._SX679_.jpg`,
   title: `INR15 COUPON`,
   country: 'ToothBrush',
   description:`beatXP Buzz Electric Toothbrush for Adults with 2 Brush Heads & 3 Cleaning Modes|Rechargeable Electric Toothbrush with 2 Minute Timer & Quadpacer|19000`,
   mrprate:`₹999`,
   traderate:`₹299`,
   rating:`4.0`,
   keyword:`Electric Toothbrush, beatXp Electric toothBrush` ,
   discount:`70%`,
   blink:`https://www.amazon.in/dp/B0CG3J9618?tag=inrdeal123-21`,


},
{
   image:`https://m.media-amazon.com/images/I/51UiGek6VHL._SX679_.jpg`,
   title: `100+ Cusomize Face`,
   country: 'SmartWatch',
   description:`SYSKA SW200 Smart Watch 100+ Cloud & Customizable Watch Faces | Smart Notifications for Calls, SMS, Whatsapp|Battery Runtime-Upto 10 Days (Ocean Green)`,
   mrprate:`₹5,499`,
   traderate:`₹999`,
   rating:`3.7`,
   keyword:`Syska Smart Watch, SmartWatch under 1000` ,
   discount:`82%`,
   blink:`https://www.amazon.in/dp/B09RMV9R2M?th=1&tag=inrdeal123-21`,


},

]


      export default mockdata