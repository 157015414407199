import { useRef } from 'react';
import Autoscroll from 'embla-carousel-auto-scroll';
import { Carousel } from '@mantine/carousel';
import { MantineProvider } from '@mantine/core';



const PerfumeCarousel = ({data}) => {
    const autoscroll = useRef(Autoscroll({ delay: 2000 }));
  return (
    <>
    
    <MantineProvider withGlobalStyles withNormalizeCSS>
    

    <Carousel className='   '
     mx="auto"
     sx={{ maxWidth:110 }}
 withIndicators
 height={430}
 slideSize="20%"
 slideGap="xs"
 loop
 align="start"
 slidesToScroll={1}
 plugins={[autoscroll.current]}
                onMouseEnter={autoscroll.current.stop}
                onMouseLeave={autoscroll.current.reset}

>
{data.map((card, index)=>(  
       <Carousel.Slide key={index} className='mx-3'> 
       <div className='flex  items-center  justify-center bg-white'>
         
         <div key={index} className='grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
         
        
         <a href={card.blink}>
           <div   className='group relative item-center justify-center overflow-hidden cursor-pointer hover:shadow-xl hover:shadow-black/30 transition-shadow'>
           
              <div  className='2xl:h-96 h-72 w-60 2xl:w-full lg:w-72 lg:h-84 '>
                
             <img src={card.img}
               alt='bnner' className='h-full w-full object-cover group-hover:rotate-1 group-hover:scale-125 transition-transform duration-500'/>
               
              </div>
             
              
           
              <div className='absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:via-black/60 to group-hover:to-black/70'>
              </div>
              <div className='absolute inset-0 flex flex-col items-center justify-center px-9 text-center translate-y-[60%] group-hover:translate-y-0 transition-all' >
                
               
              
              
              </div>
              
            </div>
            </a>
        
            
          
          </div>
        </div>
          
       
       </Carousel.Slide>

))}
  
 
 
  

  
 
 {/* ...other slides */}
</Carousel>

    </MantineProvider>
 




</>
  )
}

export default PerfumeCarousel