const aProductContent = [

    {
        name:``,
        img:`https://image01.realme.net/general/20220725/1658729857583.png.webp`,
        title:`realme Buds Air 3 Neo`,
        category:`Trending`,
        rate:`₹1,999`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fbuy.realme.com%2Fin%2Fgoods%2F563`


    },
    {
        name:``,
        img:`https://www.boat-lifestyle.com/cdn/shop/files/Artboard1_91be9b11-be2a-4223-880a-25eb97164cef_600x.png?v=1704781311`,
        title:`Boat Immortal 201`,
        category:`Game mode`,
        rate:`₹1,199`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.boat-lifestyle.com%2Fproducts%2Fimmortal-201-gaming-earbuds`


    },
    {
        name:``,
        img:`https://www.boultaudio.com/cdn/shop/files/Group12526.png?v=1710398696&width=800`,
        title:`Boult Z40 Pro`,
        category:`Effective price`,
        rate:`₹1,599`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.boultaudio.com%2Fproducts%2Fz40-pro%3Fvariant%3D40640600703045`


    },
    {
        name:``,
        img:`https://www.boat-lifestyle.com/cdn/shop/files/Doodle_40b899c0-9a82-4efc-979a-32b84a6824af_1300x.png?v=1713766323`,
        title:`Nirvana ION ANC Limited Edition`,
        category:`Limited Edition`,
        rate:`₹3,499`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.boat-lifestyle.com%2Fproducts%2Fnirvana-ion-anc-special-edition-earbuds`


    },
    {
        name:``,
        img:`https://image01.realme.net/general/20201209/1607492060139.jpg.webp`,
        title:`realme Buds Air Pro`,
        category:`Transparency mode`,
        rate:`₹4,999`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fbuy.realme.com%2Fin%2Fgoods%2F268`


    },
    {
        name:``,
        img:`https://www.boat-lifestyle.com/cdn/shop/files/Artboard4_16_1000x.jpg?v=1686138295`,
        title:`Boat Airdopes Sonik`,
        category:`Durable Quality`,
        rate:`₹2,000`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.boat-lifestyle.com%2Fproducts%2Fairdopes-sonik-long-playback-earbuds`


    },
    {
        name:``,
        img:`https://image01.realme.net/general/20220321/1647863780293.png.webp`,
        title:`realme Buds Air 3`,
        category:`luxe look`,
        rate:`₹3,999`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fbuy.realme.com%2Fin%2Fgoods%2F485`


    },
    {
        name:``,
        img:`https://image01.realme.net/general/20240318/1710754872593ef198d64c41d486cab3be1a0589e6c84.png.webp?width=1440&height=1440&size=258631`,
        title:`realme Buds T300`,
        category:`budget choice`,
        rate:`₹2,149`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fbuy.realme.com%2Fin%2Fgoods%2F679`


    },
    {
        name:``,
        img:`https://hammeronline.in/cdn/shop/files/BestHammerScreenTWSEarbuds.webp?v=1713940395&width=1800`,
        title:`Hammer Screen TWS with ANC`,
        category:`Case Display`,
        rate:`₹2,999`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fhammeronline.in%2Fcollections%2Ftruly-wireless-earbuds%2Fproducts%2Fhammer-screen-tws-bluetooth-earbuds-with-anc-and-on-case-display`


    },
    {
        name:``,
        img:`https://hammeronline.in/cdn/shop/files/BestBluetoothHammerEarbuds.webp?v=1711956710&width=1800`,
        title:`Hammer Stellar ENC with Quad Mics`,
        category:`Look Cute`,
        rate:`₹1,599`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fhammeronline.in%2Fcollections%2Ftruly-wireless-earbuds%2Fproducts%2Fhammer-stellar-tws-bluetooth-earbuds-with-quad-mics`


    },
    {
        name:``,
        img:`https://hammeronline.in/cdn/shop/files/HammerSolitudeBluetoothEarbuds.webp?v=1700478512&width=1800`,
        title:`Hammer Solitude TWS ENC`,
        category:`Touch Control`,
        rate:`₹4,499`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fhammeronline.in%2Fcollections%2Ftruly-wireless-earbuds%2Fproducts%2Fhammer-solitude-tws-bluetooth-earbuds-with-bluetooth-v5-3-enc-and-smart-touch-controls`


    },
    {
        name:``,
        img:`https://image01.realme.net/general/20240521/1716294410738cf18fb5a9b0d49c48ced11eb2fe11f8c.png.webp?width=1440&height=1440&size=346586`,
        title:`realme Buds Air 6`,
        category:`Dynamic bass boost`,
        rate:`₹3,299`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fbuy.realme.com%2Fin%2Fgoods%2F705`


    },
    {
        name:``,
        img:`https://www.gonoise.com/cdn/shop/products/4_58dcaaa9-ff0a-420a-9539-5b62242fa581.png?v=1627585368`,
        title:`Noise Air Buds+ `,
        category:`Dedicated Calling Mics`,
        rate:`₹1,399`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.gonoise.com%2Fproducts%2Fnoise-air-buds-plus-truly-wireless-earbuds`


    },
    {
        name:``,
        img:`https://www.gonoise.com/cdn/shop/files/cherry.png?v=1712645347`,
        title:`Noise Buds VS102 Plus`,
        category:`70h Playtime`,
        rate:`₹1,299`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.gonoise.com%2Fproducts%2Fnoise-buds-vs102-plus`


    },
    {
        name:``,
        img:`https://www.gonoise.com/cdn/shop/products/Frame20_9881146b-ff21-4de0-9afb-1e017d7171ea.png?v=1705293690`,
        title:`Noise VS102 Pro With ANC`,
        category:`70h Playtime`,
        rate:`₹1,799`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.gonoise.com%2Fproducts%2Fnoise-buds-vs102-pro-truly-wireless-earbuds`


    },
    {
        name:``,
        img:`https://www.gonoise.com/cdn/shop/files/grey-min.png?v=1712643045`,
        title:`Noise Buds X Prime`,
        category:`120h Playtime`,
        rate:`₹1,799`,
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.gonoise.com%2Fproducts%2Fbuds-x-prime-truly-wireless-earbuds`


    },
]
 
export default aProductContent;