const data= [

    {

        name:``,
        wear: `footwear`,
        title:`Jewella Women's Black Dress Sandals`,
        saleCategory:`ladies footwear`,
        mrpRate:`₹10999`,
        tradeRate:`₹5499`,
        thumbnail:`https://www.aldoshoes.in/on/demandware.static/-/Sites-aldo_master_catalog/default/dwe649ef55/large/jewella001043_1.jpg`,
        brand:'https://inrdeals.sgp1.cdn.digitaloceanspaces.com/production/397327/download-%2883%29.png',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.aldoshoes.in%2Fwomen%2Fwomen-footwear%2Fheels%2Fjewella001043%2FJEWELLA001043.html%3Findex%3D1.0`
        
    },

    {

        name:``,
        wear: `footwear`,
        title:`Sanne Women's Brown Block heel Sandals`,
        saleCategory:`ladies footwear`,
        mrpRate:`₹10999`,
        tradeRate:`₹6599`,
        thumbnail:`https://www.aldoshoes.in/on/demandware.static/-/Sites-aldo_master_catalog/default/dwee15275d/large/sanne250007_1.jpg`,
        brand:'https://inrdeals.sgp1.cdn.digitaloceanspaces.com/production/397327/download-%2883%29.png',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.aldoshoes.in%2Fwomen%2Fwomen-footwear%2Fheels%2Fsanne250007%2FSANNE250007.html%3Findex%3D27.0`
        
    },
    {

        name:``,
        wear: `footwear`,
        title:`Cassedyna Women's Pink Pumps`,
        saleCategory:`ladies footwear`,
        mrpRate:`₹15999`,
        tradeRate:`₹7999`,
        thumbnail:`https://www.aldoshoes.in/on/demandware.static/-/Sites-aldo_master_catalog/default/dw6b413aa3/New%20Folder/CASSEDYNA670033.jpg`,
        brand:'https://inrdeals.sgp1.cdn.digitaloceanspaces.com/production/397327/download-%2883%29.png',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.aldoshoes.in%2Fwomen%2Fwomen-footwear%2Fheels%2Fcassedyna670033%2FCASSEDYNA670033.html%3Findex%3D1.0`
        
    },
    {

        name:``,
        wear: `footwear`,
        title:`Kat Women's Red Dress Sandals`,
        saleCategory:`ladies footwear`,
        mrpRate:`₹16999`,
        tradeRate:`₹10199`,
        thumbnail:`https://www.aldoshoes.in/on/demandware.static/-/Sites-aldo_master_catalog/default/dwbd5d17f7/large/kat640033_1.jpg`,
        brand:'https://inrdeals.sgp1.cdn.digitaloceanspaces.com/production/397327/download-%2883%29.png',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.aldoshoes.in%2Fwomen%2Fwomen-footwear%2Fheels%2Fkat640033%2FKAT640033.html%3Findex%3D54.0`
        
    },
    {

        name:``,
        wear: `footwear`,
        title:`Mirale Women's Miscellaneous Block heel Sandals`,
        saleCategory:`ladies footwear`,
        mrpRate:`₹14999`,
        tradeRate:`₹14999`,
        thumbnail:`https://www.aldoshoes.in/on/demandware.static/-/Sites-aldo_master_catalog/default/dwadbe2b9d/New%20Folder/15_4800-x-4800pix.jpg`,
        brand:'https://inrdeals.sgp1.cdn.digitaloceanspaces.com/production/397327/download-%2883%29.png',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.aldoshoes.in%2Fwomen%2Fwomen-footwear%2Fheels%2Fmirale741051%2FMIRALE741051.html%3Findex%3DNaN`
        
    },
    {

        name:``,
        wear: `footwear`,
        title:`Pepela Women's White Dress Sandals`,
        saleCategory:`ladies footwear`,
        mrpRate:`₹14999`,
        tradeRate:`₹14999`,
        thumbnail:`https://www.aldoshoes.in/on/demandware.static/-/Sites-aldo_master_catalog/default/dw920f5cfd/New%20Folder/19_4800-x-4800pix.jpg`,
        brand:'https://inrdeals.sgp1.cdn.digitaloceanspaces.com/production/397327/download-%2883%29.png',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.aldoshoes.in%2Fwomen%2Fwomen-footwear%2Fheels%2Fpepela100011%2FPEPELA100011.html%3Findex%3DNaN`
        
    },
    {

        name:``,
        wear: `footwear`,
        title:`Elenaa Women's White Flat Sandals`,
        saleCategory:`ladies sandal`,
        mrpRate:`₹8999`,
        tradeRate:`₹4499`,
        thumbnail:`https://www.aldoshoes.in/on/demandware.static/-/Sites-aldo_master_catalog/default/dw79cffb70/large/elenaa121043_1.jpg`,
        brand:'https://inrdeals.sgp1.cdn.digitaloceanspaces.com/production/394173/foxtable-logo.png',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.aldoshoes.in%2Fwomen%2Fwomen-footwear%2Fflats%2Felenaa121043%2FELENAA121043.html%3Findex%3D7.0`
        
    },
    {

        name:``,
        wear: `footwear`,
        title:`Tiffania Women's Pink Dress Sandals`,
        saleCategory:`ladies sandal`,
        mrpRate:`₹19999`,
        tradeRate:`₹9999`,
        thumbnail:`https://www.aldoshoes.in/on/demandware.static/-/Sites-aldo_master_catalog/default/dweeca7195/large/tiffania650011_1.jpg`,
        brand:'https://inrdeals.sgp1.cdn.digitaloceanspaces.com/production/394173/foxtable-logo.png',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.aldoshoes.in%2Fwomen%2Fwomen-footwear%2Fheels%2Ftiffania650011%2FTIFFANIA650011.html%3Findex%3D45.0`
        
    },
    {

        name:``,
        wear: `footwear`,
        title:`Thelma Women's Blue Dress Sandals`,
        saleCategory:`ladies sandal`,
        mrpRate:`₹11999`,
        tradeRate:`₹7199`,
        thumbnail:`https://www.aldoshoes.in/on/demandware.static/-/Sites-aldo_master_catalog/default/dw9a81071c/large/thelma460043_1.jpg`,
        brand:'https://inrdeals.sgp1.cdn.digitaloceanspaces.com/production/394173/foxtable-logo.png',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.aldoshoes.in%2Fwomen%2Fwomen-footwear%2Fheels%2Fthelma460043%2FTHELMA460043.html%3Findex%3D70.0`
        
    },
    {

        name:``,
        wear: `footwear`,
        title:`Atlanticus Women's White Block heel Sandals`,
        saleCategory:`ladies sandal`,
        mrpRate:`₹9999`,
        tradeRate:`₹9999`,
        thumbnail:`https://www.aldoshoes.in/on/demandware.static/-/Sites-aldo_master_catalog/default/dwe5842a21/large/atlanticus121043_1.jpg`,
        brand:'https://inrdeals.sgp1.cdn.digitaloceanspaces.com/production/394173/foxtable-logo.png',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.aldoshoes.in%2Fwomen%2Fwomen-footwear%2Fheels%2Fatlanticus121043%2FATLANTICUS121043.html%3Findex%3D29.0`
        
    },
    {

        name:``,
        wear: `footwear`,
        title:`Pristine Women's Beige Block heel Sandals`,
        saleCategory:`ladies sandal`,
        mrpRate:`₹11999`,
        tradeRate:`₹7199`,
        thumbnail:`https://www.aldoshoes.in/on/demandware.static/-/Sites-aldo_master_catalog/default/dwf3f83e22/large/pristine251043_1.jpg`,
        brand:'https://inrdeals.sgp1.cdn.digitaloceanspaces.com/production/394173/foxtable-logo.png',
        blink:`https://inr.deals/track?id=abh673355540&src=backend&url=https%3A%2F%2Fwww.aldoshoes.in%2Fwomen%2Fwomen-footwear%2Fheels%2Fpristine251043%2FPRISTINE251043.html%3Findex%3D18.0`
        
    },









]

export default data ;