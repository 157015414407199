const product =[

    {
        
        name: 'NOEMI KHAKI CARGO',
        href: `https://www.snitch.co.in/products/noemi-khaki-cargo-pant?ref=3wSXfTh7wAmkpb&variant=44650312204450`,
        imageSrc: 'https://www.snitch.co.in/cdn/shop/files/9adc487f06922290e03867e553cdfacc.webp?v=1718778798&width=1800',
        imageAlt: "Front of men's cargo Pant in Khaki color.",
        price: 'INR 1,499',
        color: 'Khaki Tan',
    },
    {
        
        name: 'TORBJORN',
        href: 'https://www.snitch.co.in/products/torbjorn-navy-plain-shirts?ref=3wSXfTh7wAmkpb&variant=44695032365218',
        imageSrc: 'https://www.snitch.co.in/cdn/shop/files/8e4c9203b04c929140503fa61782dce2.webp?v=1719509660&width=1800',
        imageAlt: "TORBJORN NAVY PLAIN SHIRTS.",
        price: 'INR 999',
        color: 'Navy Blue',
        
    },
    {
        
        name: 'STREETCARGO BROWN',
        href: `https://www.snitch.co.in/products/streetcargo-brown-cargo-pant?ref=3wSXfTh7wAmkpb&variant=43797409398946`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4MS04533-03-3215.webp?v=1698687799&width=1800`,
        imageAlt: "Front of men's brown cargo pant",
        price: `INR 1,699`,
        color: `BROWN`,
    },
    {
        
        name: `BERIT PINK TEXTURED`,
        href: 'https://www.snitch.co.in/products/berit-pink-textured-linen-shirts?ref=3wSXfTh7wAmkpb&variant=44694957523106',
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/b4f6859958ef24c485f5063afddf43c0.webp?v=1719548194&width=1800`,
        imageAlt: "Front of men's Basic Textured shirt in Pink.",
        price: 'INR 1,199',
        color: 'PINK TEXTURED',
    },

    {
        
        name: `BERIT BLACK TEXTURED`,
        href: `https://www.snitch.co.in/products/berit-black-textured-linen-shirts?ref=3wSXfTh7wAmkpb&variant=44694969778338`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/3332587e01ca3a6bbf877e23fe7f483e.webp?v=1719548110&width=1800`,
        imageAlt: "Front of men's textured Shirt in black.",
        price: 'INR 1,199',
        color: 'Black TEXTURED',
    },

    {
        
        name: `TRENDTACTICAL SLIM CREAM `,
        href: `https://www.snitch.co.in/products/trendtactical-slim-cream-cargo?ref=3wSXfTh7wAmkpb&variant=44489389637794`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4MSO4562-06-3215.jpg?v=1715679705&width=1800`,
        imageAlt: "Front of men's Cargo pant in Peach.",
        price: 'INR 1,599',
        color: 'CREAM',
    },

    {
        
        name: `INDIGO PLAIN SLIM FIT`,
        href: `https://www.snitch.co.in/products/pascaline-indigo-plain-slim-fit-jeans?ref=3wSXfTh7wAmkpb&variant=44679161479330`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4aa085ce27d9a0090496dab0259dfdbc_be8f36ae-f85c-4194-882b-121dbf07ac49.webp?v=1719259449&width=1800`,
        imageAlt: "Front of men's Jeans in indigo",
        price: 'INR 1,699',
        color: `INDIGO`,
    },
    {
        
        name: `LIGHT GREEN TEXTURED POLO`,
        href: `https://www.snitch.co.in/products/gael-light-green-textured-polo-t-shirts?ref=3wSXfTh7wAmkpb&variant=44661850177698`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4ebd59f452f158ca70db58ce9142b77b_6e8ee9a0-be88-427b-ace2-b0035b5e2348.webp?v=1718970969&width=1800`,
        imageAlt: "Front of men's Polo tee in light green",
        price: 'INR 999',
        color: `GREEN`,
    },
    {
        
        name: `NORDIC BEIGE POLO`,
        href: `https://www.snitch.co.in/products/nordic-beige-polo-t-shirt?ref=3wSXfTh7wAmkpb&variant=43737078169762`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4MST2180-03-M21.jpg?v=1696841179&width=1800`,
        imageAlt: "Front of men's Polo tee in beige",
        price: 'INR 1,299',
        color: `BEIGE`,
    },
    
    {
        
        name: `LIVIA SKINNY FIT`,
        href: `https://www.snitch.co.in/products/livia-blue-skinny-fit-jeans?ref=3wSXfTh7wAmkpb&variant=44650371219618`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4MSD3729-04-3239.jpg?v=1718793381&width=1800`,
        imageAlt: "Front of men's blue skinny fit jeans",
        price: 'INR 1,699',
        color: `BLUE`,
    },
    {
        
        name: `NADIA PLAIN POLO`,
        href: `https://www.snitch.co.in/products/nadia-black-plain-polo-t-shirt?ref=3wSXfTh7wAmkpb&variant=44650371645602`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/4MST2379-04-M22.jpg?v=1719491708&width=1800`,
        imageAlt: "Front of men's polo tee in black",
        price: 'INR 1,199',
        color: `BLACK`,
    },
    {
        
        name: `NOEMI CARGO`,
        href: `https://www.snitch.co.in/products/noemi-navy-cargo-pant?ref=3wSXfTh7wAmkpb`,
        imageSrc: `https://www.snitch.co.in/cdn/shop/files/57545fdafe5c247282445c8ae989dcd8.webp?v=1718711175&width=1800`,
        imageAlt: "Front of men's cargo pant in blue",
        price: 'INR 1,499',
        color: `BLUE`,
    },
]

export default product