const data= [

    {
        brand:'JBL:',
        title:'HI-RES AUDIO WIRELESS WITH JBL SIGNATURE SOUND',
        paragraph:`The JBL Live Beam 3's 10mm dynamic drivers are powered by JBL Signature Sound. Each driver is perfectly tuned to deliver deep bass, balanced mid-range, and refined high frequencies—all at any volume level. JBL Spatial Sound makes you feel like you're at a concert as the sound comes from virtually everywhere around you.`,
        thumbnail:`https://in.jbl.com/dw/image/v2/BFND_PRD/on/demandware.static/-/Sites-masterCatalog_Harman/default/dwa6df911e/01.JBL_Live%20Beam%203_Product%20Image_Feature%20Screen%2002_Silver.png?sw=535&sh=535`,
        H1:`SmartCase`,
        P1:`Customise Every Aspect of your earbuds with the SmartCase`,
        H2:`True ANC`,
        P2:`True Adaptive Noise Cancellation with smart ambient (controllable via SmartCase)`,
        H3:`Personi-Fi 3.0`,
        P3:`Customize your sound to match your unique listening profile `,
        H4:`Voice Aware`,
        P4:`Balance talking and listening volumes for clearer communication`,
        tradeRate:`₹13,998.`,
        mrpRate:`₹24,999`,
        blink:`https://www.amazon.in/JBL-Multipoint-connection-Headphones-Personi-Fi/dp/B0D44H2RG3?th=1&tag=inrdeal123-21`,
        

    }

]

export default data 