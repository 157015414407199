import React from "react";
import articleContent from "./Articlecontent";
import Articles from "../components/Articles";
import { Helmet } from "react-helmet";
import Navbar from "../components/NavBar"
import Footer from  "../components/Footer";



const Articlelist = () =>{
    return (
        <>
          <Navbar />
        <div>
        <Helmet>
        <title>Explore TrendZ Product List | Fashion, Accessories & More | TrendZ Blog</title>
        <meta name="description" content="Discover the latest fashion trends at TrendZ (https://trendz.blog/articlelist). Explore our extensive collection of stylish clothing, accessories, shoes, and more. Find top brands, detailed product reviews, and exclusive offers. Upgrade your wardrobe with TrendZ's curated selections and stay ahead in style. Shop now for the best in fashion!" />
        <meta name="keywords" keywords="sunglasses, shoes, clothing, accessories, trendy fashion, eyewear, casual wear, formal wear, sportswear, fashion accessories, men's fashion, women's fashion, kids' fashion, designer brands, high-quality apparel, latest trends, style tips, fashion blog, TrendZ products, online shopping, fashion articles, style guides, fashion reviews, product recommendations, fashion deals, TrendZ store, seasonal collections, new arrivals, fashion essentials, wardrobe staples, outfit inspiration" />
      </Helmet>
            
            <div className="container py-4 mx-auto">
                <div className="flex flex-wrap m-4">
                    <Articles articles={articleContent } /> 
                </div>
            </div>
        </div>
        <Footer />
       </>
    );
};




export default Articlelist;